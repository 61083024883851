import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthStore } from '../../Stores';
import { LocalStorageService } from '../../Services';
import { useNotification } from '../NotificationProvider/NotificationProvider';
import { Utils } from '../../Utilities';

const Authorization: FC<PropsWithChildren<any>> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const notification = useNotification();

  useEffect(() => {
    const accessToken = LocalStorageService.getAuthToken();
    if (accessToken) {
      AuthStore.setAuthUser(accessToken);
      const decodeToken = Utils.getDecodedAuthToken(accessToken);
      if (decodeToken?.exp < Date.now() / 1000) {
        AuthStore.clear();
        notification.error('Session expired!');
        navigate('/auth/login', { replace: true });
        setIsLoading(false);
        return;
      }
      if (AuthStore.isFamily) {
        verifyConsent();
        return;
      }
      setIsLoading(false);
      return;
    }
    if (location?.pathname?.includes('/caregiver/child-survey')) {
      LocalStorageService.saveRedirectUrl(location.pathname);
    }
    setIsLoading(false);
  }, []);

  const verifyConsent = () => {
    AuthStore.verifyConsent().subscribe({
      next: hasConsent => {
        AuthStore.setHasConsent(hasConsent);
        setIsLoading(false);
      },
      error: error => {},
    });
  };

  if (isLoading) {
    return <p>Loading....</p>;
  }

  return children;
};

export default Authorization;
