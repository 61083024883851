export enum UserType {
  SUPER_ADMIN = 1,
  FAMILY,
  ECO_ADMIN,
  COACH,
  COORDINATOR,
  SITE_ADMIN,
  AGENCY_ADMIN,
  PROVIDER,
}

export enum UserTypeName {
  SUPER_ADMIN = 'SUPER_ADMIN',
  FAMILY = 'FAMILY',
  ECO_ADMIN = 'ECO_ADMIN',
  COACH = 'COACH',
  COORDINATOR = 'COORDINATOR',
  SITE_ADMIN = 'SITE_ADMIN',
  AGENCY_ADMIN = 'AGENCY_ADMIN',
  PROVIDER = 'PROVIDER',
}
