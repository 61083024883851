export const Fields = [
    {
      name: 'caregiverFirstName',
      type: 'string',
      placeholder: 'Enter your first name',
      label: 'Caregiver First Name',
      rules: 'required|string',
      value: '',
    },
    {
        name: 'caregiverLastName',
        type: 'string',
        placeholder: 'Enter your last name',
        label: 'Caregiver Last Name',
        rules: 'required|string',
        value: '',
      },
      {
        name: 'caregiverEmail',
        type: 'string',
        placeholder: 'Enter your email address',
        label: 'Caregiver Email',
        rules: 'required|string',
        value: '',
      },
      {
        name: 'caregiverPhone',
        type: 'string',
        placeholder: 'Enter your phone',
        label: 'Caregiver Phone',
        rules: 'required|string|min:10|max:10',
        value: '',
      },
      {
        name: 'child1FirstName',
        type: 'string',
        placeholder: 'First Name',
        label: 'First Name',
        rules: 'required|string',
        value: '',
      },
      {
        name: 'child1LastName',
        type: 'string',
        placeholder: 'Last Name',
        label: 'Last Name',
        rules: 'required|string',
        value: '',
      },
      {
        name: 'child1BirthDate',
        type: 'string',
        placeholder: 'Birth Date',
        label: 'Birth Date',
        rules: 'required',
        value: '',
      },
      {
        name: 'child1WeeksPremature',
        type: 'string',
        placeholder: 'Weeks Premature',
        label: 'Weeks Premature',
        rules: 'required|string',
        value: '',
      },
      {
        name: 'child2FirstName',
        type: 'string',
        placeholder: 'First Name',
        label: 'First Name',
        rules: 'string',
        value: '',
      },
      {
        name: 'child2LastName',
        type: 'string',
        placeholder: 'Last Name',
        label: 'Last Name',
        rules: 'string',
        value: '',
      },
      {
        name: 'child2BirthDate',
        type: 'string',
        placeholder: 'Birth Date',
        label: 'Birth Date',
        rules: '',
        value: '',
      },
      {
        name: 'child2WeeksPremature',
        type: 'string',
        placeholder: 'Weeks Premature',
        label: 'Weeks Premature',
        rules: 'string',
        value: '',
      },
      {
        name: 'child3FirstName',
        type: 'string',
        placeholder: 'First Name',
        label: 'First Name',
        rules: 'string',
        value: '',
      },
      {
        name: 'child3LastName',
        type: 'string',
        placeholder: 'Last Name',
        label: 'Last Name',
        rules: 'string',
        value: '',
      },
      {
        name: 'child3BirthDate',
        type: 'string',
        placeholder: 'Birth Date',
        label: 'Birth Date',
        rules: '',
        value: '',
      },
      {
        name: 'child3WeeksPremature',
        type: 'string',
        placeholder: 'Weeks Premature',
        label: 'Weeks Premature',
        rules: 'string',
        value: '',
      },
      {
        name: 'child4FirstName',
        type: 'string',
        placeholder: 'First Name',
        label: 'First Name',
        rules: 'string',
        value: '',
      },
      {
        name: 'child4LastName',
        type: 'string',
        placeholder: 'Last Name',
        label: 'Last Name',
        rules: 'string',
        value: '',
      },
      {
        name: 'child4BirthDate',
        type: 'string',
        placeholder: 'Birth Date',
        label: 'Birth Date',
        rules: '',
        value: '',
      },
      {
        name: 'child4WeeksPremature',
        type: 'string',
        placeholder: 'Weeks Premature',
        label: 'Weeks Premature',
        rules: 'string',
        value: '',
      }

  ];
  