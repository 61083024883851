export const StepOneFields = [
  {
    name: 'firstName',
    label: 'First Name',
    rules: 'required|string',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    rules: 'required|string',
  },
  {
    name: 'email',
    label: 'Email',
    rules: 'required|string|email',
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number',
    rules: 'required|string|min:10|max:10',
  },
  {
    name: 'providerId',
    label: 'Provider',
    placeholder: 'Provider',
    rules: 'required',
    value: '',
  },
];
