import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';
import { ChildSurveyStore, CustomDataGrid, ScreenResult, Utils } from '@shared';
import { useStyles } from './QuestionsResponse.styles';

type Props = {
  sectionId: number;
  childSurveyStore?: ChildSurveyStore;
  canSeeScores: any;
};

const QuestionsResponse: FC<Props> = ({ childSurveyStore, sectionId, canSeeScores }: Props) => {
  const classes = useStyles();    
  const columns = [
    {
      field: 'createdOn',
      headerName: 'Date Taken',
      width: 250,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },
    {
      field: 'noteText',
      headerName: 'Note',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
  ];
  const { report } = childSurveyStore;
  const section = report?.reportSections?.find(x => x.surveySectionId === sectionId);

  return (
    <div className={classes.mainContainer}>
      <Typography className={classes.title}>Survey Responses</Typography>
      {section.subSections?.map(subSection => {
        return (
          <Box className={classes.outerContainer} key={subSection.subSectionId}>
            <Box>
              {subSection?.questions?.map(question => {
                return (
                  <Box display="flex" flexDirection="row" key={question?.questionId} mb="3px">
                    <Typography>{question?.questionText} -</Typography>
                    <Typography sx={{ ml: '5px' }}>{question?.responses.map(v=>v.responseText).join(', ')}</Typography>
                  </Box>
                );
              })}
            </Box>
            <Box>
              {subSection?.score?.result && (
                <Typography textAlign="end">
                  <span className={classes.title}>Screen Result : </span>
                  <span
                    className={
                      subSection?.score?.result === ScreenResult.MEETS_EXPECTATIONS
                        ? classes.greenBackground
                        : classes.yellowBackground
                    }
                  >
                    {subSection?.score?.result}
                  </span>
                </Typography>
              )}
              {subSection?.score && Boolean(subSection?.score?.cutScore) && canSeeScores() && (
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }} textAlign="end">
                    Score:
                  </Typography>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Typography>Cut Score:</Typography>
                    <Typography sx={{ ml: '5px' }}>{subSection?.score?.cutScore}</Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Typography>Score:</Typography>
                    <Typography sx={{ ml: '5px' }}>{subSection?.score?.childScore}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
      {Boolean(section?.notes?.length) && (
        <Box>
          <Typography className={classes.title}>Section Notes</Typography>
          <CustomDataGrid
            columns={columns}
            data={section?.notes}
            pagination={false}
            hideFooter={true}
            styleClasses={{ gridControl: classes.grid }}
          />
        </Box>
      )}
    </div>
  );
};

export default inject('childSurveyStore')(observer(QuestionsResponse));
