import { IChildTipSheets } from '../Interfaces/ChildTipSheets.interface';
import { OptionsModel } from './Options.model';

export class ChildTipSheetsModel {
  developmentTipSheets: OptionsModel[] = [];
  behaviourTipSheets: OptionsModel[] = [];

  constructor(data?: Partial<ChildTipSheetsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IChildTipSheets): ChildTipSheetsModel {
    if (!apiData) {
      return new ChildTipSheetsModel();
    }

    const data: Partial<ChildTipSheetsModel> = {
      developmentTipSheets: OptionsModel.deserializeList(apiData.DevelopmentTipSheets),
      behaviourTipSheets: OptionsModel.deserializeList(apiData.BehaviourTipSheets),
    };

    return new ChildTipSheetsModel(data);
  }

  static deserializeList(tipSheetList: IChildTipSheets[]): ChildTipSheetsModel[] {
    return tipSheetList
      ? tipSheetList.map(
          (apiData: IChildTipSheets) => new ChildTipSheetsModel(ChildTipSheetsModel.deserialize(apiData))
        )
      : [];
  }
}
