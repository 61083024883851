import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Box } from '@mui/material';
import { SWYCBehaviourProgressModel } from '@models';

type Props = {
  progress: SWYCBehaviourProgressModel;
};

const SWYCBehaviour: FC<Props> = ({ progress }: Props) => {
  const data = {
    labels: progress?.labels,
    datasets: [
      {
        label: progress?.bpsc[0]?.sectionAbbreviation || 'BPSC',
        data: progress?.bpscData,
        backgroundColor: 'rgb(33, 190, 198, 0.5)',
        borderColor: 'rgb(33, 190, 198)',
        borderWidth: 1,
        categoryPercentage: 0.5,
      },
      {
        label: progress?.ppsc[0]?.sectionAbbreviation || 'PPSC',
        data: progress?.ppscData,
        backgroundColor: 'rgb(81, 185, 106, 0.5)',
        borderColor: 'rgb(81, 185, 106)',
        borderWidth: 1,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    events: [],
    scales: {
      y: {
        max:2,
        ticks: {
          beginAtZero: true,
          callback: function (label, index, labels) {
            switch (label) {
              case 0:
                return '';
              case 1:
                return 'Review Further';
              case 2:
                return 'Meet Age Expectation';
            }
          },
        },
      },
    },
  };

  return (
    <Box style={{ padding: '20px' }}>
      <Bar data={data} width="1362px" height="400px" options={options} />
    </Box>
  );
};

export default SWYCBehaviour;
