import { IProvider } from '../Interfaces';

export class ProviderModel {
  id: number;
  providerGuid: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  agencyId: number;
  agencyName: string;
  isAsqProfile: boolean;
  isArchived: boolean;
  isMasterProvider: boolean;
  isAssignedToMasterAgency: boolean;

  constructor(data?: Partial<ProviderModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IProvider): ProviderModel {
    if (!apiData) {
      return new ProviderModel();
    }

    const data: Partial<ProviderModel> = {
      id: apiData.ProviderId,
      providerGuid: apiData.ProviderGuid,
      name: apiData.Name,
      email: apiData.Email,
      firstName: apiData.FirstName,
      lastName: apiData.LastName,
      agencyId: apiData.AgencyId,
      agencyName: apiData.AgencyName,
      isAsqProfile: apiData.IsAsqProfile,
      isArchived: apiData.IsArchived,
      isMasterProvider: apiData.IsMasterProvider,
      isAssignedToMasterAgency: apiData.IsAssignedToMasterAgency,
    };

    return new ProviderModel(data);
  }

  static deserializeList(providerList: IProvider[]): ProviderModel[] {
    return providerList
      ? providerList.map((apiData: IProvider) => new ProviderModel(ProviderModel.deserialize(apiData)))
      : [];
  }
}
