import { map, Observable } from 'rxjs';
import { ChildrenModel, ChildrenPaginationModel, ChildTeachersModel } from '../Models';
import { IAPIResponse, IChildren, IChildrenPagination, IChildAndTeachers } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { Sort } from '../Enums';

class ChildrenService {
  public getChildrenList(
    pageNumber: number,
    pageSize: number,
    sort: string,
    dir: Sort,
    query?: string,
    providerId?: number,
    reviewedStatus = null
  ): Observable<ChildrenPaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      PageNumber: pageNumber,
      PageSize: pageSize,
      Sort: sort,
      Dir: dir,
      ProviderId: providerId || null,
      Query: query || '',
      ReviewedStatus: reviewedStatus,
    });

    return http
      .get<IAPIResponse<IChildrenPagination>>(`${ApiUrl.children}?${params}`)
      .pipe(map((response: IAPIResponse<IChildrenPagination>) => ChildrenPaginationModel.deserialize(response.Data)));
  }

  public getSiteChildren(
    pageNumber: number,
    pageSize: number,
    sort: string,
    dir: Sort,
    query: string,
    siteId?: number
  ): Observable<ChildrenPaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      PageNumber: pageNumber,
      PageSize: pageSize,
      Sort: sort,
      Dir: dir,
      SiteId: siteId || null,
      Query: query || '',
    });

    return http
      .get<IAPIResponse<IChildrenPagination>>(`${ApiUrl.siteChildren}?${params}`)
      .pipe(map((response: IAPIResponse<IChildrenPagination>) => ChildrenPaginationModel.deserialize(response.Data)));
  }

  public getFamilyChildren(
    pageNumber: number,
    pageSize: number,
    sort: string,
    dir: Sort,
    query: string,
    providerId: number,
    familyId: number,
    reviewedStatus = null
  ): Observable<ChildrenPaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      PageNumber: pageNumber,
      PageSize: pageSize,
      Sort: sort,
      Dir: dir,
      FamilyId: familyId,
      ProviderId: providerId || null,
      Query: query || '',
      ReviewedStatus: reviewedStatus,
    });

    return http
      .get<IAPIResponse<IChildrenPagination>>(`${ApiUrl.familyChildren}?${params}`)
      .pipe(map((response: IAPIResponse<IChildrenPagination>) => ChildrenPaginationModel.deserialize(response.Data)));
  }

  public addChild(req): Observable<number> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<number>>(ApiUrl.children, req)
      .pipe(map((response: IAPIResponse<number>) => response.Data));
  }

  public getChildById(id: number): Observable<ChildrenModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IChildren>>(`${ApiUrl.children}/${id}`)
      .pipe(map((response: IAPIResponse<IChildren>) => ChildrenModel.deserialize(response.Data)));
  }

  public updateChild(req): Observable<boolean> {debugger;
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.children, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public deleteChild(id: number): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.children}/${id}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public getChildAndTeachers(childrenId: number, siteId: number): Observable<ChildTeachersModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      ChildrenId: childrenId,
      SiteId: siteId,
    });
    return http
      .get<IAPIResponse<IChildAndTeachers>>(`${ApiUrl.childAndTeachers}?${params}`)
      .pipe(map((response: IAPIResponse<IChildAndTeachers>) => ChildTeachersModel.deserialize(response.Data)));
  }

  public allocateTeacher(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.allocateTeacher, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public childConsent(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<boolean>>(ApiUrl.childConsent, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }
}

export default new ChildrenService();
