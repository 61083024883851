import { FC, ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import { FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { IClasses } from '@interfaces';
import { SurveyQuestionsModel } from '@models';

interface Props {
  className?: string;
  defaultValue?: any;
  showLabel?: boolean;
  width?: number;
  options: SurveyQuestionsModel;
  styleClasses?: IClasses;
  error?: boolean;
  onValueChange: (value: any) => void;
}
const CustomRadioButton: FC<Props> = ({ defaultValue, options, className, error, onValueChange }: Props) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = e => {
    setValue(e.target.value);
    onValueChange(e.target.value);
  };
  const editableContent = (): ReactNode => {
    return (
      <div className={className}>
        <FormLabel id="demo-row-radio-buttons-group-label">
          {options.isRequired && <Typography sx={{ mr: '5px', color: 'red' }}>*</Typography>}
          <Typography>{`${options?.orderIndex}. ${options?.title}`}</Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={value}
          onChange={onChange}
        >
          {options?.surveyQuestionOptions?.map(option => (
            <FormControlLabel
              key={option.questionOptionId}
              value={option.questionOptionId}
              control={<Radio />}
              sx={{ paddingLeft: '8px' }}
              label={option.optionText}
            />
          ))}
        </RadioGroup>
        {error && <FormHelperText>This question is required.</FormHelperText>}
      </div>
    );
  };

  return <div>{editableContent()}</div>;
};

export default observer(CustomRadioButton);
