import { action, makeObservable, observable } from 'mobx';
import {
  ChildProgressModel,
  ChildReportModel,
  ChildSurveyModel,
  ReportSectionsModel,
  SurveySectionsModel,
} from '../Models';

class ChildSurveyStore {
  public survey: ChildSurveyModel = new ChildSurveyModel();
  public surveySection: SurveySectionsModel = new SurveySectionsModel();
  public report: ChildReportModel = new ChildReportModel();
  public reportSection: ReportSectionsModel = new ReportSectionsModel();
  public progress: ChildProgressModel = new ChildProgressModel();

  constructor() {
    makeObservable(this, {
      survey: observable,
      surveySection: observable,
      report: observable,
      reportSection: observable,
      progress: observable,
      setSurvey: action,
      setSurveySection: action,
      setReport: action,
      setReportSection: action,
      setProgress: action,
      clear: action,
    });
  }

  public setSurvey(survey: ChildSurveyModel): void {
    this.survey = survey;
  }

  public setSurveySection(surveySection: SurveySectionsModel): void {
    this.surveySection = surveySection;
  }

  public setReport(report: ChildReportModel): void {
    this.report = report;
  }

  public setReportSection(reportSection: ReportSectionsModel): void {
    this.reportSection = reportSection;
  }

  public setProgress(progress: ChildProgressModel): void {
    this.progress = progress;
  }

  public clear(): void {
    this.survey = new ChildSurveyModel();
    this.surveySection = new SurveySectionsModel();
    this.report = new ChildReportModel();
    this.reportSection = new ReportSectionsModel();
    this.progress = new ChildProgressModel();
  }
}

export default ChildSurveyStore;
