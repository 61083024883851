import React, { FC, useEffect } from 'react';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Typography, Button } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  CustomDataGrid,
  CustomDialog,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  useNotification,
  UserRole,
  UserRoleOptions,
  UserSearch,
  UserType,
  UserTypeName,
  Actions,
} from '@shared';
import { UserStore, UIStore, ProfileStore } from '@stores';
import { CircleCheckGreen, CircleCheckGrey } from '@assets';
import { UserService } from '@services';
import { PaginationModel, UserModel } from '@models';
import { useStyles } from './Dashboard.styles';
import { UserFields } from './UserFields';
import { EditIcon, CrossIcon } from '@assets';

type Props = {
  userStore?: UserStore;
  profileStore?: ProfileStore;
};

const form: MobxReactForm = getFormValidation(UserFields, null);

const Dashboard: FC<Props> = ({ userStore, profileStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const observable = useLocalObservable(() => ({
    user: new UserModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    type: null,
    totalNumberOfRecords: 0,
    sortModel: [],
  }));
  const columns = [
    { field: 'firstName', headerName: 'First Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lastName', headerName: 'Last Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'type',
      headerName: 'User Type',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => UserRole[params.value],
    },
    {
      field: 'active',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params =>
        params.value ? <img src={CircleCheckGreen} alt="active" /> : <img src={CircleCheckGrey} alt="inactive" />,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    getUserList();
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isEdit = false;
      observable.isDelete = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getUserList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getUserList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
    });
    getUserList();
  };

  const getUserList = () => {
    const { pageNumber, pageSize, sortModel, query, type } = observable;
    UIStore.showLoader();
    UserService.getAllUsers(pageNumber, pageSize, sortModel, query, type).subscribe({
      next: (response: PaginationModel) => {
        userStore.setUserList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    if (observable.isEdit) {
      editUser();
      return;
    }
    addUser();
  };

  const addUser = () => {
    const { firstName, lastName, email, phoneNumber } = form.values();
    const request = { firstName, lastName, email, phoneNumber, type: UserType.SUPER_ADMIN };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        UserService.addUser(request).subscribe({
          next: (response: number) => {
            handleClose();
            getUserList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const editUser = () => {
    const { firstName, lastName, email, phoneNumber, type } = form.values();
    const { id } = observable.user;
    const request = { firstName, lastName, email, phoneNumber, type, id };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        UserService.updateUser(request).subscribe({
          next: (response: boolean) => {
            handleClose();
            getUserList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const onUserAction = (user: UserModel, isEdit = false) => {
    runInAction(() => {
      observable.user = user;
      if (isEdit) {
        getUserById();
        return;
      }
      observable.isDelete = true;
    });
  };

  const getUserById = () => {
    UIStore.showLoader();
    UserService.getUserById(observable.user?.id).subscribe({
      next: (response: UserModel) => {
        const { firstName, lastName, email, phoneNumber, type } = response;
        form.$('firstName').set(firstName);
        form.$('lastName').set(lastName);
        form.$('email').set(email);
        form.$('phoneNumber').set(phoneNumber || '');
        form.$('type').set(UserType[type]);
        runInAction(() => {
          observable.isEdit = true;
          observable.isOpen = true;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const deleteUser = () => {
    UIStore.showLoader();
    UserService.deleteUser(observable.user?.id).subscribe({
      next: (response: boolean) => {
        handleClose();
        getUserList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSearch = (searchText: string, type: number) => {
    runInAction(() => {
      observable.query = searchText;
      observable.type = type;
      observable.pageNumber = 1;
    });
    getUserList();
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
      observable.type = null;
    });
    getUserList();
  };

  const renderActions = params => {
    return (
      <>
        {params.row.type === UserTypeName.SUPER_ADMIN && (
          <Actions btnText="Edit" onClick={() => onUserAction(params.row, true)} imgSrc={EditIcon} />
        )}
        {params.row.id !== profileStore?.profile?.id && !params.row.isMasterUser && (
          <Actions btnText="Delete" onClick={() => onUserAction(params.row)} imgSrc={CrossIcon} />
        )}
      </>
    );
  };

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Users
      </Typography>
      <UserSearch
        searchPlaceholder="Enter name or email to search user"
        options={UserRoleOptions}
        showUserType={true}
        onSearch={onSearch}
        onClear={onClear}
      />
      <Box component="div" display="flex" flexDirection="column">
        <Button
          className={classes.button}
          onClick={() =>
            runInAction(() => {
              observable.isOpen = true;
            })
          }
        >
          <AddCircleIcon className={classes.icon} />
          <Typography variant="body1" className={classes.text}>
            ADD USER
          </Typography>
        </Button>
        <CustomDataGrid
          columns={columns}
          data={userStore.userList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
        />
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit User' : 'Add User'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('firstName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'firstName')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('lastName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'lastName')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('email')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'email')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('phoneNumber')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'phoneNumber')}
            showLabel={true}
            form={form}
          />
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this user?"
          handleClose={handleClose}
          handleDelete={deleteUser}
        />
      )}
    </div>
  );
};

export default inject('userStore', 'profileStore')(observer(Dashboard));
