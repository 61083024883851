export const UserFields = [
    {
        name: 'firstName',
        type: 'string',
        label: 'First Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'lastName',
        type: 'string',
        label: 'Last Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'email',
        type: 'string',
        label: 'Email',
        rules: 'required|string|email',
        value: '',
    },
    {
        name: 'phoneNumber',
        type: 'string',
        label: 'Phone Number',
        rules: 'required|string|min:10|max:10',
        value: '',
    },
    {
        name: 'type',
        type: 'string',
        placeholder: 'User Type',
        label: 'User Type',
        value: '',
    },
];
