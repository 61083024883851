import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';
import MobxReactForm, { Field } from 'mobx-react-form';
import { observer } from 'mobx-react';
import { Box, Button, Card, CircularProgress, InputAdornment, Link, Typography } from '@mui/material';
import { Lock as LockIcon, EmailOutlined as EmailOutlinedIcon } from '@mui/icons-material';
import { CTFLogo, Logo } from '@assets';
import { FormInputControl, getFormValidation, LocalStorageService, useNotification, UserRole } from '@shared';
import { UIStore, AuthStore } from '@stores';
import { useStyles } from './Login.styles';
import { LoginFields } from './fields';

const form: MobxReactForm = getFormValidation(LoginFields, null);

const Login: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const notification = useNotification();

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value.trim());
  };

  const verifyConsent = () => {
    AuthStore.verifyConsent().subscribe({
      next: hasConsent => {
        AuthStore.setHasConsent(hasConsent);
        UIStore.hideLoader();
        if (hasConsent) {
          const redirectUrl = LocalStorageService.getRedirectUrl();
          if (redirectUrl) {
            LocalStorageService.removeRedirectUrl();
            navigate(redirectUrl);
            return;
          }
          navigate('/caregiver/children');
          return;
        }
        navigate('/caregiver/account-consent');
      },
      error: error => {
        UIStore.hideLoader();
      },
    });
  };

  const navigateTo = (role: string) => {
    switch (role) {
      case UserRole.FAMILY:
        verifyConsent();
        return;
      case UserRole.ECO_ADMIN:
      case UserRole.COACH:
      case UserRole.COORDINATOR:
        navigate('/eco/users');
        return;
      case UserRole.SITE_ADMIN:
        navigate('/site/teachers');
        return;
      default:
        navigate('/admin/users');
    }
  };

  const onLogin = () => {
    const { email, password } = form.values();
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        AuthStore.login(email, password).subscribe({
          next: response => {
            navigateTo(response.role);
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const onLoginWithToken = () => {
    navigate('/access-token');
  };

  return (
    <div className={classes.mainWrapper}>
      <Card component="div" className={classes.loginContainer} elevation={4}>
        <div className={classes.imageContainer}>
          <img src={Logo} className={classes.image} alt="logo"></img>
        </div>
        <form>
          <Box component="div" display="flex" flexDirection="column">
            <FormInputControl
              showLabel={true}
              field={form.$('email')}
              onValueChange={value => handleChange(value, 'email')}
              isAdornment={true}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              startAdornment={
                <InputAdornment position="start" className={classes.inputIcon}>
                  <EmailOutlinedIcon />
                </InputAdornment>
              }
            />
            <FormInputControl
              showLabel={true}
              field={form.$('password')}
              onValueChange={value => handleChange(value, 'password')}
              isAdornment={true}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              type="password"
              startAdornment={
                <InputAdornment position="start" className={classes.inputIcon}>
                  <LockIcon />
                </InputAdornment>
              }
            />
            <Box component="div" alignSelf="flex-end" sx={{ my: 1 }}>
              <Link onClick={() => navigate('/auth/forgot-password')} underline="hover" className={classes.link}>
                Forgot Password
              </Link>
            </Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.loginButton}
              onClick={onLogin}
              disabled={UIStore.pageLoading}
            >
              {UIStore.pageLoading ? (
                <CircularProgress className={classes.progress} style={{ width: 30, height: 30 }} />
              ) : (
                <Typography>Login</Typography>
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.loginButton}
              onClick={onLoginWithToken}
              disabled={UIStore.pageLoading}
            >
              {UIStore.pageLoading ? (
                <CircularProgress className={classes.progress} style={{ width: 30, height: 30 }} />
              ) : (
                <Typography>Login with Token</Typography>
              )}
            </Button>
          </Box>
        </form>
      </Card>
      <Box component="div" className={classes.logoContainer}>
        <img src={CTFLogo} className={classes.ctfLogo} alt="CTFlogo"></img>
        <Box component="div" mt={2}>
          <Typography variant="body1" textAlign="center">
            Funding for this site has been provided by the
          </Typography>
          <Typography variant="body1" textAlign="center">
            Children's Trust Fund of Missouri
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default observer(Login);
