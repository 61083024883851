import React, { FC, useEffect } from 'react';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Button, Typography } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  Actions,
  CustomDataGrid,
  CustomDatePicker,
  CustomDialog,
  CustomDropDown,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  ParentEntityType,
  Sort,
  useNotification,
  Utils,
} from '@shared';
import { UIStore, ChildrenStore, SiteStore } from '@stores';
import { ChildrenService } from '@services';
import { ChildrenModel, ChildrenPaginationModel, ChildTeachersModel } from '@models';
import { Fields, TeacherFields } from './Fields';
import { useStyles } from './ChildrenGrid.styles';
import { CrossIcon, EditIcon, VisibilityIcon } from '@assets';
import { useNavigate } from 'react-router';

type Props = {
  childrenStore?: ChildrenStore;
  siteStore?: SiteStore;
};

const form: MobxReactForm = getFormValidation(Fields, null);
const teacherForm: MobxReactForm = getFormValidation(TeacherFields, null);

const EcoChildren: FC<Props> = ({ childrenStore, siteStore }: Props) => {
  const notification = useNotification();
  const navigate = useNavigate();
  const classes = useStyles();
  const observable = useLocalObservable(() => ({
    child: new ChildrenModel(),
    isDelete: false,
    isOpen: false,
    isModalOpen: false,
    isEdit: false,
    query: '',
    pageNumber: 1,
    pageSize: 10,
    sort: 'firstName',
    dir: Sort.asc,
    totalNumberOfRecords: 0,
    sortModel: [],
  }));
  const { site } = siteStore;

  const columns = [
    { field: 'studentId', headerName: 'Student Id', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'firstName', headerName: 'First Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lastName', headerName: 'Last Name', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'weekPremature',
      headerName: 'Weeks Premature',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
    },
    {
      field: 'birthDate',
      headerName: 'Birth Date',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },
    {
      field: 'teacherName',
      headerName: 'Teacher',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params =>
        params.value || (
          <Button className={classes.assignBtn} onClick={() => handleAssign(params.row)}>
            Assign
          </Button>
        ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    getChildrenList();
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleAssign = (child: ChildrenModel) => {
    UIStore.showLoader();
    ChildrenService.getChildAndTeachers(child.id, site.id).subscribe({
      next: (response: ChildTeachersModel) => {
        UIStore.hideLoader();
        if (!response?.childrenId) {
          notification.error('No teacher is assigned to this particular site.');
          return;
        }
        childrenStore.setChildTeachers(response);
        runInAction(() => {
          observable.isModalOpen = true;
        });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isEdit = false;
      observable.isDelete = false;
      observable.isModalOpen = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getChildrenList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getChildrenList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
      observable.sort = newSortModel[0]?.field;
      observable.dir = Sort[newSortModel[0]?.sort];
    });
    getChildrenList();
  };

  const getChildrenList = () => {
    const { pageNumber, pageSize, sort, dir, query } = observable;
    UIStore.showLoader();
    ChildrenService.getSiteChildren(pageNumber, pageSize, sort, dir, query, site?.id).subscribe({
      next: (response: ChildrenPaginationModel) => {
        debugger;
        childrenStore.setChildrenList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onAction = (child: ChildrenModel, isEdit = false) => {
    runInAction(() => {
      observable.child = child;
      if (isEdit) {
        getChildById();
        return;
      }
      observable.isDelete = true;
    });
  };

  const getChildById = () => {
    UIStore.showLoader();
    ChildrenService.getChildById(observable.child?.id).subscribe({
      next: (response: ChildrenModel) => {
        const { firstName, lastName, birthDate, weekPremature } = response;
        form.$('firstName').set(firstName);
        form.$('lastName').set(lastName);
        form.$('weekPremature').set(weekPremature);
        form.$('birthDate').set(birthDate);
        runInAction(() => {
          observable.isEdit = true;
          observable.isOpen = true;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    if (observable.isEdit) {
      editChild();
      return;
    }
    addChild();
  };

  const editChild = () => {
    const { firstName, lastName, birthDate, weekPremature } = form.values();
    const { id } = observable.child;
    const request = {
      id,
      firstName,
      lastName,
      birthDate: Utils.formattedDate(birthDate, 'MM/DD/YYYY'),
      weekPremature: Number(weekPremature),
    };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildrenService.updateChild(request).subscribe({
          next: (response: boolean) => {
            handleClose();
            getChildrenList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const addChild = () => {
    const { firstName, lastName, birthDate, weekPremature } = form.values();
    const { site } = siteStore;
    const request = {
      firstName,
      lastName,
      birthDate: Utils.formattedDate(birthDate, 'MM/DD/YYYY'),
      weekPremature: Number(weekPremature),
      parentEntityId: site?.id,
      parentEntityType: ParentEntityType.SITE,
    };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildrenService.addChild(request).subscribe({
          next: (response: number) => {
            handleClose();
            getChildrenList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const deleteChild = () => {
    UIStore.showLoader();
    ChildrenService.deleteChild(observable.child?.id).subscribe({
      next: (response: boolean) => {
        handleClose();
        getChildrenList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onTeacherAssign = () => {
    teacherForm.submit({
      onSuccess: () => {
        const { childrenId } = childrenStore?.childTeachers;
        const { teacherId } = teacherForm.values();
        const request = { childrenId, teacherId };
        UIStore.showLoader();
        ChildrenService.allocateTeacher(request).subscribe({
          next: (response: boolean) => {
            handleClose();
            getChildrenList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const renderActions = params => {
    return (
      <>
        <Actions btnText="Edit" onClick={() => onAction(params.row, true)} imgSrc={EditIcon} />
        <Actions btnText="Delete" onClick={() => onAction(params.row)} imgSrc={CrossIcon} />
        {Boolean(params?.row?.showProgress) && (
          <Actions
            btnText="Progress"
            onClick={() => navigate(`/admin/child-progress/${params?.row?.id}`)}
            imgSrc={VisibilityIcon}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <Box component="div" display="flex" flexDirection="column">
        <Button
          className={classes.button}
          onClick={() =>
            runInAction(() => {
              observable.isOpen = true;
            })
          }
        >
          <AddCircleIcon className={classes.icon} />
          <Typography variant="body1" className={classes.text}>
            ADD CHILD
          </Typography>
        </Button>
        <CustomDataGrid
          columns={columns}
          data={childrenStore.childrenList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
          styleClasses={{ gridControl: classes.grid }}
        />
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit Child' : 'Add Child'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('firstName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'firstName')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('lastName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'lastName')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('weekPremature')}
            type="number"
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'weekPremature')}
            showLabel={true}
            form={form}
          />
          <CustomDatePicker
            field={form.$('birthDate')}
            onValueChange={value => handleChange(value, 'birthDate')}
            value={form.$('birthDate').value}
            showLabel={true}
            isBirthDate={true}
            endDate={Utils.formattedDate(String(new Date()), 'MM/DD/YYYY')}
            className={classes.dateInput}
            form={form}
          />
        </div>
      </CustomDialog>
      <CustomDialog
        open={observable.isModalOpen}
        title="Assign Teacher"
        buttonText="Assign"
        onClose={handleClose}
        onSubmit={onTeacherAssign}
      >
        <div>
          <FormInputControl
            field={teacherForm.$('childName')}
            value={childrenStore.childTeachers?.childName}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            inputProps={{ readOnly: true }}
          />
          <CustomDropDown
            value={teacherForm.$('teacherId').value}
            field={teacherForm.$('teacherId')}
            options={childrenStore.childTeachers?.teachers}
            className={classes.selectBox}
            placeholder={teacherForm.$('teacherId').label}
            onValueChange={value => handleChange(value, 'teacherId')}
          />
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this child?"
          handleClose={handleClose}
          handleDelete={deleteChild}
        />
      )}
    </div>
  );
};

export default inject('childrenStore', 'siteStore')(observer(EcoChildren));
