export const Fields = [
    {
        name: 'email',
        type: 'string',
        placeholder: 'Enter your Email Address',
        label: 'Email',
        rules: 'required|string|email',
        value: '',
    },
];
