import React, { FC } from 'react';
import { observer } from 'mobx-react';
import {
    IconButton,
    Typography,
    Avatar,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@mui/material';
import { Logout, PersonOutline as ProfileIcon } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';

type Props = {
    name?: string;
    onLogout?: () => void;
}

const AccountMenu: FC<Props> = ({ name, onLogout }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const location = useLocation();

    const slash = location.pathname.indexOf('/', 1);
    const titleLocation = location.pathname.substring(0, slash);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                size="large"
                color="inherit"
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{ ':hover': { backgroundColor: 'transparent' } }}>
                <Avatar sx={{ width: 40, height: 40 }} />
                <Typography variant="body1" noWrap component="div" sx={{ marginLeft: '8px' }}>
                    {`Hello! ${name}`}
                </Typography>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={()=>navigate(`${titleLocation}/my-profile`)}>
                    <ListItemIcon>
                        <ProfileIcon />
                    </ListItemIcon>
                    My Profile
                </MenuItem>
                <MenuItem onClick={onLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
};

export default observer(AccountMenu);
