import { ISitePagination } from '../Interfaces';
import { PageModel } from './Page.model';
import { SiteModel } from './Site.model';

export class SitePaginationModel extends PageModel {
    results: SiteModel[];

    constructor(data?: Partial<SitePaginationModel>) {
        super();
        Object.assign(this, data);
    }

    static deserialize(apiData: ISitePagination): SitePaginationModel {
        if (!apiData) {
            return new SitePaginationModel();
        }

        const data: Partial<SitePaginationModel> = {
            pageNumber: apiData.PageNumber,
            pageSize: apiData.PageSize,
            totalNumberOfRecords: apiData.TotalNumberOfRecords,
            totalPages: apiData.TotalPages,
            results: SiteModel.deserializeList(apiData.Results),
        };

        return new SitePaginationModel(data);
    }
}
