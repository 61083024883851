import {
  IAssociatedTipSheets,
  IChildReport,
  INotes,
  IQuestions,
  IReportSections,
  IResponses,
  IScore,
  ISubSections,
} from '../Interfaces';
import { OptionsModel } from './Options.model';

export class ChildReportModel {
  childrenSurveyId: number;
  childId: number;
  childFirstName: string = '';
  childLastName: string = '';
  childBirthDate: string = '';
  ageInMonths: number;
  parentEntityFirstName: string = '';
  parentEntityLastName: string = '';
  parentEntityType: number;
  surveyId: number;
  surveyName: string = '';
  isReviewed: boolean = false;
  isRetakeAndEditAvialable: boolean = false;
  reportSections: ReportSectionsModel[] = [];
  associatedTipSheets: AssociatedTipSheetsModel = null;

  constructor(data?: Partial<ChildReportModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IChildReport): ChildReportModel {
    if (!apiData) {
      return new ChildReportModel();
    }

    const data: Partial<ChildReportModel> = {
      childrenSurveyId: apiData.ChildrenSurveyId,
      childId: apiData.ChildId,
      childFirstName: apiData?.ChildFirstName || '',
      childLastName: apiData?.ChildLastName || '',
      childBirthDate: apiData?.ChildBirthDate || '',
      ageInMonths: apiData?.AgeInMonths,
      parentEntityFirstName: apiData?.ParentEntityFirstName || '',
      parentEntityLastName: apiData?.ParentEntityLastName || '',
      parentEntityType: apiData.ParentEntityType,
      surveyId: apiData.SurveyId,
      surveyName: apiData?.SurveyName || '',
      isReviewed: apiData.IsReviewed,
      isRetakeAndEditAvialable: apiData.IsRetakeAndEditAvialable,
      associatedTipSheets: AssociatedTipSheetsModel.deserialize(apiData.AssociatedTipSheets),
      reportSections: ReportSectionsModel.deserializeList(apiData.ReportSections),
    };

    return new ChildReportModel(data);
  }

  static deserializeList(reportList: IChildReport[]): ChildReportModel[] {
    return reportList
      ? reportList.map((apiData: IChildReport) => new ChildReportModel(ChildReportModel.deserialize(apiData)))
      : [];
  }
}

export class AssociatedTipSheetsModel {
  behaviourTipSheets: OptionsModel[] = [];
  developmentTipSheet: OptionsModel = new OptionsModel();

  constructor(data?: Partial<AssociatedTipSheetsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IAssociatedTipSheets): AssociatedTipSheetsModel {
    if (!apiData) {
      return null;
    }

    const data: Partial<AssociatedTipSheetsModel> = {
      developmentTipSheet: OptionsModel.deserialize(apiData.DevelopmentTipSheet),
      behaviourTipSheets: OptionsModel.deserializeList(apiData.BehaviourTipSheets),
    };

    return new AssociatedTipSheetsModel(data);
  }

  static deserializeList(sheetList: IAssociatedTipSheets[]): AssociatedTipSheetsModel[] {
    return sheetList
      ? sheetList.map(
          (apiData: IAssociatedTipSheets) => new AssociatedTipSheetsModel(AssociatedTipSheetsModel.deserialize(apiData))
        )
      : [];
  }
}

export class ReportSectionsModel {
  surveySectionId: number;
  sectionName: string;
  orderIndex: number;
  subSections: SubSectionsModel[];
  notes: NotesModel[];

  constructor(data?: Partial<ReportSectionsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IReportSections): ReportSectionsModel {
    if (!apiData) {
      return new ReportSectionsModel();
    }

    const data: Partial<ReportSectionsModel> = {
      surveySectionId: apiData.SurveySectionId,
      sectionName: apiData.SectionName,
      orderIndex: apiData.OrderIndex,
      subSections: SubSectionsModel.deserializeList(apiData.SubSections),
      notes: NotesModel.deserializeList(apiData.Notes),
    };

    return new ReportSectionsModel(data);
  }

  static deserializeList(sectionList: IReportSections[]): ReportSectionsModel[] {
    return sectionList
      ? sectionList.map((apiData: IReportSections) => new ReportSectionsModel(ReportSectionsModel.deserialize(apiData)))
      : [];
  }
}

export class SubSectionsModel {
  subSectionId: number;
  subSectionName: string;
  score: ScoreModel = null;
  questions: QuestionsModel[];

  constructor(data?: Partial<SubSectionsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ISubSections): SubSectionsModel {
    if (!apiData) {
      return new SubSectionsModel();
    }

    const data: Partial<SubSectionsModel> = {
      subSectionId: apiData.SubSectionId,
      subSectionName: apiData.SubSectionName,
      score: ScoreModel.deserialize(apiData?.Score),
      questions: QuestionsModel.deserializeList(apiData.Questions),
    };

    return new SubSectionsModel(data);
  }

  static deserializeList(subSectionList: ISubSections[]): SubSectionsModel[] {
    return subSectionList
      ? subSectionList.map((apiData: ISubSections) => new SubSectionsModel(SubSectionsModel.deserialize(apiData)))
      : [];
  }
}

export class ScoreModel {
  childScore: number;
  cutScore: number;
  result: string = '';

  constructor(data?: Partial<ScoreModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IScore): ScoreModel {
    if (!apiData) {
      return null;
    }

    const data: Partial<ScoreModel> = {
      childScore: apiData.ChildScore,
      cutScore: apiData.CutScore,
      result: apiData?.Result || '',
    };

    return new ScoreModel(data);
  }
}

export class QuestionsModel {
  questionId: number;
  questionText: string;
  orderIndex: number;
  responses: ResponsesModel[];

  constructor(data?: Partial<QuestionsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IQuestions): QuestionsModel {
    if (!apiData) {
      return new QuestionsModel();
    }

    const data: Partial<QuestionsModel> = {
      questionId: apiData.QuestionId,
      questionText: apiData.QuestionText,
      orderIndex: apiData.OrderIndex,
      responses: ResponsesModel.deserializeList(apiData.Responses),
    };

    return new QuestionsModel(data);
  }

  static deserializeList(questionsList: IQuestions[]): QuestionsModel[] {
    return questionsList
      ? questionsList.map((apiData: IQuestions) => new QuestionsModel(QuestionsModel.deserialize(apiData)))
      : [];
  }
}

export class NotesModel {
  id: number;
  childrenSurveyId: number;
  sectionId: number;
  noteText: string;
  createdOn: string;

  constructor(data?: Partial<NotesModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: INotes): NotesModel {
    if (!apiData) {
      return new NotesModel();
    }

    const data: Partial<NotesModel> = {
      id: apiData.Id,
      childrenSurveyId: apiData.ChildrenSurveyId,
      sectionId: apiData.SectionId,
      noteText: apiData.NoteText,
      createdOn: apiData.CreatedOn,
    };

    return new NotesModel(data);
  }

  static deserializeList(noteList: INotes[]): NotesModel[] {
    return noteList ? noteList.map((apiData: INotes) => new NotesModel(NotesModel.deserialize(apiData))) : [];
  }
}

export class ResponsesModel {
  surveyQuestionId: number;
  responseText: string;
  orderIndex: number;

  constructor(data?: Partial<ResponsesModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IResponses): ResponsesModel {
    if (!apiData) {
      return new ResponsesModel();
    }

    const data: Partial<ResponsesModel> = {
      surveyQuestionId: apiData.SurveyQuestionId,
      responseText: apiData.ResponseText,
      orderIndex: apiData.OrderIndex,
    };

    return new ResponsesModel(data);
  }

  static deserializeList(responseList: IResponses[]): ResponsesModel[] {
    return responseList
      ? responseList.map((apiData: IResponses) => new ResponsesModel(ResponsesModel.deserialize(apiData)))
      : [];
  }
}
