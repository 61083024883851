import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { CustomDataGrid, Utils } from '@shared';
import { ChildSurveyAssessmentReportsModel } from '@models';
import { useStyles } from './Reports.styles';

type Props = {
  reports?: ChildSurveyAssessmentReportsModel[];
  path?: string;
};

const Reports: FC<Props> = ({ reports, path }: Props) => {
  const classes = useStyles();

  const columns = [
    {
      field: 'surveyName',
      headerName: 'Assessment Version',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => `SWYC ${params.value}`,
    },
    {
      field: 'submittedOn',
      headerName: 'Asssessment Due',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },
    {
      field: 'status',
      headerName: 'Assessment Status',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => (
        <>
          {params.row?.displayReportLink && (
            <Link
              target="_blank"
              to={
                path === '/eco' || path === '/site'
                  ? `${path}/student-report/${params?.row?.childSurveyGuid}`
                  : `${path}/child-report/${params?.row?.childSurveyGuid}`
              }
              className={classes.link}
            >
              View Report
            </Link>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Box component="div" display="flex" flexDirection="column" padding="20px">
        <CustomDataGrid columns={columns} data={reports} pagination={false} />
      </Box>
    </>
  );
};

export default observer(Reports);
