import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import MobxReactForm, { Field } from 'mobx-react-form';
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Box,
  Chip,
  Select,
  ListItemIcon,
  SelectChangeEvent,
} from '@mui/material';
import { useStyles } from './MultiSelect.styles';
import { Theme, useTheme } from '@mui/material/styles';
import { Check as CheckIcon } from '@mui/icons-material';

type Props = {
  showLabel?: boolean;
  value?: any[];
  options?: any[];
  placeholder?: string;
  label?: string;
  field?: Field;
  form?: MobxReactForm;
  height?: number;
  className?: string;
  error?: boolean;
  onValueChange?: (value) => void;
};

const MultiSelect: FC<Props> = ({
  value,
  label,
  className,
  options,
  placeholder,
  field,
  form,
  height,
  onValueChange,
  error = false,
  showLabel = true,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const showCheckIcon = (id: number): boolean => {
    return value?.some(x => x.value === id);
  };

  const handleChange = (event: SelectChangeEvent<any[]>) => {
    const {
      target: { value },
    } = event;
    onValueChange(value);
  };

  function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  return (
    <div className={className}>
      <InputLabel id="select-label">{showLabel && (field?.label || label)}</InputLabel>
      <Select
        fullWidth
        value={value}
        multiple
        onChange={handleChange}
        displayEmpty
        {...field?.bind()}
        renderValue={(selected: any[]) => {
          if (selected.length === 0) {
            return <>{placeholder}</>;
          } else {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map(item => (
                  <Chip key={item.value} label={item.label} style={{ backgroundColor: '#BAE4EE' }} />
                )) || placeholder}
              </Box>
            );
          }
        }}
        error={Boolean((Boolean(form?.submitted) || field?.touched) && (field?.hasError || error))}
        sx={{ minWidth: 400, height: height || 55, color: value || value.length === 0 ? '#BCBCBC' : 'black' }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: theme => theme.palette.primary.main,
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: theme => theme.palette.primary[100],
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                backgroundColor: theme => theme.palette.primary.main,
              },
            },
          },
        }}
      >
        {options?.map(item => {
          return (
            <MenuItem value={item} key={item?.value} style={getStyles(item, value, theme)}>
              {showCheckIcon(item?.value) && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
              {item?.label}
            </MenuItem>
          );
        })}
      </Select>
      {Boolean((Boolean(form?.submitted) || field?.touched) && (field?.hasError || error)) && (
        <FormHelperText className={classes.errorText}>{`${
          placeholder || field?.placeholder
        } field is required.`}</FormHelperText>
      )}
    </div>
  );
};
export default observer(MultiSelect);
