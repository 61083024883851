import React, { FC, useEffect } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import MobxReactForm, { Field } from 'mobx-react-form';
import { Button, Box, Typography, Unstable_Grid2 as Grid, FormControlLabel, Checkbox } from '@mui/material';
import { CustomDialog, CustomDropDown, FormInputControl, getFormValidation, useNotification } from '@shared';
import { UIStore, FamilyStore, AuthStore, RootStore } from '@stores';
import { CommonService, FamilyService } from '@services';
import { FamilyModel, OptionsModel } from '@models';
import { StepOneFields } from './StepOneFields';
import { useStyles } from './StepOne.styles';

type Props = {
  onNext?: () => void;
  onCancel?: () => void;
  familyStore?: FamilyStore;
};

const form: MobxReactForm = getFormValidation(StepOneFields, null);

const StepOne: FC<Props> = ({ familyStore, onNext, onCancel }: Props) => {
  const classes = useStyles();
  const params = useParams();
  const notification = useNotification();
  const observable = useLocalObservable(() => ({
    isArchived: false,
    isOpen: false,
    accessToken: '',
  }));

  useEffect(() => {
    getProviderList();
    return () => {
      clear();
    };
  }, []);

  const getProviderList = () => {
    UIStore.showLoader();
    CommonService.getProviderOptions().subscribe({
      next: (response: OptionsModel[]) => {
        familyStore.setProviders(response);
        if (params?.id) {
          getFamilyById();
          return;
        }
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const getFamilyById = () => {
    UIStore.showLoader();
    FamilyService.getFamilyById(params?.id).subscribe({
      next: (response: FamilyModel) => {
        const { firstName, lastName, email, phoneNumber, providerId, isArchived } = response;
        runInAction(() => {
          observable.isArchived = isArchived;
        });
        form.$('firstName').set(firstName);
        form.$('lastName').set(lastName);
        form.$('email').set(email);
        form.$('phoneNumber').set(phoneNumber);
        form.$('providerId').set(providerId);
        familyStore.setFamily(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const clear = () => {
    form.reset();
    runInAction(() => {
      form.$submitted = 0;
    });
  };

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value, field: string) => {
    getField(field).set(value);
  };

  const onArchived = (isArchived: boolean) => {
    runInAction(() => {
      observable.isArchived = isArchived;
    });
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
    });
  };

  const addFamily = req => {
    FamilyService.addFamily(req).subscribe({
      next: (response: FamilyModel) => {
        familyStore.setFamily(response);
        UIStore.hideLoader();
        onNext();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const updateFamily = req => {
    FamilyService.updateFamily(req).subscribe({
      next: (response: FamilyModel) => {
        familyStore.setFamily(response);
        UIStore.hideLoader();
        onNext();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    const { firstName, lastName, email, phoneNumber, providerId } = form.values();
    const req = { firstName, lastName, email, phoneNumber, providerId };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        if (params?.id) {
          updateFamily({ ...req, familyGuid: familyStore.family?.guid, isArchived: observable.isArchived });
          return;
        }
        addFamily(req);
      },
      onError: error => {},
    });
  };

  const getAccessToken = () => {
    UIStore.showLoader();
    FamilyService.familyAccessToken(familyStore?.family?.id).subscribe({
      next: (accessToken: string) => {
        UIStore.hideLoader();
        runInAction(() => {
          observable.accessToken = accessToken;
          observable.isOpen = true;
        });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const navigate = useNavigate();
  const useAccessToken = () => {
    UIStore.showLoader();
    FamilyService.familyAccessToken(familyStore?.family?.id).subscribe({
      next: (accessToken: string) => {
        UIStore.hideLoader();
        runInAction(() => {
          AuthStore.clear();
          RootStore.clear();
          navigate(`/access-token?token=${accessToken}`);
        });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  return (
    <Box className={classes.innerContainer}>
      <Typography>
        Please enter the family information below. Then click 'Save & Move Next' to continue to the child section
      </Typography>
      <Grid container spacing={3} marginTop="12px">
        <Grid xs={12} xsOffset={3} md={6}>
          <FormInputControl
            field={form.$('firstName')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'firstName')}
            form={form}
          />
          <FormInputControl
            field={form.$('lastName')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'lastName')}
            form={form}
          />
          <FormInputControl
            field={form.$('email')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'email')}
            form={form}
          />
          <FormInputControl
            field={form.$('phoneNumber')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'phoneNumber')}
            form={form}
          />
          <CustomDropDown
            value={form.$('providerId').value}
            field={form.$('providerId')}
            options={familyStore.providers}
            className={classes.selectBox}
            placeholder={form.$('providerId').label}
            onValueChange={value => handleChange(value, 'providerId')}
            form={form}
          />
          {params?.id && (
            <div style={{ marginTop: 10 }}>
              <FormControlLabel
                control={<Checkbox checked={observable.isArchived} onChange={e => onArchived(e.target.checked)} />}
                label={<Typography>Archive</Typography>}
              />
            </div>
          )}
          <Box className={classes.btnWrapper}>
            {params?.id && (
              <Button variant="contained" onClick={getAccessToken} className={classes.btn}>
                Access Token
              </Button>
            )}
            {params?.id && (
              <Button variant="contained" onClick={useAccessToken} className={classes.btn}>
                Use Token
              </Button>
            )}
            {params?.id && (
              <Button className={classes.cancelBtn} onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button variant="contained" onClick={onSubmit} className={classes.btn}>
              {params?.id ? 'Update & Move Next' : 'Save & Move Next'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CustomDialog
        open={observable.isOpen}
        title="Access Token"
        onClose={handleClose}
        onSubmit={handleClose}
        buttonText="Ok"
        fullWidth={true}
        height="25%"
      >
        <Box component="div" display="flex" alignItems="center" justifyContent="center" height="100%">
          <Typography variant="body1">{`Access token is ${observable.accessToken}`}</Typography>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default inject('familyStore')(observer(StepOne));
