import { ISite } from '../Interfaces';
import { UserModel } from './User.model';

export class SiteModel {
  id: number;
  guid: string;
  name: string;
  phoneNumber: string;
  address: string;
  active: boolean;
  isAssociated?: boolean;
  ecoSiteMappingId?: number;
  siteUsers: UserModel[];
  ecoName: string;

  constructor(data?: Partial<SiteModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ISite): SiteModel {
    if (!apiData) {
      return new SiteModel();
    }

    const data: Partial<SiteModel> = {
      id: apiData.Id,
      guid: apiData.SiteGuid,
      name: apiData.Name,
      phoneNumber: apiData.PhoneNumber,
      address: apiData.Address,
      active: apiData.Active,
      isAssociated: apiData.IsAssociated,
      ecoSiteMappingId: apiData.EcoSiteMappingId,
      siteUsers: UserModel.deserializeList(apiData.SiteUsers),
      ecoName: apiData.ECOName,
    };

    return new SiteModel(data);
  }

  static deserializeList(siteList: ISite[]): SiteModel[] {
    return siteList ? siteList.map((apiData: ISite) => new SiteModel(SiteModel.deserialize(apiData))) : [];
  }
}
