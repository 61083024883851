import React, { FC } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { observer, useLocalObservable } from 'mobx-react';
import { MobxReactForm, Field } from 'mobx-react-form';
import { Box, Button, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import {
  AuthStore,
  CustomDropDown,
  FormInputControl,
  getFormValidation,
  LocalStorageService,
  UIStore,
  useNotification,
  UserService,
} from '@shared';
import { IDropdownOptions } from '@interfaces';
import { useStyles } from '../AccountConsent.styles';
import { DemographicFields } from '../Fields';
import {
  CountyOptions,
  GenderOptions,
  HasHousholdIncomeOptions,
  HouseholdOptions,
  EthnicityOptions,
  RaceOptions,
  AgeGroupOptions,
  IncomeCategories,
  ColumbiaResidentOptions
} from './Options';

enum County {
  OTHER = 'Other',
}

const form: MobxReactForm = getFormValidation(DemographicFields, null);

const DemographicsForm: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const notification = useNotification();

  const observable = useLocalObservable(() => ({
    yearly: 0,
    monthly: 0
  }));

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value, field: string) => {
    getField(field).set(value);
    if (field === 'countyName') {
      const required = value === County.OTHER ? 'required|string' : 'string';
      getField('countyOtherName').set('rules', required);
    }
    else if (field==='houseHoldSize'){
      observable.yearly=IncomeCategories.filter(f=>f.members===value)[0].yearly;
      observable.monthly=IncomeCategories.filter(f=>f.members===value)[0].monthly;
    }
  };

  const submit = () => {
    form.submit({
      onSuccess: () => {
        const {
          houseHoldSize,
          hasHouseHoldIncomeOver2092PerMonth,
          countyName,
          ethnicityId,
          raceId,
          genderId,
          ageGroupId,
          countyOtherName,
          columbiaResident
        } = form.values();
        const req = {
          userId: Number(AuthStore.authUser?.id),
          houseHoldSize,
          hasHouseHoldIncomeOver2092PerMonth: Boolean(Number(hasHouseHoldIncomeOver2092PerMonth)),
          countyName: countyName === County.OTHER ? countyOtherName : countyName,
          ethnicityId: Number(ethnicityId),
          raceId: Number(raceId),
          genderId: Number(genderId),
          ageGroupId: Number(ageGroupId),
          columbiaResident: Number(columbiaResident)
        };
        UIStore.showLoader();
        UserService.userConsent(req).subscribe({
          next: response => {
            UIStore.hideLoader();
            AuthStore.setHasConsent(response);
            form.reset();
            const redirectUrl = LocalStorageService.getRedirectUrl();
            if (redirectUrl) {
              LocalStorageService.removeRedirectUrl();
              navigate(redirectUrl);
              return;
            }
            navigate('/caregiver/children');
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const renderOptions = (options: IDropdownOptions[]) => {
    return options.map((item, index) => (
      <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} />
    ));
  };

  const { countyName } = form.values();

  return (
    <Box className={classes.demoContainer}>
      <Typography>Please fill in the following information for yourself as the account holder</Typography>
      <Box marginTop="2rem">
        <CustomDropDown
          value={form.$('houseHoldSize').value}
          label={form.$('houseHoldSize').label}
          error={form.$('houseHoldSize').hasError}
          placeholder={form.$('houseHoldSize').placeholder}
          options={HouseholdOptions}
          onValueChange={value => handleChange(value, 'houseHoldSize')}
          className={classes.selectBox}
          form={form}
        />
        <div className={classes.radioBtn}>
          <FormLabel className={classes.radioLabel}>
            My household income is Over ${observable.yearly} per year or ${observable.monthly} per month
          </FormLabel>
          <RadioGroup
            row
            value={form.$('hasHouseHoldIncomeOver2092PerMonth').value}
            onChange={e => handleChange(e.target.value, 'hasHouseHoldIncomeOver2092PerMonth')}
          >
            {renderOptions(HasHousholdIncomeOptions)}
          </RadioGroup>
          {Boolean(form?.submitted) && form.$('hasHouseHoldIncomeOver2092PerMonth').hasError && (
            <FormHelperText className={classes.errorText}> Household income field is required.</FormHelperText>
          )}
        </div>
        <CustomDropDown
          value={form.$('countyName').value}
          label={form.$('countyName').label}
          error={form.$('countyName').hasError}
          placeholder={form.$('countyName').placeholder}
          options={CountyOptions}
          onValueChange={value => handleChange(value, 'countyName')}
          className={classes.selectBox}
          form={form}
        />
        {countyName === County.OTHER && (
          <FormInputControl
            field={form.$('countyOtherName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'countyOtherName')}
            form={form}
          />
        )}
        <div className={classes.radioBtn}>
          <FormLabel className={classes.radioLabel}>Race</FormLabel>
          <RadioGroup value={form.$('raceId').value} onChange={e => handleChange(e.target.value, 'raceId')}>
            {renderOptions(RaceOptions)}
          </RadioGroup>
          {Boolean(form?.submitted) && form.$('raceId').hasError && (
            <FormHelperText className={classes.errorText}>Race field is required.</FormHelperText>
          )}
        </div>
        <div className={classes.radioBtn}>
          <FormLabel className={classes.radioLabel}>Ethnicity</FormLabel>
          <RadioGroup value={form.$('ethnicityId').value} onChange={e => handleChange(e.target.value, 'ethnicityId')}>
            {renderOptions(EthnicityOptions)}
          </RadioGroup>
          {Boolean(form?.submitted) && form.$('ethnicityId').hasError && (
            <FormHelperText className={classes.errorText}>Ethnicity field is required.</FormHelperText>
          )}
        </div>
        <CustomDropDown
          value={form.$('genderId').value}
          label={form.$('genderId').label}
          placeholder={form.$('genderId').placeholder}
          field={form.$('genderId')}
          options={GenderOptions}
          onValueChange={value => handleChange(value, 'genderId')}
          className={classes.selectBox}
          form={form}
        />
        <div className={classes.radioBtn}>
          <FormLabel className={classes.radioLabel}>My Age Group is</FormLabel>
          <RadioGroup row value={form.$('ageGroupId').value} onChange={e => handleChange(e.target.value, 'ageGroupId')}>
            {renderOptions(AgeGroupOptions)}
          </RadioGroup>
          {Boolean(form?.submitted) && form.$('ageGroupId').hasError && (
            <FormHelperText className={classes.errorText}>Age Group is required.</FormHelperText>
          )}
        </div>
        <div className={classes.radioBtn}>
          <FormLabel className={classes.radioLabel}>I am a Columbia Resident</FormLabel>
          <RadioGroup row value={form.$('columbiaResident').value} onChange={e => handleChange(e.target.value, 'columbiaResident')}>
            {renderOptions(ColumbiaResidentOptions)}
          </RadioGroup>
          {Boolean(form?.submitted) && form.$('columbiaResident').hasError && (
            <FormHelperText className={classes.errorText}>Columbia residency is required.</FormHelperText>
          )}
        </div>
      </Box>
      <div className={classes.btn}>
        <Button variant="contained" className={classes.submitBtn} onClick={submit}>
          Submit
        </Button>
      </div>
    </Box>
  );
};

export default observer(DemographicsForm);
