import { map, Observable } from 'rxjs';
import { GridSortModel } from '@mui/x-data-grid';
import { ECOModel, ECOPaginationModel, UserModel } from '../Models';
import { IAPIResponse, IECO, IECOPagination, IUser } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { Sort } from '../Enums';

class ECOService {
  public getAllECOs(
    pageNumber: number,
    pageSize: number,
    sortModel: GridSortModel,
    query: string,
    status: number
  ): Observable<ECOPaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      PageNumber: pageNumber,
      PageSize: pageSize,
      Sort: sortModel[0]?.field || 'name',
      Dir: Sort[sortModel[0]?.sort] || Sort.asc,
      Query: query,
      Status: status >= 0 ? status : null,
    });
    return http
      .get<IAPIResponse<IECOPagination>>(`${ApiUrl.ecos}?${params}`)
      .pipe(map((response: IAPIResponse<IECOPagination>) => ECOPaginationModel.deserialize(response.Data)));
  }

  public addEco(req): Observable<ECOModel> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<IECO>>(ApiUrl.ecos, req)
      .pipe(map((response: IAPIResponse<IECO>) => ECOModel.deserialize(response.Data)));
  }

  public deleteECO(ecoGuid: string): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.ecos}/${ecoGuid}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public getECOById(ecoGuid: string): Observable<ECOModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IECO>>(`${ApiUrl.ecos}/${ecoGuid}`)
      .pipe(map((response: IAPIResponse<IECO>) => ECOModel.deserialize(response.Data)));
  }

  public updateEco(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.ecos, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public addEcoUser(req): Observable<UserModel> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<IUser>>(ApiUrl.ecoUser, req)
      .pipe(map((response: IAPIResponse<IUser>) => UserModel.deserialize(response.Data)));
  }

  public deleteEcoUser(ecoId: number, userId: number): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ ecoId, userId });
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.ecoUser}?${params}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public ecoUsers(ecoGuid: string): Observable<UserModel[]> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ ecoGuid });
    return http
      .get<IAPIResponse<IUser[]>>(`${ApiUrl.ecoUsers}?${params}`)
      .pipe(map((response: IAPIResponse<IUser[]>) => UserModel.deserializeList(response.Data)));
  }

  public getEcoUserById(id: number): Observable<UserModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ id });
    return http
      .get<IAPIResponse<IUser>>(`${ApiUrl.ecoUser}?${params}`)
      .pipe(map((response: IAPIResponse<IUser>) => UserModel.deserialize(response.Data)));
  }

  public updateEcoUser(req): Observable<UserModel> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<IUser>>(ApiUrl.ecoUser, req)
      .pipe(map((response: IAPIResponse<IUser>) => UserModel.deserialize(response.Data)));
  }
}

export default new ECOService();
