import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './About.styles';

const About: FC = () => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.boxContainer}>
      <Typography variant="h5" fontWeight={600}>
        About The SWYC
      </Typography>
      <Typography variant="body1" mt={2} fontWeight={600}>
        Why screening is important:
      </Typography>
      <Typography variant="body1" mt={1}>
        During a child's first five years, development occurs at a rate faster than any other stage of life. During this
        time, children are gaining social and emotional skills that are connected to every area of growth and
        development. It is crucial to be aware, through the use of screening, of potential risk factors and delays
        within this developmental period.
      </Typography>
      <Typography variant="body1" mt={2} fontWeight={600}>
        What is the SWYC:
      </Typography>
      <Typography variant="body1" mt={1}>
        The SWYC is a first-level screening tool for children ages 0-5. This tool can be completed in 10 minutes, and
        can assess a child's well-being within developmental milestones, social emotional health, autism spectrum
        disorder, and family context.
      </Typography>
      <Typography variant="body1" mt={2} fontWeight={600}>
        The BCECC SWYC Screening Hub services are offered through the MU School of Medicine with support and funding
        from the Boone County Children's Services Board. The BCECC SWYC Screening Hub was updated and enhanced in 2023
        with support and funding from the Missouri Children’s Trust Fund, Facility Improvement and Infrastructure Grant.
      </Typography>
    </Box>
  );
};

export default About;
