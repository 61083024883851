import { map, Observable } from 'rxjs';
import { GridSortModel } from '@mui/x-data-grid';
import { PaginationModel, ProfileModel, UserModel } from '../Models';
import { IAPIResponse, IPagination, IProfile, IUser } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { Sort } from '../Enums';

class UserService {

    public getAllUsers(
        pageNumber: number,
        pageSize: number,
        sortModel: GridSortModel,
        query: string,
        type: number
    ): Observable<PaginationModel> {
        const http: HttpClient = new HttpClient();
        const params = Utils.buildParamString({
            PageNumber: pageNumber,
            PageSize: pageSize,
            Sort: sortModel[0]?.field || 'firstName',
            Dir: Sort[sortModel[0]?.sort] || Sort.asc,
            Query: query,
            Type: type || null,
        })
        return http
            .get<IAPIResponse>(`${ApiUrl.users}?${params}`)
            .pipe(map((response: IAPIResponse<IPagination>) => PaginationModel.deserialize(response.Data)));
    }

    public getUserById(id: number): Observable<UserModel> {
        const http: HttpClient = new HttpClient();
        return http
            .get<IAPIResponse<IUser>>(`${ApiUrl.users}/${id}`)
            .pipe(map((response: IAPIResponse<IUser>) => UserModel.deserialize(response.Data)));
    }

    public addUser(req): Observable<number> {
        const http: HttpClient = new HttpClient();
        return http
            .post<IAPIResponse<number>>(ApiUrl.users, req)
            .pipe(map((response: IAPIResponse<number>) => (response.Data)));
    }

    public deleteUser(id: number): Observable<boolean> {
        const http: HttpClient = new HttpClient();
        return http
            .delete<IAPIResponse<boolean>>(`${ApiUrl.users}/${id}`)
            .pipe(map((response: IAPIResponse<boolean>) => response.Data));
    }

    public updateUser(req): Observable<boolean> {
        const http: HttpClient = new HttpClient();
        return http
            .put<IAPIResponse<boolean>>(ApiUrl.users, req)
            .pipe(map((response: IAPIResponse<boolean>) => (response.Data)));
    }

    public userConsent(req): Observable<boolean> {
        const http: HttpClient = new HttpClient();
        return http
            .post<IAPIResponse<boolean>>(ApiUrl.consent, req)
            .pipe(map((response: IAPIResponse<boolean>) => (response.Data)));
    }

    public userProfile(): Observable<ProfileModel> {
        const http: HttpClient = new HttpClient();
        return http
            .get<IAPIResponse<IProfile>>(ApiUrl.userProfile)
            .pipe(map((response: IAPIResponse<IProfile>) => ProfileModel.deserialize(response.Data)));
    }

}

export default new UserService();
