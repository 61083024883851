import { map, Observable } from 'rxjs';
import { FamilyModel, FamilyPaginationModel } from '../Models';
import { IAPIResponse, IFamily, IFamilyPagination } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { GridSortModel } from '@mui/x-data-grid';
import { Sort } from '../Enums';
import { RegisterFamily } from '../Models/RegisterFamily.model';

class FamilyService {
  public addFamily(req): Observable<FamilyModel> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<IFamily>>(ApiUrl.families, req)
      .pipe(map((response: IAPIResponse<IFamily>) => FamilyModel.deserialize(response.Data)));
  }

  public getFamilyById(id: string): Observable<FamilyModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IFamily>>(`${ApiUrl.families}/${id}`)
      .pipe(map((response: IAPIResponse<IFamily>) => FamilyModel.deserialize(response.Data)));
  }

  public updateFamily(req): Observable<FamilyModel> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<IFamily>>(ApiUrl.families, req)
      .pipe(map((response: IAPIResponse<IFamily>) => FamilyModel.deserialize(response.Data)));
  }

  public getFamilyList(
    pageNumber: number,
    pageSize: number,
    sortModel: GridSortModel,
    query: string,
    isArchived = false,
    providerId?: number,
    status?: number
  ): Observable<FamilyPaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      PageNumber: pageNumber,
      PageSize: pageSize,
      Sort: sortModel[0]?.field || 'fullName',
      Dir: Sort[sortModel[0]?.sort] || Sort.asc,
      IsArchived: isArchived,
      ProviderId: providerId || null,
      Query: query,
      Status: status >= 0 ? status : null,
    });
    return http
      .get<IAPIResponse<IFamilyPagination>>(`${ApiUrl.families}?${params}`)
      .pipe(map((response: IAPIResponse<IFamilyPagination>) => FamilyPaginationModel.deserialize(response.Data)));
  }

  public deleteFamily(id: string): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.families}/${id}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public familyAccessToken(id: number): Observable<string> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<string>>(`${ApiUrl.familyAccessToken}/${id}`, '')
      .pipe(map((response: IAPIResponse<string>) => response.Data));
  }

  public registerFamily(registration: RegisterFamily): Observable<string> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<string>>(`${ApiUrl.registerFamily}`, registration)
      .pipe(map((response: IAPIResponse<string>) => response.Data));
  }
}

export default new FamilyService();
