import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { CustomDataGrid, Utils } from '@shared';
import { ReferralsModel } from '@models';

type Props = {
  referrals?: ReferralsModel[];
};

const Referrals: FC<Props> = ({ referrals }: Props) => {
  const columns = [
    {
      field: 'referredTo',
      headerName: 'Provider Name',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'referredBy',
      headerName: 'Referred By',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'referralDate',
      headerName: 'Referral Date',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },
  ];

  return (
    <>
      <Box component="div" display="flex" flexDirection="column" padding="20px">
        <CustomDataGrid columns={columns} data={referrals} pagination={false} />
      </Box>
    </>
  );
};

export default observer(Referrals);
