import { IChildren } from '../Interfaces';

export class ChildrenModel {
  id: number;
  firstName: string;
  lastName: string;
  caregiverOrSiteName: string;
  birthDate: string;
  childName: string;
  providerName: string;
  weekPremature: number;
  parentEntityId: number;
  parentEntityType: number;
  teacherId: number;
  teacherName: string;
  ageGroupId?: number;
  isSubmitted?: boolean;
  studentId: string;
  submittedOn?: string;
  completedDate?: string;
  surveyCompleted?: string;
  isSelected?: boolean = false;
  childSurveyGuid?: string;
  showProgress?: boolean = false;
  showTakeSurvey?: boolean = false;
  totalSurveySubmitted?: number;
  swycVersion?: string;
  isReviewed?: boolean = false;
  needReview?: boolean = false;

  constructor(data?: Partial<ChildrenModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IChildren): ChildrenModel {
    if (!apiData) {
      return new ChildrenModel();
    }

    const data: Partial<ChildrenModel> = {
      id: apiData.Id,
      teacherId: apiData.TeacherId,
      teacherName: apiData.TeacherName,
      firstName: apiData.FirstName,
      lastName: apiData.LastName,
      caregiverOrSiteName: apiData.CareGiverOrSiteName,
      birthDate: apiData.BirthDate,
      childName: apiData.ChildName,
      providerName: apiData.ProviderName,
      weekPremature: apiData.WeekPremature,
      parentEntityId: apiData.ParentEntityId,
      parentEntityType: apiData.ParentEntityType,
      ageGroupId: apiData.AgeGroupId,
      isSubmitted: apiData.IsSubmitted,
      studentId: apiData.StudentId,
      submittedOn: apiData.SubmittedOn,
      completedDate: apiData.CompletedDate,
      surveyCompleted: apiData.SurveyCompleted,
      childSurveyGuid: apiData.ChildSurveyGuid,
      showProgress: apiData.ShowProgress,
      showTakeSurvey: apiData.ShowTakeSurvey,
      totalSurveySubmitted: apiData.TotalSurveySubmitted,
      swycVersion: apiData.SWYCVersion,
      isReviewed: apiData.IsReviewed,
      needReview: apiData.NeedReview,
    };

    return new ChildrenModel(data);
  }

  static deserializeList(childrenList: IChildren[]): ChildrenModel[] {    
    return childrenList
      ? childrenList.map((apiData: IChildren) => new ChildrenModel(ChildrenModel.deserialize(apiData)))
      : [];
  }
}
