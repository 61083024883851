import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import MobxReactForm, { Field } from 'mobx-react-form';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { Box, Button, Card, CircularProgress, InputAdornment, Typography } from '@mui/material';
import { Lock as LockIcon, ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { Logo } from '@assets';
import { FormInputControl, getFormValidation, useNotification } from '@shared';
import { UIStore, AuthStore } from '@stores';
import { PasswordFields } from './fields';
import { useStyles } from './ConfirmPassword.styles';

const form: MobxReactForm = getFormValidation(PasswordFields, null);

const ForgotPassword: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const notification = useNotification();
  const [isInvalid, setInvalid] = useState(false);

  useEffect(() => {
    validateUser();
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value.trim());
  };

  const validateUser = () => {
    AuthStore.validateUser(params.token).subscribe({
      next: (isValid: boolean) => {
        if (!isValid) {
          setInvalid(true);
        }
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error.message);
      },
    });
  };

  const onReset = () => {
    const { password } = form.values();
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        AuthStore.resetPassword(params.token, password).subscribe({
          next: (response: boolean) => {
            UIStore.hideLoader();
            if (!response) {
              notification.error('Something went wrong!');
              return;
            }
            notification.success('Password has been reset successfully!');
            navigate('/login');
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error.message);
          },
        });
      },
      onError: error => {},
    });
  };

  return (
    <div className={classes.mainWrapper}>
      <Card className={classes.container} elevation={4}>
        {isInvalid ? (
          <div className={classes.invalidWrapper}>
            <ErrorOutlineIcon className={classes.errorIcon} />
            <Typography variant="h5" sx={{ marginTop: 2 }}>
              Something went wrong!
            </Typography>
          </div>
        ) : (
          <>
            <div className={classes.imageContainer}>
              <img src={Logo} className={classes.image} alt="logo"></img>
            </div>
            <form>
              <Box component="div" display="flex" flexDirection="column">
                <FormInputControl
                  showLabel={true}
                  field={form.$('password')}
                  onValueChange={value => handleChange(value, 'password')}
                  isAdornment={true}
                  form={form}
                  type="password"
                  styleClasses={{ inputControl: classes.input }}
                  startAdornment={
                    <InputAdornment position="start" className={classes.inputIcon}>
                      <LockIcon />
                    </InputAdornment>
                  }
                />
                <FormInputControl
                  showLabel={true}
                  field={form.$('confirmPassword')}
                  onValueChange={value => handleChange(value, 'confirmPassword')}
                  isAdornment={true}
                  form={form}
                  type="password"
                  styleClasses={{ inputControl: classes.input }}
                  startAdornment={
                    <InputAdornment position="start" className={classes.inputIcon}>
                      <LockIcon />
                    </InputAdornment>
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.resetButton}
                  onClick={onReset}
                  disabled={UIStore.pageLoading}
                >
                  {UIStore.pageLoading ? (
                    <CircularProgress className={classes.progress} style={{ width: 30, height: 30 }} />
                  ) : (
                    <Typography>Reset</Typography>
                  )}
                </Button>
              </Box>
            </form>
          </>
        )}
      </Card>
    </div>
  );
};

export default observer(ForgotPassword);
