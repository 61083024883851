import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Typography, Button, List, ListItem, Checkbox, ListItemButton, ListItemText } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import { Actions, CustomDataGrid, CustomDialog, DeleteDialog, SiteStatus, useNotification, UserSearch } from '@shared';
import { UIStore, SiteStore } from '@stores';
import { CircleCheckGreen, CircleCheckGrey, CrossIcon, EditIcon, VisibilityIcon } from '@assets';
import { EcoSiteMappingService, SiteService } from '@services';
import { ECOModel, SiteModel, SitePaginationModel } from '@models';
import { useStyles } from './Site.styles';
import { Loop as LoopIcon } from '@mui/icons-material';

type Props = {
  siteStore?: SiteStore;
};

const Site: FC<Props> = ({ siteStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const navigate = useNavigate();
  const observable = useLocalObservable(() => ({
    site: new SiteModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    showEcos: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    status: null,
    totalNumberOfRecords: 0,
    sortModel: [],
  }));
  const { ecos } = siteStore;

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'ecoName', headerName: 'ECO', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'phoneNumber', headerName: 'Phone', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'active',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params =>
        params.value ? <img src={CircleCheckGreen} alt="active" /> : <img src={CircleCheckGrey} alt="inactive" />,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    getSiteList();
  }, []);

  const handleClose = () => {
    runInAction(() => {
      observable.isDelete = false;
      observable.showEcos = false;
    });
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getSiteList();
  };

  const onChange = (checked: boolean, ecoId: number) => {
    const index = ecos?.findIndex(x => x.id === ecoId);
    if (index < 0) {
      return;
    }
    const updatedEcos = {
      ...ecos[index],
      isAssociated: checked,
    };
    siteStore.setEcos([...ecos.slice(0, index), updatedEcos, ...ecos.slice(index + 1)]);
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getSiteList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
    });
    getSiteList();
  };

  const getSiteList = () => {
    const { pageNumber, pageSize, sortModel, query, status } = observable;
    UIStore.showLoader();
    SiteService.getAllSites(pageNumber, pageSize, sortModel, query, status).subscribe({
      next: (response: SitePaginationModel) => {      
        //set id for Super Admin to see all Sites/ECO/Cycles
        //response.results=response.results.map(m=>{return{m.id.toString() +'.'+ m.ecoSiteMappingId.toString(),...m};});  
        response.results.forEach(f=>f.id=parseFloat(f.id.toString() +'.'+ f.ecoSiteMappingId.toString()));
        debugger;
        siteStore.setSiteList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSearch = (searchText: string, status: number) => {
    runInAction(() => {
      observable.query = searchText;
      observable.status = status - 1;
      observable.pageNumber = 1;
    });
    getSiteList();
  };

  const getEcos = () => {
    UIStore.showLoader();
    EcoSiteMappingService.getEcos(observable.site?.id).subscribe({
      next: (response: ECOModel[]) => {
        siteStore.setEcos(response);
        UIStore.hideLoader();
        runInAction(() => {
          observable.showEcos = true;
        });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onAction = (site: SiteModel, isEdit = false, isMapping = false) => {
    runInAction(() => {
      observable.site = site;
      if (isMapping) {
        getEcos();
        return;
      }
      if (!isEdit) {
        observable.isDelete = true;
        return;
      }
      navigate(`/admin/sites/${site.guid}`);
    });
  };

  const deleteSite = () => {
    UIStore.showLoader();
    SiteService.deleteSite(observable.site?.guid).subscribe({
      next: (response: boolean) => {
        handleClose();
        getSiteList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
      observable.status = null;
    });
    getSiteList();
  };

  const onSubmit = () => {
    const filteredEcosIds = ecos?.filter(eco => eco.isAssociated)?.map(eco => eco.id);
    const req = {
      siteId: observable.site?.id,
      ecoIds: filteredEcosIds,
    };
    UIStore.showLoader();
    EcoSiteMappingService.mapEcosToSite(req).subscribe({
      next: (response: boolean) => {
        UIStore.hideLoader();
        handleClose();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const renderActions = params => {
    return (
      <>
        <Actions btnText="Edit" onClick={() => onAction(params.row, true)} imgSrc={EditIcon} />
        <Actions btnText="Delete" onClick={() => onAction(params.row)} imgSrc={CrossIcon} />
        <Actions btnText="Ecos" onClick={() => onAction(params.row, false, true)} imgSrc={VisibilityIcon} />
        <Actions
          btnText="Cycles"
          icon={true}
          iconSrc={<LoopIcon sx={{ color: theme => theme.palette.common.black }} />}
          onClick={() => navigate(`/admin/cycles/${params.row?.ecoSiteMappingId}`)}
        />
      </>
    );
  };

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Sites
      </Typography>
      <UserSearch options={SiteStatus} showUserType={true} onSearch={onSearch} onClear={onClear} />
      <Box component="div" display="flex" flexDirection="column">
        <Button className={classes.button} onClick={() => navigate('/admin/add-site')}>
          <AddCircleIcon className={classes.icon} />
          <Typography variant="body1" className={classes.text}>
            ADD SITE
          </Typography>
        </Button>
        <CustomDataGrid
          columns={columns}
          data={siteStore.siteList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
        />
      </Box>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this site?"
          handleClose={handleClose}
          handleDelete={deleteSite}
        />
      )}
      {observable.showEcos && (
        <CustomDialog open={observable.showEcos} title="Ecos" onClose={handleClose} onSubmit={onSubmit}>
          <List dense className={classes.list}>
            {ecos?.map(eco => {
              const labelId = `checkbox-list-secondary-label-${eco.id}`;
              return (
                <ListItem
                  key={eco.id}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={e => onChange(e.target.checked, eco.id)}
                      checked={eco.isAssociated}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText id={labelId} primary={eco.name} className={classes.listText} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </CustomDialog>
      )}
    </div>
  );
};

export default inject('siteStore')(observer(Site));
