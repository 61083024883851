import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    marginTop: '5rem',
  },
  downloadBtn: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  backBtn: {
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.black}`,
    marginRight: '20px',
    width: 150,
  },
  saveBtn: {
    width: 150,
  },
  heading: {
    fontWeight: 600,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '30px 0px',
  },
}));
