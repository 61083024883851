import { action, makeObservable, observable } from 'mobx';
import { ECOModel, SiteModel, UserModel } from '../Models';

class SiteStore {
  public siteList: SiteModel[] = [];
  public site: SiteModel = new SiteModel();
  public siteUsers: UserModel[] = [];
  public ecos: ECOModel[] = [];

  constructor() {
    makeObservable(this, {
      siteList: observable,
      site: observable,
      siteUsers: observable,
      ecos: observable,
      setSiteList: action,
      setSite: action,
      setSiteUsers: action,
      setEcos: action,
      clear: action,
    });
  }

  public setSiteList(sites: SiteModel[]): void {    
    this.siteList = sites;
  }

  public setSiteUsers(siteUsers: UserModel[]): void {
    this.siteUsers = siteUsers;
  }

  public setSite(site: SiteModel): void {
    this.site = site;
  }

  public setEcos(ecos: ECOModel[]): void {
    this.ecos = ecos;
  }

  public clear(): void {
    this.siteList = [];
    this.siteUsers = [];
    this.ecos = [];
    this.site = new SiteModel();
  }
}

export default SiteStore;
