import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2%',
    margin: "auto"
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '2rem',
    fontSize: '1.2rem',
  },
  btn: {
    marginTop: '1rem',
    fontSize: '18px',
  },
  input: {
    width: 400,
    margin: '16px 0 4px',
    '& .MuiFormHelperText-root': {
      marginTop: '-15px',
      marginBottom: '12px',
      marginLeft: 0,
    },
    '& .MuiInputBase-root': {
      marginTop: '9px',
      marginBottom: '20px',
    },
    '& .MuiInputLabel-root': {
      width: 400,
      top: '-27px',
      transform: 'none',
      maxWidth: '100%',
      pointerEvents: 'all',
      color: theme.palette.grey[500],
      fontWeight: 600,
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px 10px',
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: '5px 8px 8px',
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: '7px 1px 10px',
    },
    '& MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
}));
