import { action, makeObservable, observable } from 'mobx';
import { ChildrenModel, ChildrenPaginationModel, CycleModel, OptionsModel, UserModel } from '../Models';

class CycleStore {
  public cycleList: CycleModel[] = [];
  public cycle: CycleModel = new CycleModel();
  public students: ChildrenModel[] = [];
  public siteUsers: OptionsModel[] = [];

  constructor() {
    makeObservable(this, {
      cycleList: observable,
      cycle: observable,
      students: observable,
      siteUsers: observable,
      setCycleList: action,
      setCycle: action,
      setStudents: action,
      setSiteUsers: action,
      clear: action,
    });
  }

  public setCycleList(cycles: CycleModel[]): void {
    this.cycleList = cycles;
  }

  public setCycle(cycle: CycleModel): void {
    this.cycle = cycle;
  }

  public setStudents(students: ChildrenModel[]): void {
    this.students = students;
  }

  public setSiteUsers(siteUsers: OptionsModel[]): void {
    this.siteUsers = siteUsers;
  }

  public clear(): void {
    this.cycleList = [];
    this.students = [];
    this.siteUsers = [];
    this.cycle = new CycleModel();
  }
}

export default CycleStore;
