import { map, Observable } from 'rxjs';
import { GridSortModel } from '@mui/x-data-grid';
import { TeacherModel, TeacherPaginationModel } from '../Models';
import { IAPIResponse, ITeacher, ITeacherPagination } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { Sort } from '../Enums';

class TeacherService {
  public getAllTeachers(
    pageNumber: number,
    pageSize: number,
    sortModel: GridSortModel,
    query: string,
    siteId?: number
  ): Observable<TeacherPaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      PageNumber: pageNumber,
      PageSize: pageSize,
      Sort: sortModel[0]?.field || 'firstName',
      Dir: Sort[sortModel[0]?.sort] || Sort.asc,
      Query: query,
      SiteId: siteId || null,
    });
    return http
      .get<IAPIResponse<ITeacherPagination>>(`${ApiUrl.teachersPagination}?${params}`)
      .pipe(map((response: IAPIResponse<ITeacherPagination>) => TeacherPaginationModel.deserialize(response.Data)));
  }

  public getTeacherById(id: number): Observable<TeacherModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<ITeacher>>(`${ApiUrl.teachers}/${id}`)
      .pipe(map((response: IAPIResponse<ITeacher>) => TeacherModel.deserialize(response.Data)));
  }

  public addTeacher(req): Observable<number> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<number>>(ApiUrl.teachers, req)
      .pipe(map((response: IAPIResponse<number>) => response.Data));
  }

  public updateTeacher(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.teachers, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public deleteTeacher(id: number): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.teachers}/${id}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }
}

export default new TeacherService();
