import { action, observable, makeObservable } from 'mobx';
class UIStore {
    public pageLoading = false;

    constructor() {
        makeObservable(this, {
            pageLoading: observable,
            showLoader: action,
            hideLoader: action,
        });
    }

    public showLoader(): void {
        this.pageLoading = true;
    }

    public hideLoader(): void {
        this.pageLoading = false;
    }
}

export default new UIStore();