import jwt_decode from 'jwt-decode';
import { IDecodedAuthToken } from '../Interfaces';
import moment from 'moment';

export class Utils {
  static getDecodedAuthToken(accessToken: string): IDecodedAuthToken {
    const decode: IDecodedAuthToken = jwt_decode(accessToken);
    return decode;
  }

  static buildParamString(params: { [key: string]: any }): string {
    const paramsArray: string[] = [];
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        let value;
        if (typeof params[key] === 'boolean') {
          value = params[key] || false;
        } else {
          value = typeof params[key] === 'number' && isFinite(params[key]) ? params[key] : params[key] || '';
        }
        paramsArray.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
    return paramsArray.join('&');
  }

  static formattedDate(dateString: string, displayFormat?: string): string {
    if (!dateString) return '';
    const date = moment(new Date(dateString));
    return date?.isValid() ? date?.format(displayFormat || 'YYYY-MM-DD') : '';
  }

  static getFileAsFormData(file: File): FormData {
    let formDataFile = new FormData();
    formDataFile.append('file', file);
    return formDataFile;
  }
}
