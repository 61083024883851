import React, { FC } from 'react';
import { Navigate } from 'react-router';

type Props = {
    hasPermission: boolean;
    redirectPath?: string;
    isExternalLink?: boolean;
    element: any;
}

const PrivateRoute: FC<Props> = ({
    hasPermission,
    element,
    redirectPath = '/auth/login',
}: Props) => {
    return hasPermission ? element : <Navigate to={redirectPath} replace={true} />;
};

export default PrivateRoute;