import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { Box, Pagination, TablePagination } from '@mui/material';
import { IClasses } from '@interfaces';
import classNames from 'classnames';
import { useStyles } from './CustomDataGrid.styles';

type Props = {
  columns: any;
  data: any;
  pageSize?: number;
  totalNumberOfRecords?: number;
  pageNumber?: number;
  sortModel?: GridSortModel;
  pagination?: boolean;
  hideFooter?: boolean;
  onRowsPerPageChange?: (rowsPerPage: number | string) => void;
  onPageChange?: (pageNumber: number) => void;
  onSortModelChange?: (newSortModel: GridSortModel) => void;
  addNewRow?: () => void;
  styleClasses?: IClasses;
  getRow?: (row: any) => string;
};

const CustomDataGrid: FC<Props> = ({
  columns,
  data,
  pageSize,
  totalNumberOfRecords,
  sortModel,
  pageNumber,
  onRowsPerPageChange,
  onPageChange,
  onSortModelChange,
  getRow,
  pagination = true,
  hideFooter = false,
  styleClasses,
}: Props) => {
  const classes = useStyles();

  const handleRowsPerPageChange = event => {
    onRowsPerPageChange(event.target.value);
  };

  const CustomPagination = () => {
    return (
      <>
        <Pagination
          count={Math.ceil(totalNumberOfRecords / pageSize)}
          page={pageNumber}
          color="primary"
          onChange={(event, value) => onPageChange(value)}
          showFirstButton
          showLastButton
        />
        <TablePagination
          component="div"
          count={totalNumberOfRecords}
          page={pageNumber - 1}
          rowsPerPage={pageSize}
          onPageChange={(event, value) => null}
          rowsPerPageOptions={[10, 25, 50]}
          onRowsPerPageChange={handleRowsPerPageChange}
          nextIconButtonProps={{ style: { display: 'none' } }}
          backIconButtonProps={{ style: { display: 'none' } }}
          style={{ display: 'flex' }}
        />
      </>
    );
  };

  return (
    <Box
      className={classNames({
        [classes.mainContainer]: true,
        [styleClasses?.gridControl]: styleClasses?.gridControl,
      })}
    >
      <DataGrid
        editMode="row"
        disableColumnMenu
        columns={columns}
        rows={data}
        sortingOrder={['asc', 'desc']}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        getRowId={data => data.id}
        pageSize={pageSize}
        className={classes.gridContainer}
        hideFooter={hideFooter}
        hideFooterSelectedRowCount={true}
        disableVirtualization
        getRowClassName={params => (getRow ? getRow(params.row) : '')}
        components={{ Pagination: pagination ? CustomPagination : () => <></> }}
      />
    </Box>
  );
};

export default observer(CustomDataGrid);
