import React, { FC, useEffect } from 'react';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Typography, Button, FormControlLabel, Checkbox } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  CustomDataGrid,
  CustomDialog,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  useNotification,
  UserSearch,
  CustomDropDown,
  Actions,
} from '@shared';
import { UIStore, ProviderStore } from '@stores';
import { CommonService, ProviderService } from '@services';
import { OptionsModel, ProviderModel, ProviderPaginationModel } from '@models';
import { useStyles } from './Provider.styles';
import { UserFields } from './UserFields';
import { CrossIcon, EditIcon } from '@assets';

type Props = {
  providerStore?: ProviderStore;
};

const form: MobxReactForm = getFormValidation(UserFields, null);

const Provider: FC<Props> = ({ providerStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const observable = useLocalObservable(() => ({
    provider: new ProviderModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    isArchived: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    totalNumberOfRecords: 0,
    sortModel: [],
    agencyList: [],
  }));
  const columns = [
    { field: 'name', headerName: 'Provider Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'firstName', headerName: 'First Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lastName', headerName: 'Last Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'agencyName', headerName: 'Agency', sortable: false, flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'isAsqProfile',
      headerName: 'ASQ Profile',
      flex: 1,
      sortable: false,
      type: 'boolean',
      headerClassName: 'super-app-theme--header',
      cellClassName: params => (params.value ? classes.activeAsqProfile : classes.inActiveAsqProfile),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    getProviderList();
    getAgencyOptions();
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isEdit = false;
      observable.isDelete = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getProviderList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getProviderList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
    });
    getProviderList();
  };

  const getAgencyOptions = () => {
    UIStore.showLoader();
    CommonService.getAgencyOptions().subscribe({
      next: (response: OptionsModel[]) => {
        runInAction(() => {
          observable.agencyList = response;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const getProviderList = () => {
    const { pageNumber, pageSize, sortModel, query, isArchived } = observable;
    UIStore.showLoader();
    ProviderService.getAllProvider(pageNumber, pageSize, sortModel, query, isArchived).subscribe({
      next: (response: ProviderPaginationModel) => {
        providerStore.setProviderList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    if (observable.isEdit) {
      editProvider();
      return;
    }
    addProvider();
  };

  const addProvider = () => {
    const { name, firstName, lastName, email, agencyId, isAsqProfile, isArchived } = form.values();
    const request = { name, email, firstName, lastName, agencyId, isAsqProfile, isArchived };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ProviderService.addProvider(request).subscribe({
          next: (response: number) => {
            handleClose();
            getProviderList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const editProvider = () => {
    const { name, firstName, lastName, email, agencyId, isAsqProfile, isArchived } = form.values();
    const { providerGuid } = observable.provider;
    const request = { name, email, firstName, lastName, agencyId, isAsqProfile, isArchived, providerGuid };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ProviderService.updateProvider(request).subscribe({
          next: response => {
            handleClose();
            getProviderList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const onUserAction = (provider: ProviderModel, isEdit = false) => {
    runInAction(() => {
      observable.provider = provider;
      if (isEdit) {
        const { name, firstName, lastName, email, agencyId, isAsqProfile, isArchived } = observable.provider;
        form.$('name').set(name);
        form.$('firstName').set(firstName);
        form.$('lastName').set(lastName);
        form.$('email').set(email);
        form.$('agencyId').set(agencyId);
        form.$('isAsqProfile').set(isAsqProfile);
        form.$('isArchived').set(isArchived);
        observable.isEdit = isEdit;
        observable.isOpen = true;
        return;
      }
      observable.isDelete = true;
    });
  };

  const deleteProvider = () => {
    UIStore.showLoader();
    ProviderService.deleteProvider(observable.provider?.providerGuid).subscribe({
      next: (response: boolean) => {
        handleClose();
        getProviderList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onArchived = (isArchived: boolean) => {
    runInAction(() => {
      observable.isArchived = isArchived;
      observable.pageNumber = 1;
    });
    getProviderList();
  };

  const onSearch = (searchText: string) => {
    runInAction(() => {
      observable.query = searchText;
      observable.pageNumber = 1;
    });
    getProviderList();
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
    });
    getProviderList();
  };

  const renderActions = params => {
    return (
      <>
        <div style={{ width: 70 }}>
          <Actions btnText="Edit" onClick={() => onUserAction(params.row, true)} imgSrc={EditIcon} />
        </div>
        <div style={{ width: 80 }}>
          {(!params.row.isMasterProvider || !params.row.isAssignedToMasterAgency) && (
            <Actions btnText="Delete" onClick={() => onUserAction(params.row)} imgSrc={CrossIcon} />
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Provider List
      </Typography>
      <UserSearch searchPlaceholder="Enter name or email to search provider" onSearch={onSearch} onClear={onClear} />
      <Box component="div" display="flex" flexDirection="column">
        <div className={classes.buttonWrapper}>
          <FormControlLabel
            control={<Checkbox checked={observable.isArchived} onChange={e => onArchived(e.target.checked)} />}
            label={<Typography>Archive</Typography>}
          />
          <Button
            onClick={() =>
              runInAction(() => {
                observable.isOpen = true;
              })
            }
          >
            <AddCircleIcon className={classes.icon} />
            <Typography variant="body1" className={classes.text}>
              ADD PROVIDER
            </Typography>
          </Button>
        </div>
        <CustomDataGrid
          columns={columns}
          data={providerStore.providerList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
        />
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit Provider' : 'Add Provider'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('name')}
            showLabel={true}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'name')}
            form={form}
          />
          <FormInputControl
            field={form.$('email')}
            showLabel={true}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'email')}
            form={form}
          />
          <FormInputControl
            field={form.$('firstName')}
            showLabel={true}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'firstName')}
            form={form}
          />
          <FormInputControl
            field={form.$('lastName')}
            showLabel={true}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'lastName')}
            form={form}
          />
          <CustomDropDown
            value={form.$('agencyId').value}
            field={form.$('agencyId')}
            options={observable.agencyList}
            className={classes.selectBox}
            placeholder={form.$('agencyId').label}
            onValueChange={value => handleChange(value, 'agencyId')}
            form={form}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.$('isAsqProfile').value}
                  onChange={e => handleChange(e.target.checked, 'isAsqProfile')}
                />
              }
              label={<Typography className={classes.checkBoxLabel}>{form.$('isAsqProfile').label}</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.$('isArchived').value}
                  onChange={e => handleChange(e.target.checked, 'isArchived')}
                />
              }
              label={<Typography className={classes.checkBoxLabel}>{form.$('isArchived').label}</Typography>}
            />
          </div>
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this provider?"
          handleClose={handleClose}
          handleDelete={deleteProvider}
        />
      )}
    </div>
  );
};

export default inject('providerStore')(observer(Provider));
