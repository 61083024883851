import { action, makeObservable, observable } from 'mobx';
import { ECOModel, SiteModel, UserModel } from '../Models';

class ECOStore {
  public ecoList: ECOModel[] = [];
  public eco: ECOModel = new ECOModel();
  public ecoUsers: UserModel[] = [];
  public sites: SiteModel[] = [];

  constructor() {
    makeObservable(this, {
      ecoList: observable,
      eco: observable,
      ecoUsers: observable,
      sites: observable,
      setECOList: action,
      setECO: action,
      setECOUsers: action,
      setSites: action,
      clear: action,
    });
  }

  public setECOList(ecos: ECOModel[]): void {
    this.ecoList = ecos;
  }

  public setECOUsers(ecoUsers: UserModel[]): void {
    this.ecoUsers = ecoUsers;
  }

  public setECO(eco: ECOModel): void {
    this.eco = eco;
  }

  public setSites(sites: SiteModel[]): void {
    this.sites = sites;
  }

  public clear(): void {
    this.ecoList = [];
    this.ecoUsers = [];
    this.sites = [];
    this.eco = new ECOModel();
  }
}

export default ECOStore;
