import { action, makeObservable, observable } from 'mobx';
import { AgencyModel } from '../Models';

class AgencyStore {
  public agencyList: AgencyModel[] = [];

  constructor() {
    makeObservable(this, {
      agencyList: observable,
      setAgencyList: action,
      clear: action,
    });
  }

  public setAgencyList(agencies: AgencyModel[]): void {
    this.agencyList = agencies;
  }

  public clear(): void {
    this.agencyList = [];
  }
}

export default AgencyStore;
