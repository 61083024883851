import { FC, ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import { FormControlLabel, FormHelperText, FormLabel, Typography, Checkbox } from '@mui/material';
import { IClasses } from '@interfaces';
import { SurveyQuestionsModel } from '@models';

interface Props {
  className?: string;
  defaultValue?: number[];
  showLabel?: boolean;
  width?: number;
  options: SurveyQuestionsModel;
  styleClasses?: IClasses;
  error?: boolean;
  onValueChange: (value: any, checked?: boolean) => void;
}
const CustomCheckbox: FC<Props> = (props: Props) => {
  const { defaultValue, options, className, error, onValueChange } = props;
  const [value, setValue] = useState(defaultValue);
  const onChange = (checked: boolean, optionId: number) => {
    onValueChange(optionId, checked);
    if (value?.includes(optionId)) {
      setValue(value?.filter(id => id !== optionId));
      return;
    }
    setValue([...value, optionId]);
  };

  const editableContent = (): ReactNode => {
    return (
      <div className={className}>
        <FormLabel>
          {options.isRequired && <Typography sx={{ mr: '5px', color: 'red' }}>*</Typography>}
          <Typography>{`${options?.orderIndex}. ${options?.title}`}</Typography>
        </FormLabel>
        {options?.surveyQuestionOptions?.map(option => (
          <FormControlLabel
            key={option.questionOptionId}
            control={
              <Checkbox
                onChange={e => onChange(e.target.checked, option.questionOptionId)}
                checked={value?.includes(option.questionOptionId)}
              />
            }
            label={option.optionText}
            sx={{ paddingLeft: '8px' }}
          />
        ))}
        {error && <FormHelperText>This question is required.</FormHelperText>}
      </div>
    );
  };

  return <div>{editableContent()}</div>;
};

export default observer(CustomCheckbox);
