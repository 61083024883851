import { IChildAndTeachers } from '../Interfaces';
import { OptionsModel } from './Options.model';

export class ChildTeachersModel {
  childrenId: number;
  childName: string;
  teachers: OptionsModel[];

  constructor(data?: Partial<ChildTeachersModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IChildAndTeachers): ChildTeachersModel {
    if (!apiData) {
      return new ChildTeachersModel();
    }

    const data: Partial<ChildTeachersModel> = {
      childrenId: apiData.ChildrenId,
      childName: apiData.ChildName,
      teachers: OptionsModel.deserializeList(apiData.Teachers),
    };

    return new ChildTeachersModel(data);
  }

  static deserializeList(childTeachersList: IChildAndTeachers[]): ChildTeachersModel[] {
    return childTeachersList
      ? childTeachersList.map(
          (apiData: IChildAndTeachers) => new ChildTeachersModel(ChildTeachersModel.deserialize(apiData))
        )
      : [];
  }
}
