import { FC, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { Box, Drawer, List, Typography, Divider, ListItem, ListItemButton, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ChildSurveyStore, CommonStore, UIStore } from '@stores';
import { ChildSurveyService } from '@services';
import { ChildProgressModel } from '@models';
import { useNotification } from '../NotificationProvider/NotificationProvider';
import { sideBareOptions } from './SideBarOptions';
import { SWYCBehaviour, SWYCDevelopment, Reports, Referrals } from './index';
import { useStyles } from './ChildProgress.styles';

type Props = {
  childSurveyStore?: ChildSurveyStore;
  commonStore?: CommonStore;
};

const ChildProgress: FC<Props> = ({ childSurveyStore, commonStore }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const notification = useNotification();
  const location = useLocation();
  const { progress } = childSurveyStore;
  const slash = location.pathname.indexOf('/', 1);
  const path = location.pathname.substring(0, slash);
  const observable = useLocalObservable(() => ({
    optionId: 1,
  }));

  useEffect(() => {
    getChildProgress();
  }, []);

  const getChildProgress = () => {
    UIStore.showLoader();
    ChildSurveyService.getChildProgress(Number(params?.id)).subscribe({
      next: (response: ChildProgressModel) => {
        childSurveyStore.setProgress(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold' }}>
          {`${progress?.childFirstName} ${progress?.childLastName}'s Progress`}
        </Typography>
        <div className={classes.btnContainer}>
          <ArrowBackIcon className={classes.backBtn} />
          <Button className={classes.backBtn} onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </div>
      </Box>
      <Box sx={{ display: 'flex', height: 'calc(100% - 95px)', marginTop: '30px' }}>
        <Drawer className={classes.sideBar} variant="permanent" anchor="left">
          <Divider />
          <ListItem className={classes.title}>Quick Navigation</ListItem>
          <List>
            {sideBareOptions.map((option, id) => (
              <div key={option.id}>
                <Link to={'#' + String(option.id)} className={classes.navOptions} reloadDocument>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        backgroundColor: observable.optionId === option.id ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                      }}
                      onClick={() =>
                        runInAction(() => {
                          observable.optionId = option.id;
                        })
                      }
                    >
                      {option.label}
                    </ListItemButton>
                  </ListItem>
                </Link>
                <Divider style={{ color: '#80808061' }} />
              </div>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }} className={classes.sideBox}>
          <div id="1">
            <Typography className={classes.title}>SWYC Development</Typography>
            <SWYCDevelopment progress={progress.swycDevelopmentProgress} />
          </div>
          <div id="2">
            <Typography className={classes.title}>SWYC Behaviour</Typography>
            <SWYCBehaviour progress={progress.swycBehaviourProgress} />
          </div>
          <div id="3">
            <Typography className={classes.title}>Reports</Typography>
            <Reports reports={progress?.childSurveyAssessmentReports} path={path} />
          </div>
          <div id="4">
            <Typography className={classes.title}>Referrals</Typography>
            <Referrals referrals={progress?.referrals} />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default inject('childSurveyStore', 'commonStore')(observer(ChildProgress));
