import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflowY: 'hidden',
    background: theme.palette.common.white,
  },
  mainContainer: {
    padding: '10px 30px',
    marginTop: '5rem',
    position: 'relative',
    height: 'calc(100% - 80px)',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 65px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 65px)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '15px 10px',
      height: 'calc(100% - 65px)',
    },
  },
  header: {
    background: theme.palette.background.default,
    color: theme.palette.common.black,
    borderBottom: '1px solid #d3d3d3',
    boxShadow: 'none',
  },
}));
