import React, { FC } from 'react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { useLocation, useNavigate } from 'react-router';
import { observer, useLocalObservable } from 'mobx-react';
import { AxiosError } from 'axios';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { Logo } from '@assets';
import { CustomDatePicker, FormInputControl, LocalStorageService, getFormValidation, useNotification,Utils } from '@shared';
import { UIStore, AuthStore } from '@stores';
import { useStyles } from './RegisterFamily.styles';
import { Fields } from './Fields';
import { FamilyService } from '@services';

const form: MobxReactForm = getFormValidation(Fields, null);

const RegisterFamily: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const notification = useNotification();  
  const location = useLocation();
  const slash = location.pathname.indexOf('/', 1);
  const path = location.pathname.substring(0, slash);

  const observable = useLocalObservable(() => ({
    placeholder: '',
  }));
  
  const getField = (key: string): Field => {
    return form.$(key);
  };
 

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  
  const onRegisterFamily = () => {
    UIStore.showLoader();
    FamilyService.registerFamily(form.values()).subscribe({
      next: (response: string) => {
        UIStore.hideLoader();

        navigate(`/registrationthankyou`, { replace: true });        
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  return (
    <div className={classes.mainWrapper}>
      <Card className={classes.container} elevation={4}>
        <div style={{ textAlign: 'center' }}>
          <img src={Logo} className={classes.image} alt="logo" />
        </div>
        <form style={{border:'1px'}}>
          <Box component="div" display="flex" flexDirection="column">            
            <FormInputControl
              showLabel={true}
              field={form.$('caregiverFirstName')}
              onValueChange={value => handleChange(value, 'caregiverFirstName')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              //value={observable.token}
            />
            <FormInputControl
              showLabel={true}
              field={form.$('caregiverLastName')}
              onValueChange={value => handleChange(value, 'caregiverLastName')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              //value={observable.token}
            />
            <FormInputControl
              showLabel={true}
              field={form.$('caregiverEmail')}
              onValueChange={value => handleChange(value, 'caregiverEmail')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              //value={observable.token}
            />
            <FormInputControl
              showLabel={true}
              field={form.$('caregiverPhone')}
              onValueChange={value => handleChange(value, 'caregiverPhone')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              //value={observable.token}
            />           
          </Box>
          <Box component="div" display="flex" flexDirection="row">
          <Box component="div" style={{margin:'36px 16px 4px'}}>Child 1</Box>            
          <FormInputControl
              showLabel={false}
              field={form.$('child1FirstName')}
              onValueChange={value => handleChange(value, 'child1FirstName')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              //value={observable.token}
            />
            <FormInputControl
            showLabel={false}
            field={form.$('child1LastName')}
            onValueChange={value => handleChange(value, 'child1LastName')}
            form={form}
            styleClasses={{ inputControl: classes.input }}
            //value={observable.token}
          />
          <CustomDatePicker
            field={form.$('child1BirthDate')}
            onValueChange={value => handleChange(value, 'child1BirthDate')}
            value={form.$('child1BirthDate').value}
            showLabel={false}
            isBirthDate={true}
            endDate={Utils.formattedDate(String(new Date()), 'MM/DD/YYYY')}
            className={classes.dateInput}
            form={form}
          />          
            <FormInputControl
            showLabel={false}
            type="number"
            field={form.$('child1WeeksPremature')}
            onValueChange={value => handleChange(value, 'child1WeeksPremature')}
            form={form}
            styleClasses={{ inputControl: classes.input }}
            //value={observable.token}
          />
          </Box>
          <Box component="div" display="flex" flexDirection="row">
          <Box component="div" style={{margin:'36px 16px 4px'}}>Child 2</Box>            
          <FormInputControl
              showLabel={false}
              field={form.$('child2FirstName')}
              onValueChange={value => handleChange(value, 'child2FirstName')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              //value={observable.token}
            />
            <FormInputControl
            showLabel={false}
            field={form.$('child2LastName')}
            onValueChange={value => handleChange(value, 'child2LastName')}
            form={form}
            styleClasses={{ inputControl: classes.input }}
            //value={observable.token}
          />
          <CustomDatePicker
            field={form.$('child2BirthDate')}
            onValueChange={value => handleChange(value, 'child2BirthDate')}
            value={form.$('child2BirthDate').value}
            showLabel={false}
            isBirthDate={true}
            endDate={Utils.formattedDate(String(new Date()), 'MM/DD/YYYY')}
            className={classes.dateInput}
            form={form}
          />          
            <FormInputControl
            showLabel={false}
            type="number"
            field={form.$('child2WeeksPremature')}
            onValueChange={value => handleChange(value, 'child2WeeksPremature')}
            form={form}
            styleClasses={{ inputControl: classes.input }}
            //value={observable.token}
          />
          </Box>
          <Box component="div" display="flex" flexDirection="row">
          <Box component="div" style={{margin:'36px 16px 4px'}}>Child 3</Box>            
          <FormInputControl
              showLabel={false}
              field={form.$('child3FirstName')}
              onValueChange={value => handleChange(value, 'child3FirstName')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              //value={observable.token}
            />
            <FormInputControl
            showLabel={false}
            field={form.$('child3LastName')}
            onValueChange={value => handleChange(value, 'child3LastName')}
            form={form}
            styleClasses={{ inputControl: classes.input }}
            //value={observable.token}
          />
          <CustomDatePicker
            field={form.$('child3BirthDate')}
            onValueChange={value => handleChange(value, 'child3BirthDate')}
            value={form.$('child3BirthDate').value}
            showLabel={false}
            isBirthDate={true}
            endDate={Utils.formattedDate(String(new Date()), 'MM/DD/YYYY')}
            className={classes.dateInput}
            form={form}
          />          
            <FormInputControl
            showLabel={false}
            type="number"
            field={form.$('child3WeeksPremature')}
            onValueChange={value => handleChange(value, 'child3WeeksPremature')}
            form={form}
            styleClasses={{ inputControl: classes.input }}
            //value={observable.token}
          />
          </Box>
          <Box component="div" display="flex" flexDirection="row">
          <Box component="div" style={{margin:'36px 16px 4px'}}>Child 4</Box>            
          <FormInputControl
              showLabel={false}
              field={form.$('child4FirstName')}
              onValueChange={value => handleChange(value, 'child4FirstName')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              //value={observable.token}
            />
            <FormInputControl
            showLabel={false}
            field={form.$('child4LastName')}
            onValueChange={value => handleChange(value, 'child4LastName')}
            form={form}
            styleClasses={{ inputControl: classes.input }}
            //value={observable.token}
          />
          <CustomDatePicker
            field={form.$('child4BirthDate')}
            onValueChange={value => handleChange(value, 'child4BirthDate')}
            value={form.$('child4BirthDate').value}
            showLabel={false}
            isBirthDate={true}
            endDate={Utils.formattedDate(String(new Date()), 'MM/DD/YYYY')}
            className={classes.dateInput}
            form={form}
          />          
            <FormInputControl
            showLabel={false}
            type="number"
            field={form.$('child4WeeksPremature')}
            onValueChange={value => handleChange(value, 'child4WeeksPremature')}
            form={form}
            styleClasses={{ inputControl: classes.input }}
            //value={observable.token}
          />
          </Box>
          <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={onRegisterFamily}
              disabled={UIStore.pageLoading}
            >
              {UIStore.pageLoading ? (
                <CircularProgress className={classes.progress} style={{ width: 30, height: 30 }} />
              ) : (
                <Typography>Register</Typography>
              )}
            </Button>
        </form>
      </Card>
    </div>
  );
};

export default observer(RegisterFamily);
