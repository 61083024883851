import React, { FC } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

type Props = {
    message: string;
    severity?: AlertColor;
    handleClose: () => void;
};

const CustomAlert: FC<Props> = (props: Props) => {
    const { message, severity = 'error', handleClose } = props;
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={true}
            autoHideDuration={8000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} elevation={4} severity={severity} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomAlert;
