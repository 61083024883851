import { ICyclePagination } from '../Interfaces';
import { CycleModel } from './Cycle.model';
import { PageModel } from './Page.model';

export class CyclePaginationModel extends PageModel {
  results: CycleModel[];

  constructor(data?: Partial<CyclePaginationModel>) {
    super();
    Object.assign(this, data);
  }

  static deserialize(apiData: ICyclePagination): CyclePaginationModel {
    if (!apiData) {
      return new CyclePaginationModel();
    }

    const data: Partial<CyclePaginationModel> = {
      pageNumber: apiData.PageNumber,
      pageSize: apiData.PageSize,
      totalNumberOfRecords: apiData.TotalNumberOfRecords,
      totalPages: apiData.TotalPages,
      results: CycleModel.deserializeList(apiData.Results),
    };

    return new CyclePaginationModel(data);
  }
}
