import FamilyStore from './Family.store';
import AgencyStore from './Agency.store';
import ECOStore from './ECO.store';
import ProviderStore from './Provider.store';
import UserStore from './User.store';
import ChildrenStore from './Children.store';
import ProfileStore from './Profile.store';
import SiteStore from './Site.store';
import ChildSurveyStore from './ChildSurvey.store';
import TeacherStore from './Teacher.store';
import CommonStore from './Common.store';
import CycleStore from './Cycle.store';

class RootStore {
  public userStore: UserStore;
  public agencyStore: AgencyStore;
  public providerStore: ProviderStore;
  public ecoStore: ECOStore;
  public familyStore: FamilyStore;
  public childrenStore: ChildrenStore;
  public profileStore: ProfileStore;
  public siteStore: SiteStore;
  public childSurveyStore: ChildSurveyStore;
  public teacherStore: TeacherStore;
  public commonStore: CommonStore;
  public cycleStore: CycleStore;

  constructor() {
    this.userStore = new UserStore();
    this.agencyStore = new AgencyStore();
    this.providerStore = new ProviderStore();
    this.ecoStore = new ECOStore();
    this.familyStore = new FamilyStore();
    this.childrenStore = new ChildrenStore();
    this.profileStore = new ProfileStore();
    this.siteStore = new SiteStore();
    this.childSurveyStore = new ChildSurveyStore();
    this.teacherStore = new TeacherStore();
    this.commonStore = new CommonStore();
    this.cycleStore = new CycleStore();
  }

  public clear() {
    this.userStore.clear();
    this.agencyStore.clear();
    this.providerStore.clear();
    this.ecoStore.clear();
    this.familyStore.clear();
    this.childrenStore.clear();
    this.profileStore.clear();
    this.siteStore.clear();
    this.childSurveyStore.clear();
    this.teacherStore.clear();
    this.commonStore.clear();
    this.cycleStore.clear();
  }
}

export default new RootStore();
