import { IChildrenPagination } from '../Interfaces';
import { ChildrenModel } from './Children.model';
import { PageModel } from './Page.model';

export class ChildrenPaginationModel extends PageModel {
    results: ChildrenModel[];

    constructor(data?: Partial<ChildrenPaginationModel>) {
        super();
        Object.assign(this, data);
    }

    static deserialize(apiData: IChildrenPagination): ChildrenPaginationModel {
        if (!apiData) {
            return new ChildrenPaginationModel();
        }

        const data: Partial<ChildrenPaginationModel> = {
            pageNumber: apiData.PageNumber,
            pageSize: apiData.PageSize,
            totalNumberOfRecords: apiData.TotalNumberOfRecords,
            totalPages: apiData.TotalPages,
            results: ChildrenModel.deserializeList(apiData.Results),
        };

        return new ChildrenPaginationModel(data);
    }
}
