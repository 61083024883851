import { FC } from 'react';
import { AxiosError } from 'axios';
import MobxReactForm, { Field } from 'mobx-react-form';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { Typography, Box, Button } from '@mui/material';
import { AuthStore, ProfileStore, UIStore } from '@stores';
import { runInAction } from 'mobx';
import CustomDialog from '../CustomDialog/CustomDialog';
import { getFormValidation } from '../FormValidation/FormValidation';
import { changePasswordFields } from './fields';
import FormInputControl from '../FormInputControl/FormInputControl';
import { useNotification } from '../NotificationProvider/NotificationProvider';
import { useStyles } from './MyProfile.styles';

type Props = {
  profileStore?: ProfileStore;
};

const form: MobxReactForm = getFormValidation(changePasswordFields, null);
const MyProfile: FC<Props> = ({ profileStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const { profile } = profileStore;
  const observable = useLocalObservable(() => ({
    isOpen: false,
  }));

  const handleClose = (): void => {
    runInAction(() => {
      observable.isOpen = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const onSubmit = () => {
    form.submit({
      onSuccess: () => {
        const { oldPassword, newPassword } = form.values();
        UIStore.showLoader();
        AuthStore.changePassword(oldPassword, newPassword).subscribe({
          next: (response: string) => {
            UIStore.hideLoader();
            handleClose();
            notification.success(response);
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  return (
    <>
      <Box className={classes.container}>
        <Box margin="auto">
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography className={classes.title}>First Name :</Typography>
            <Typography sx={{ fontSize: '1.2rem', marginLeft: '20px' }}>{profile?.firstName}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography className={classes.title}>Last Name :</Typography>
            <Typography sx={{ fontSize: '1.2rem', marginLeft: '20px' }}>{profile?.lastName}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '45px' }}>
            <Typography className={classes.title}>Email :</Typography>
            <Typography sx={{ fontSize: '1.2rem', marginLeft: '22px' }}>{profile?.email}</Typography>
          </Box>
          <Box marginLeft="20px">
            <Button
              variant="contained"
              className={classes.btn}
              onClick={() =>
                runInAction(() => {
                  observable.isOpen = true;
                })
              }
            >
              Change Password
            </Button>
          </Box>
          <CustomDialog open={observable.isOpen} title="Change Password" onClose={handleClose} onSubmit={onSubmit}>
            <FormInputControl
              type="password"
              field={form.$('oldPassword')}
              styleClasses={{ inputControl: classes.input }}
              onValueChange={value => handleChange(value, 'oldPassword')}
              showLabel={true}
              form={form}
            />
            <FormInputControl
              type="password"
              field={form.$('newPassword')}
              styleClasses={{ inputControl: classes.input }}
              onValueChange={value => handleChange(value, 'newPassword')}
              showLabel={true}
              form={form}
            />
            <FormInputControl
              type="password"
              field={form.$('confirmPassword')}
              styleClasses={{ inputControl: classes.input }}
              onValueChange={value => handleChange(value, 'confirmPassword')}
              showLabel={true}
              form={form}
            />
          </CustomDialog>
        </Box>
      </Box>
    </>
  );
};

export default inject('profileStore')(observer(MyProfile));
