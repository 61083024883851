import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    margin: '16px 0 4px',
    '& .MuiFormHelperText-root': {
      marginTop: '-15px',
      marginBottom: '12px',
      marginLeft: 0,
    },
    '& .MuiInputBase-root': {
      marginTop: '9px',
      marginBottom: '20px',
    },
    '& .MuiInputLabel-root': {
      width: '100%',
      top: '-27px',
      transform: 'none',
      maxWidth: '100%',
      pointerEvents: 'all',
      color: theme.palette.grey[500],
      fontWeight: 600,
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px 10px',
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: '5px 8px 8px',
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: '7px 1px 10px',
    },
    '& MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
  container: {
    width: '75%',
    minHeight: '50%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: 'auto',
    },
  },
  image: {
    width: 150,
    height: 150,
  },
  inputIcon: {
    '& svg': {
      color: theme.palette.text.primary,
    },
  },
  btn: {
    borderRadius: 5,
    fontSize: '16px',
    textTransform: 'inherit',
    background: theme.palette.primary.main,
    width: '100%',
    height: 45,
    margin: '8px 0 25px',
    '&:hover': {
      background: theme.palette.primary.dark,
      boxShadow: 'none',
    },
    '&:disabled': {
      background: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  progress: {
    color: theme.palette.common.white,
  },
  dateInput: {
    width: '100%',
    margin: '16px 0 4px',
    '& .MuiFormHelperText-root': {
      marginTop: '-15px',
      marginBottom: '12px',
      marginLeft: 0,
    },    
    '& .MuiInputBase-root': {
      marginTop: '10px',
      marginBottom: '18px',      
    },
    '& .MuiInputLabel-root': {
      width: '100%',
      top: '-27px',
      transform: 'none',
      maxWidth: '100%',
      pointerEvents: 'all',
      color: theme.palette.grey[500],
      fontWeight: 600,
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px 10px',
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: '5px 8px 8px',
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: '7px 1px 10px',
    },
    '& MuiFormHelperText-root': {
      marginLeft: 0,
    },
  }
}));
