import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  innerContainer: {
    padding: "41px 41px 20px",
    margin: "auto"
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2rem"
  },
  cancelBtn: {
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.black}`,
    marginRight: "20px",
    width: 150,
  },
  selectBox: {
    '& .MuiFormLabel-root': {
      fontWeight: 600,
      fontSize: '16px',
      color: theme.palette.grey[500],
      top: '-9px',
    },
    '&.MuiInputBase-root ': {
      marginTop: '3px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
  },
  input: {
    width: '100%',
    margin: '16px 0 4px',
    '& .MuiFormHelperText-root': {
      marginTop: '-15px',
      marginBottom: '12px',
      marginLeft: 0,
    },
    '& .MuiInputBase-root': {
      marginTop: '9px',
      marginBottom: '20px'
    },
    '& .MuiInputLabel-root': {
      width: '100%',
      top: '-27px',
      transform: 'none',
      maxWidth: '100%',
      pointerEvents: 'all',
      color: theme.palette.grey[500],
      fontWeight: 600,
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px 10px',
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: '5px 8px 8px',
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: '7px 1px 10px',
    },
    '& MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
}));
