import React, { FC, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { inject, observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router';
import { Box, Toolbar, Typography, Button } from '@mui/material';
import { Logo, BCCSFLogo } from '@assets';
import { AuthStore, ProfileStore, RootStore, UIStore } from '@stores';
import { UserService } from '@services';
import { ProfileModel } from '@models';
import UserDropdown from './UserDropdown/UserDropdown';
import SideNavBar from './SideNavBar/SideNavBar';
import {
  AdminOptions,
  AgencyOptions,
  EcoAdminOptions,
  EcoOptions,
  FamilyOptions,
  ProviderOptions,
  SiteOptions,
} from './HeaderOptions';
import { useStyles } from './Header.styles';
import AccountMenu from './AccountMenu/AccountMenu';

type Props = {
  profileStore?: ProfileStore;
};

const Header: FC<Props> = ({ profileStore }: Props) => {
  const [openRightPanel, setOpenRightPanel] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const slash = location.pathname.indexOf('/', 1);
  const titleLocation = location.pathname.substring(0, slash);

  useEffect(() => {
    userProfile();
  }, []);

  const userProfile = () => {
    UIStore.showLoader();
    UserService.userProfile().subscribe({
      next: (response: ProfileModel) => {
        profileStore.setProfile(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const getHeaderOptions = () => {
    if (AuthStore.isFamily) {
      return FamilyOptions;
    }
    if (AuthStore.isEcoAdmin) {
      return EcoAdminOptions;
    }
    if (AuthStore.isCoach || AuthStore.isCoordinator) {
      return EcoOptions;
    }
    if (AuthStore.isAgencyAdmin) {
      return AgencyOptions;
    }
    if (AuthStore.isProvider) {
      return ProviderOptions;
    }
    if (AuthStore.isSiteAdmin) {
      return SiteOptions;
    }
    return AdminOptions;
  };

  const handleOpenRightPanel = () => {
    setOpenRightPanel(true);
  };

  const onLogout = () => {
    AuthStore.clear();
    RootStore.clear();
    navigate('/auth/login');
  };

  const renderHeaderTitles = () => {
    const options = getHeaderOptions();
    return options.map((option, index) => (
      <div key={index}>
        {option.isDropdown ? (
          <UserDropdown
            title={option.name}
            options={option.dropdownList}
            className={`${
              titleLocation === option.navigate &&
              location.pathname !== '/admin/reporting-dashboard' &&
              location.pathname !== '/admin/about'
                ? classes.activeLink
                : classes.userButton
            }`}
          />
        ) : (
          <Button
            onClick={() => window.location.toString().endsWith(option.navigate) ? window.location.reload() : navigate(option.navigate)}
            size="small"
            className={`${location.pathname === option.navigate ? classes.activeLink : classes.userButton}`}
          >
            <Typography variant="body1" noWrap component="div" sx={{ fontWeight: 'bold' }}>
              {option.name}
            </Typography>
          </Button>
        )}
      </div>
    ));
  };

  return (
    <Box className={classes.mainHeader}>
      <Toolbar className={classes.toolBar}>
        <Typography variant="h6" noWrap component="div" sx={{ display: { sm: 'block' } }}>
          <img src={Logo} alt="logo" className={classes.headerLogo} />          
        </Typography>
        <Box sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center' }}>
          <>{renderHeaderTitles()}</>
          {/* <Button onClick={null} size="small" className={classes.userButton}>
            <Typography variant="body1" noWrap component="div" sx={{ fontWeight: 'bold' }}>
              Contact
            </Typography>
          </Button> */}
          <Button
            onClick={() => navigate(`${titleLocation}/about`)}
            size="small"
            className={`${location.pathname === `${titleLocation}/about` ? classes.activeLink : classes.userButton}`}
          >
            <Typography variant="body1" noWrap component="div" sx={{ fontWeight: 'bold' }}>
              About
            </Typography>
          </Button>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center' }}>
          {/* <IconButton
            size="medium"
            aria-label="show new notifications"
            color="inherit"
            className={classes.notification}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            <Badge color="error" badgeContent={2}>
              <Notifications />
            </Badge>
          </IconButton> */}
          <AccountMenu name={profileStore?.profile?.fullName} onLogout={onLogout} />
        </Box>
        {/* <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: { xs: 0, sm: 2 }, display: { xs: 'block', lg: 'none' } }}
          onClick={handleOpenRightPanel}
        >
          <MenuIcon />
        </IconButton> */}
      </Toolbar>
      <SideNavBar openRightPanel={openRightPanel} setOpenRightPanel={setOpenRightPanel} />
    </Box>
  );
};

export default inject('profileStore')(observer(Header));
