import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    btn:{
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent'
          },
    }
}));
