import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  sideBar: {
    flexShrink: 0,
    '& .MuiPaper-root': {
      border: '1px solid #80808061',
      position: 'relative',
      marginRight: '30px',
      height: '100%',
    },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
    },
  },
  sideBox: {
    border: '1px solid #80808061',
    padding: '0px',
    overflowY: 'auto',
    height: '100%',
    '& > .MuiBox-root': {
      height: 'calc(100% - 65px)',
      overflowY: 'auto',
    },
  },
  title: {
    backgroundColor: ' #80808061',
    padding: '20px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
  },
  navOptions: {
    textDecoration: 'none',
    color: '#000000d1',
    fontWeight: 600,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backBtn: {
    color: '#0000FF',
    fontWeight: 600,
    fontSize: '18px',
  },
}));
