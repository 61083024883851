import { map, Observable } from 'rxjs';
import { OptionsModel } from '../Models';
import { IAPIResponse, IOptions } from '../Interfaces';
import HttpClient from '../Tools/HttpClient';
import { ApiUrl } from './APIUrls';

class CommonService {
  public getAgencyOptions(): Observable<OptionsModel[]> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IOptions[]>>(ApiUrl.agencyOptions)
      .pipe(map((response: IAPIResponse<IOptions[]>) => OptionsModel.deserializeList(response.Data)));
  }

  public getProviderOptions(): Observable<OptionsModel[]> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IOptions[]>>(ApiUrl.providerOptions)
      .pipe(map((response: IAPIResponse<IOptions[]>) => OptionsModel.deserializeList(response.Data)));
  }

  public getFamilyOptions(): Observable<OptionsModel[]> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IOptions[]>>(ApiUrl.familyOptions)
      .pipe(map((response: IAPIResponse<IOptions[]>) => OptionsModel.deserializeList(response.Data)));
  }

  public getSiteOptions(): Observable<OptionsModel[]> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IOptions[]>>(ApiUrl.siteOptions)
      .pipe(map((response: IAPIResponse<IOptions[]>) => OptionsModel.deserializeList(response.Data)));
  }

  public getExternalProviders(): Observable<OptionsModel[]> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IOptions[]>>(ApiUrl.externalProviders)
      .pipe(map((response: IAPIResponse<IOptions[]>) => OptionsModel.deserializeList(response.Data)));
  }
}

export default new CommonService();
