import { map, Observable } from 'rxjs';
import { GridSortModel } from '@mui/x-data-grid';
import { AgencyPaginationModel } from '../Models';
import { IAgencyPagination, IAPIResponse } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { Sort } from '../Enums';

class AgencyService {

    public getAllAgency(
        pageNumber: number,
        pageSize: number,
        sortModel: GridSortModel,
        query: string
    ): Observable<AgencyPaginationModel> {
        const http: HttpClient = new HttpClient();
        const params = Utils.buildParamString({
            PageNumber: pageNumber,
            PageSize: pageSize,
            Sort: sortModel[0]?.field || 'name',
            Dir: Sort[sortModel[0]?.sort] || Sort.asc,
            Query: query,
        })
        return http
            .get<IAPIResponse<IAgencyPagination>>(`${ApiUrl.agencies}?${params}`)
            .pipe(map((response: IAPIResponse<IAgencyPagination>) => AgencyPaginationModel.deserialize(response.Data)));
    }

    public addAgency(req): Observable<number> {
        const http: HttpClient = new HttpClient();
        return http
            .post<IAPIResponse<number>>(ApiUrl.agencies, req)
            .pipe(map((response: IAPIResponse<number>) => (response.Data)));
    }

    public deleteAgency(id: string): Observable<boolean> {
        const http: HttpClient = new HttpClient();
        return http
            .delete<IAPIResponse<boolean>>(`${ApiUrl.agencies}/${id}`)
            .pipe(map((response: IAPIResponse<boolean>) => response.Data));
    };

    public updateAgency(req): Observable<boolean> {
        const http: HttpClient = new HttpClient();
        return http
            .put<IAPIResponse<boolean>>(ApiUrl.agencies, req)
            .pipe(map((response: IAPIResponse<boolean>) => (response.Data)));
    }
}

export default new AgencyService();
