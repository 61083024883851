import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import {
  AddECO,
  AddFamily,
  Agency,
  Dashboard,
  ECO,
  Provider,
  Family,
  Site,
  AddSite,
  Teacher,
  Children,
  ReportingDashboard,
} from './Components';
import { About, ChildProgress, ChildReport, MyProfile } from '@shared';
import { ChildSurvey } from '../Caregiver/Components';
import { ThanksPage } from '../Public';
import {EcoCycles, EcoSurvey} from './Components';
const Admin: FC = () => {
  return (
    <Routes>
      <Route path="/users" element={<Dashboard />} />
      <Route path="/agencies" element={<Agency />} />
      <Route path="/providers" element={<Provider />} />
      <Route path="/ecos" element={<ECO />} />
      <Route path="/add-eco" element={<AddECO />} />
      <Route path="/ecos/:id" element={<AddECO />} />
      <Route path="/families" element={<Family />} />
      <Route path="/add-family" element={<AddFamily />} />
      <Route path="/families/:id" element={<AddFamily />} />
      <Route path="/sites" element={<Site />} />
      <Route path="/add-site" element={<AddSite />} />
      <Route path="/sites/:id" element={<AddSite />} />
      <Route path="/teachers" element={<Teacher />} />
      <Route path="/children" element={<Children />} />
      <Route path="/child-progress/:id" element={<ChildProgress />} />
      <Route path="/child-report/:id" element={<ChildReport />} />
      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/reporting-dashboard" element={<ReportingDashboard />} />
      <Route path="/about" element={<About />} />
      <Route path="/child-survey/:id" element={<ChildSurvey />} />
      <Route path="/thanks" element={<ThanksPage />} />
      <Route path="/cycles/:mappingId" element={<EcoCycles />} />
      <Route path="/send-survey" element={<EcoSurvey />} />
      <Route path="/*" element={<Navigate to="users" />} />
    </Routes>
  );
};

export default Admin;
