export enum UserRole {
    SUPER_ADMIN = 'Admin',
    ECO_ADMIN = 'Eco Admin',
    FAMILY = 'Family',
    COACH = 'Coach',
    COORDINATOR = 'Coordinator',
    AGENCY_ADMIN = 'Agency Admin',
    SITE_ADMIN = 'Site Admin',
    PROVIDER= 'Provider',
}
