import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    width: '100%',
    height: 'calc(100vh - 338px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 300px)',
    },
    [theme.breakpoints.down('sm' || 'xs')]: {
      height: 'calc(100vh - 200px)',
    },
  },
  gridContainer: {
    '& .super-app-theme--header': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'flex-start',
      },
    },
    '& .MuiDataGrid-cell': {
      justifyContent: 'flex-start',
    },
    '& .super-app-theme--lightGreen': {
      backgroundColor: theme.palette.success[100],
      '&:hover': {
        backgroundColor: theme.palette.success[100],
      },
    },
    '& .super-app-theme--lightBlue': {
      backgroundColor: theme.palette.primary[100],
      '&:hover': {
        backgroundColor: theme.palette.primary[100],
      },
    },
    '& .super-app-theme--lightYellow': {
      backgroundColor: theme.palette.warning[100],
      '&:hover': {
        backgroundColor: theme.palette.warning[100],
      },
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: 'rgba(242, 242, 242, 1)',
      '& > div': {
        display: 'none',
      },
      '& .Mui-selected': {
        backgroundColor: theme.palette.primary[100],
      },
    },
  },
}));
