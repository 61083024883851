import React, { FC, Fragment, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { runInAction } from 'mobx';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { Box, Drawer, List, Typography, Divider, ListItem, ListItemButton, Button } from '@mui/material';
import { AddCircle as AddCircleIcon, Margin } from '@mui/icons-material';
import { AuthStore, ChildSurveyStore, CommonStore, UIStore } from '@stores';
import { ChildReportModel, ChildTipSheetsModel, NotesModel, OptionsModel } from '@models';
import { ChildSurveyService, CommonService } from '@services';
import {
  CustomDialog,
  CustomDropDown,
  FormInputControl,
  MultiSelect,
  ParentEntityType,
  Utils,
  useNotification,
} from '@shared';
import QuestionsResponse from './QuestionsResponse/QuestionsResponse';
import { Fields, ReferralFields, TipSheetsFields } from './Fields';
import { getFormValidation } from '../FormValidation/FormValidation';
import { useStyles } from './ChildReport.styles';

type Props = {
  childSurveyStore?: ChildSurveyStore;
  commonStore?: CommonStore;
};

const form: MobxReactForm = getFormValidation(Fields, null);
const tipSheetsForm: MobxReactForm = getFormValidation(TipSheetsFields, null);
const referralform: MobxReactForm = getFormValidation(ReferralFields, null);

const ChildReport: FC<Props> = ({ childSurveyStore, commonStore }: Props) => {
  const classes = useStyles();
  const params = useParams();
  const notification = useNotification();
  const location = useLocation();
  const slash = location.pathname.indexOf('/', 1);
  const path = location.pathname.substring(0, slash);
  const navigate = useNavigate();
  const { report } = childSurveyStore;
  const { associatedTipSheets } = report;
  const observable = useLocalObservable(() => ({
    orderIndex: 0,
    sectionId: 0,
    isOpen: false,
    isModalOpen: false,
    isReferModalOpened: false,
    tipSheets: new ChildTipSheetsModel(),
    canSeeScores: true
  }));

  useEffect(() => {
    getReport();
    getExternalProviders();
    setExistingTipSheets();
    return () => {
      childSurveyStore.clear();
      commonStore.clear();
    };
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const isCanSeeScores = ()=>{return observable.canSeeScores;}

  const toggleScoring =()=>{observable.canSeeScores = !observable.canSeeScores;}

  const handleChange = (value: any, field: string, form?: string): void => {
    getField(field).set(value);
  };

  const getTipSheetsFormField = (key: string): Field => {
    return tipSheetsForm.$(key);
  };

  const handleTipSheetsFormChange = (value: any | any[], field: string): Field => {
    getTipSheetsFormField(field).set(value);
  };

  const getReferralField = (key: string): Field => {
    return referralform.$(key);
  };

  const handleReferralChange = (value: any, field: string, form?: string): void => {
    getReferralField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isModalOpen = false;
      observable.isReferModalOpened = false;
      observable.sectionId = 0;
      form.$submitted = 0;
      tipSheetsForm.$submitted = 0;
      referralform.$submitted = 0;
    });
    form.reset();
    tipSheetsForm.reset();
    referralform.reset();
  };

  const getLastIndex = (): number => {
    return associatedTipSheets?.behaviourTipSheets?.length - 1;
  };

  const getReport = () => {
    UIStore.showLoader();
    ChildSurveyService.getChildReport(params?.id).subscribe({
      next: (response: ChildReportModel) => {
        const reportSection = response?.reportSections[0];
        childSurveyStore.setReport(response);
        //tipSheetsForm.values = response.associatedTipSheets;
        runInAction(() => {
          observable.orderIndex = reportSection.orderIndex;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const getExternalProviders = () => {
    UIStore.showLoader();
    CommonService.getExternalProviders().subscribe({
      next: (response: OptionsModel[]) => {
        commonStore.setExternalProviders(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const openModal = (sectionId: number) => {
    runInAction(() => {
      observable.sectionId = sectionId;
      observable.isOpen = true;
    });
  };

  const getTipSheets = () => {
    UIStore.showLoader();
    ChildSurveyService.getTipSheets().subscribe({
      next: (response: ChildTipSheetsModel) => {
        runInAction(() => {          
          observable.tipSheets = response;
          debugger;          
          if(report.associatedTipSheets!=null){
          tipSheetsForm.$('developmentTipSheetId').set(response.developmentTipSheets.filter(f=>f.label===report.associatedTipSheets.developmentTipSheet.label)[0].value??'');          
          tipSheetsForm.$('behaviourTipSheets').set(response.behaviourTipSheets.filter(f=>report.associatedTipSheets.behaviourTipSheets.filter(m=>m.label===f.label).length>0));
          }
          observable.isModalOpen = true;
          
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    const { note } = form.values();
    const request = { childrenSurveyId: report?.childrenSurveyId, sectionId: observable.sectionId, noteText: note };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildSurveyService.addNote(request).subscribe({
          next: (response: NotesModel) => {
            const sectionIndex = report?.reportSections?.findIndex(x => x.surveySectionId === response.sectionId);
            report.reportSections[sectionIndex].notes = [...report?.reportSections[sectionIndex]?.notes, response];
            const updatedReport = new ChildReportModel(report);
            childSurveyStore.setReport(updatedReport);
            UIStore.hideLoader();
            handleClose();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const onTipSheetsSubmit = () => {
    const { developmentTipSheetId, behaviourTipSheets } = tipSheetsForm.values();
    const request = {
      childrenSurveyId: report?.childrenSurveyId,
      developmentTipSheetId,
      behaviourTipSheetIds: behaviourTipSheets?.map(x => x.value),
    };    
    tipSheetsForm.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildSurveyService.insertTipSheets(request).subscribe({
          next: (response: boolean) => {
            handleClose();
            UIStore.hideLoader();
            getReport();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const retakeSurvey = () => {
    UIStore.showLoader();
    ChildSurveyService.retakeChildSurvey(params?.id).subscribe({
      next: (response: boolean) => {
        UIStore.hideLoader();
        navigate(`/admin/child-survey/${params?.id}`, { replace: true });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const printSurvey = () => {
    navigate(`/printablesurvey/${params?.id}`, { replace: false });
  };

  const onReferChild = () => {
    referralform.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildSurveyService.referChild({ ...referralform.values(), childId: report?.childId }).subscribe({
          next: (response: boolean) => {
            UIStore.hideLoader();
            handleClose();
            notification.success('Thank you. This referral has been added.');
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const setExistingTipSheets = ()=>{
    return;
    tipSheetsForm.$('associatedTipSheets').set(report.associatedTipSheets);    
  };


  const renderHeader = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
      <Box component="div" display="flex" flexDirection="column">
        <Typography variant="body1" fontWeight="bold" sx={{ fontSize: '18px' }}>
          {`SWYC ${report?.surveyName}`}
        </Typography>
        <Typography>
          <span className={classes.heading}>Family Profile: </span>
          {report?.parentEntityFirstName + ' ' + report?.parentEntityLastName}
        </Typography>
        <Typography>
          <span className={classes.heading}>Child: </span>
          {report?.childFirstName + ' ' + report?.childLastName}
        </Typography>
        <Typography>
          <span className={classes.heading}>Birth Date: </span>
          {Utils.formattedDate(report?.childBirthDate, 'MM/DD/YYYY')}
        </Typography>
        <Typography>
          <span className={classes.heading}>Age In Months: </span>
          {report?.ageInMonths}
        </Typography>
        <span><Button onClick={toggleScoring} className={classes.btn} style={{margin:'0px',padding:'0px'}}>Toggle Scoring</Button></span>
      </Box>
      {report?.associatedTipSheets && (
        <Box component="div" className={classes.tipSheetsSection}>
          <Typography variant="body1" fontWeight="bold" textAlign="center">
            Tip Sheets
          </Typography>
          <Box component="div">
            <Typography>
              <span className={classes.heading}>Developmental: </span>
              <Link
                to={`/Assets/DevelopmentalTipSheets/${associatedTipSheets?.developmentTipSheet?.fileName}`}
                target="_blank"
                className={classes.link}
              >
                {associatedTipSheets?.developmentTipSheet?.label}
              </Link>
            </Typography>
            {Boolean(associatedTipSheets?.behaviourTipSheets?.length) && (
              <Typography>
                <span className={classes.heading}>Behavioral: </span>
                {associatedTipSheets?.behaviourTipSheets?.map((behaviourTipSheet, index) => (
                  <Link
                    to={`/Assets/BehaviorTipSheets/${behaviourTipSheet?.fileName}`}
                    target="_blank"
                    className={classes.link}
                    key={behaviourTipSheet?.label}
                  >
                    {getLastIndex() === index ? `${behaviourTipSheet?.label}` : `${behaviourTipSheet?.label}, `}
                  </Link>
                ))}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <Box component="div">
        {(AuthStore.isAdmin || AuthStore.isProvider) &&
          report.parentEntityType === ParentEntityType.FAMILY && (
            <Button className={classes.btn} sx={{ width: '100px' }} onClick={getTipSheets}>
              {!report.isReviewed ? 'Review' : 'Tip Sheets'}
            </Button>
          )}
        {AuthStore.isAdmin && report.isRetakeAndEditAvialable && (
          <Button className={classes.btn} onClick={retakeSurvey}>
            Retake Survey
          </Button>
        )}
        {AuthStore.isAdmin && report.isRetakeAndEditAvialable && (
          <Button className={classes.btn} onClick={() => navigate(`/admin/child-survey/${params?.id}`)}>
            Edit Survey
          </Button>
        )}
        {(AuthStore.isAdmin || AuthStore.isProvider) && report.parentEntityType === ParentEntityType.FAMILY && (
          <Button
            className={classes.btn}
            sx={{ width: '100px' }}
            onClick={() =>
              runInAction(() => {
                observable.isReferModalOpened = true;
              })
            }
          >
            Refer
          </Button>
        )}
        <Button className={classes.btn} onClick={printSurvey}>
            Print Survey
        </Button>
      </Box>
    </Box>
  );

  const renderReport = () => (
    <Box className={classes.reportSection}>
      <Drawer className={classes.sideBar} variant="permanent" anchor="left">
        <Divider />
        <ListItem className={classes.title}>Quick Navigation</ListItem>
        <List>
          {report?.reportSections?.map(section => (
            <Fragment key={section.orderIndex}>
              <Link to={'#' + String(section.orderIndex)} className={classes.navOptions} reloadDocument>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{
                      backgroundColor:
                        observable.orderIndex === section.orderIndex ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                    }}
                    onClick={() =>
                      runInAction(() => {
                        observable.orderIndex = section.orderIndex;
                      })
                    }
                  >
                    {section?.sectionName}
                  </ListItemButton>
                </ListItem>
              </Link>
              <Divider style={{ color: '#80808061' }} />
            </Fragment>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }} className={classes.sideBox}>
        {report?.reportSections?.map(section => {
          return (
            <div key={section?.orderIndex}>
              <Box className={classes.mainHeading}>
                <Typography id={String(section?.orderIndex)}>{section?.sectionName}</Typography>
                {((path === '/eco' && AuthStore?.isEcoAdmin) || path === '/provider' || path === '/admin') && (
                  <Button className={classes.button} onClick={() => openModal(section?.surveySectionId)}>
                    <AddCircleIcon className={classes.icon} />
                    <Typography variant="body1" className={classes.text}>
                      Add Note
                    </Typography>
                  </Button>
                )}
              </Box>
              <QuestionsResponse sectionId={section.surveySectionId} canSeeScores={isCanSeeScores} />
            </div>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <>
      {renderHeader()}
      {renderReport()}
      <CustomDialog
        open={observable.isOpen}
        title="Add Note"
        onClose={handleClose}
        onSubmit={onSubmit}
        fullWidth={true}
      >
        <FormInputControl
          field={form.$('note')}
          styleClasses={{ inputControl: classes.input }}
          onValueChange={value => handleChange(value, 'note')}
          showLabel={true}
          multiline={true}
          form={form}
        />
      </CustomDialog>
      <CustomDialog
        open={observable.isModalOpen}
        title="Tip Sheets"
        buttonText="Submit"
        onClose={handleClose}
        onSubmit={onTipSheetsSubmit}
        fullWidth={true}
      >
        <Box component="div" mt={1}>
          <CustomDropDown
            value={tipSheetsForm.$('developmentTipSheetId').value}
            field={tipSheetsForm.$('developmentTipSheetId')}
            options={observable.tipSheets.developmentTipSheets}
            className={classes.selectBox}
            placeholder={tipSheetsForm.$('developmentTipSheetId').label}
            onValueChange={value => handleTipSheetsFormChange(value, 'developmentTipSheetId')}
            form={tipSheetsForm}
          />
          <MultiSelect
            label={tipSheetsForm.$('behaviourTipSheets').label}
            options={observable.tipSheets.behaviourTipSheets}
            onValueChange={value => handleTipSheetsFormChange(value, 'behaviourTipSheets')}
            placeholder={tipSheetsForm.$('behaviourTipSheets').placeholder}
            className={classes.selectBox}
            value={tipSheetsForm.$('behaviourTipSheets').value}
          />
        </Box>
      </CustomDialog>
      <CustomDialog
        open={observable.isReferModalOpened}
        title="Provider"
        buttonText="Refer Child"
        onClose={handleClose}
        onSubmit={onReferChild}
        fullWidth={true}
      >
        <Box component="div" mt={1}>
          <Typography variant="body1" fontWeight="bold" mb={2}>
            Select the provider you are referring this child to.
          </Typography>
          <CustomDropDown
            value={referralform.$('externalProviderId')?.value}
            field={referralform.$('externalProviderId')}
            options={commonStore.externalProviders}
            className={classes.selectBox}
            placeholder={referralform.$('externalProviderId').label}
            onValueChange={value => handleReferralChange(value, 'externalProviderId')}
            form={referralform}
          />
        </Box>
      </CustomDialog>
    </>
  );
};

export default inject('childSurveyStore', 'commonStore')(observer(ChildReport));
