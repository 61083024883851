import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TaskAlt as TaskAltIcon } from '@mui/icons-material';
import { Button, Card, Typography } from '@mui/material';
import { useStyles } from './RegistrationThankYou.styles';

type Props = {};

const RegistrationThankYou: FC<Props> = () => {
  const classes = useStyles();
  const location = useLocation();
  const slash = location.pathname.indexOf('/', 1);
  const path = location.pathname.substring(0, slash);

  
  return (
    <div className={classes.mainWrapper}>
      <Card component="div" className={classes.container} elevation={4}>
        <TaskAltIcon style={{ color: 'green', fontSize: 120 }} />
        <Typography variant="h4">Thank You!</Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for registering.  Please check your email to continue signing up.
        </Typography>        
      </Card>
    </div>
  );
};

export default RegistrationThankYou;
