export const HouseholdOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
]

export const CountyOptions = [    
    { value: 'Adair', label: 'Adair' },
    { value: 'Andrew', label: 'Andrew' },
    { value: 'Atchison', label: 'Atchison' },
    { value: 'Audrain', label: 'Audrain' },
    { value: 'Barry', label: 'Barry' },
    { value: 'Barton', label: 'Barton' },
    { value: 'Bates', label: 'Bates' },
    { value: 'Benton', label: 'Benton' },
    { value: 'Bollinger', label: 'Bollinger' },
    { value: 'Boone', label: 'Boone' },
    { value: 'Buchanan', label: 'Buchanan' },
    { value: 'Butler', label: 'Butler' },
    { value: 'Caldwell', label: 'Caldwell' },
    { value: 'Callaway', label: 'Callaway' },
    { value: 'Camden', label: 'Camden' },
    { value: 'Cape Girardeau', label: 'Cape Girardeau' },
    { value: 'Carroll', label: 'Carroll' },
    { value: 'Carter', label: 'Carter' },
    { value: 'Cass', label: 'Cass' },
    { value: 'Cedar', label: 'Cedar' },
    { value: 'Chariton', label: 'Chariton' },
    { value: 'Christian', label: 'Christian' },
    { value: 'Clark', label: 'Clark' },
    { value: 'Clay', label: 'Clay' },
    { value: 'Clinton', label: 'Clinton' },
    { value: 'Cole', label: 'Cole' },
    { value: 'Cooper', label: 'Cooper' },
    { value: 'Crawford', label: 'Crawford' },
    { value: 'Dade', label: 'Dade' },
    { value: 'Dallas', label: 'Dallas' },
    { value: 'Daviess', label: 'Daviess' },
    { value: 'DeKalb', label: 'DeKalb' },
    { value: 'Dent', label: 'Dent' },
    { value: 'Douglas', label: 'Douglas' },
    { value: 'Dunklin', label: 'Dunklin' },
    { value: 'Franklin', label: 'Franklin' },
    { value: 'Gasconade', label: 'Gasconade' },
    { value: 'Gentry', label: 'Gentry' },
    { value: 'Greene', label: 'Greene' },
    { value: 'Grundy', label: 'Grundy' },
    { value: 'Harrison', label: 'Harrison' },
    { value: 'Henry', label: 'Henry' },
    { value: 'Hickory', label: 'Hickory' },
    { value: 'Holt', label: 'Holt' },
    { value: 'Howard', label: 'Howard' },
    { value: 'Howell', label: 'Howell' },
    { value: 'Iron', label: 'Iron' },
    { value: 'Jackson', label: 'Jackson' },
    { value: 'Jasper', label: 'Jasper' },
    { value: 'Jefferson', label: 'Jefferson' },
    { value: 'Johnson', label: 'Johnson' },
    { value: 'Knox', label: 'Knox' },
    { value: 'Laclede', label: 'Laclede' },
    { value: 'Lafayette', label: 'Lafayette' },
    { value: 'Lawrence', label: 'Lawrence' },
    { value: 'Lewis', label: 'Lewis' },
    { value: 'Lincoln', label: 'Lincoln' },
    { value: 'Linn', label: 'Linn' },
    { value: 'Livingston', label: 'Livingston' },
    { value: 'Macon', label: 'Macon' },
    { value: 'Madison', label: 'Madison' },
    { value: 'Maries', label: 'Maries' },
    { value: 'Marion', label: 'Marion' },
    { value: 'McDonald', label: 'McDonald' },
    { value: 'Mercer', label: 'Mercer' },
    { value: 'Miller', label: 'Miller' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Moniteau', label: 'Moniteau' },
    { value: 'Monroe', label: 'Monroe' },
    { value: 'Montgomery', label: 'Montgomery' },
    { value: 'Morgan', label: 'Morgan' },
    { value: 'New Madrid', label: 'New Madrid' },
    { value: 'Newton', label: 'Newton' },
    { value: 'Nodaway', label: 'Nodaway' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Osage', label: 'Osage' },
    { value: 'Ozark', label: 'Ozark' },
    { value: 'Pemiscot', label: 'Pemiscot' },
    { value: 'Perry', label: 'Perry' },
    { value: 'Pettis', label: 'Pettis' },
    { value: 'Phelps', label: 'Phelps' },
    { value: 'Pike', label: 'Pike' },
    { value: 'Platte', label: 'Platte' },
    { value: 'Polk', label: 'Polk' },
    { value: 'Pulaski', label: 'Pulaski' },
    { value: 'Putnam', label: 'Putnam' },
    { value: 'Ralls', label: 'Ralls' },
    { value: 'Randolph', label: 'Randolph' },
    { value: 'Ray', label: 'Ray' },
    { value: 'Reynolds', label: 'Reynolds' },
    { value: 'Ripley', label: 'Ripley' },
    { value: 'Saint Charles', label: 'Saint Charles' },
    { value: 'Saint Clair', label: 'Saint Clair' },
    { value: 'Saint Francois', label: 'Saint Francois' },
    { value: 'Saint Louis', label: 'Saint Louis' },
    { value: 'Saint Louis City', label: 'Saint Louis City' },
    { value: 'Sainte Genevieve', label: 'Sainte Genevieve' },
    { value: 'Saline', label: 'Saline' },
    { value: 'Schuyler', label: 'Schuyler' },
    { value: 'Scotland', label: 'Scotland' },
    { value: 'Scott', label: 'Scott' },
    { value: 'Shannon', label: 'Shannon' },
    { value: 'Shelby', label: 'Shelby' },
    { value: 'Stoddard', label: 'Stoddard' },
    { value: 'Stone', label: 'Stone' },
    { value: 'Sullivan', label: 'Sullivan' },
    { value: 'Taney', label: 'Taney' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Vernon', label: 'Vernon' },
    { value: 'Warren', label: 'Warren' },
    { value: 'Washington', label: 'Washington' },
    { value: 'Wayne', label: 'Wayne' },
    { value: 'Webster', label: 'Webster' },
    { value: 'Worth', label: 'Worth' },
    { value: 'Wright', label: 'Wright' },
    { value: 'Other', label: 'Other' },
]

export const GenderOptions = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Other' },
]

export const HasHousholdIncomeOptions = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
]

export const EthnicityOptions = [
    { value: 1, label: 'Hispanic or Latino' },
    { value: 2, label: 'Not Hispanic or Latino' },
]

export const RaceOptions = [
    { value: 1, label: 'American Indian or Alaska Native' },
    { value: 2, label: 'Asian' },
    { value: 3, label: 'Black or African American' },    
    { value: 4, label: 'Native Hawaiian or Other Pacific Islander' },
    { value: 5, label: 'White' },
    { value: 6, label: 'Multiple Races' },
    { value: 7, label: 'Other' },
]

export const AgeGroupOptions = [
    { value: 1, label: '19 or Younger' },
    { value: 2, label: '20 or Older' },
]


export const IncomeCategories = [
  { members: 1, yearly: 24280, monthly: 2024 },
  { members: 2, yearly: 32920, monthly: 2744 },
  { members: 3, yearly: 41560, monthly: 3464 },
  { members: 4, yearly: 50200, monthly: 4184 },
  { members: 5, yearly: 58840, monthly: 4904 },
  { members: 6, yearly: 67480, monthly: 5624 },
  { members: 7, yearly: 76120, monthly: 6344 },
  { members: 8, yearly: 84760, monthly: 7064 },
  { members: 9, yearly: 93400, monthly: 7784 },
  { members: 10, yearly: 102040, monthly: 8504 },
  { members: 11, yearly: 110680, monthly: 9224 },
  { members: 12, yearly: 119320, monthly: 9944 },
]

export const ColumbiaResidentOptions = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
];