import { IOptions } from '../Interfaces';

export class OptionsModel {
  value: number;
  label: string;
  fileName?: string;
  email?: string;

  constructor(data?: Partial<OptionsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IOptions): OptionsModel {
    if (!apiData) {
      return new OptionsModel();
    }

    const data: Partial<OptionsModel> = {
      value: apiData.Id,
      label: apiData.Name,
      fileName: apiData.FileName,
      email: apiData.Email,
    };

    return new OptionsModel(data);
  }

  static deserializeList(options: IOptions[]): OptionsModel[] {
    return options ? options.map((apiData: IOptions) => new OptionsModel(OptionsModel.deserialize(apiData))) : [];
  }
}
