export const Fields = [
  {
    name: 'note',
    type: 'string',
    label: 'Note',
    rules: 'required|string',
    value: '',
  },
];

export const TipSheetsFields = [
  {
    name: 'developmentTipSheetId',
    placeholder: 'Select',
    label: 'Developmental Tip Sheets',
    rules: 'required',
    value: '',
  },
  {
    name: 'behaviourTipSheets',
    placeholder: 'Select',
    label: 'Behaviour Tip Sheets',
    value: [],
  },
];

export const ReferralFields = [
  {
    name: 'externalProviderId',
    placeholder: 'Select',
    label: 'Provider',
    rules: 'required',
    value: '',
  },
];
