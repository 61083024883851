import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Box, Typography } from '@mui/material';
import { NewHubAccountsDetailsModel } from '@models';

type Props = {
  progress: NewHubAccountsDetailsModel[];
};

const HubAccountDetails: FC<Props> = ({ progress }: Props) => {
  const data = {
    labels: progress?.map(x => x?.month),
    datasets: [
      {
        label: 'Family Count',
        data: progress?.map(x => x?.totalFamilyCount),
        backgroundColor: progress?.map(x => x?.color),
        borderColor: progress?.map(x => x?.borderColor),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box style={{ padding: '20px' }}>
      <Bar data={data} width="1362px" height="400px" />
      <Typography textAlign="center">Total New Hub Accounts</Typography>
    </Box>
  );
};

export default HubAccountDetails;
