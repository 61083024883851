import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { runInAction } from 'mobx';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { Box, Button, Typography } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import {
  Actions,
  CustomDataGrid,
  CustomDialog,
  CustomDropDown,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  useNotification,
  UserRole,
  UserType,
} from '@shared';
import { ECOService, UserService } from '@services';
import { ECOStore, UIStore } from '@stores';
import { UserModel } from '@models';
import { Fields, UserRoleOptions } from './StepTwoFields';
import { useStyles } from './StepTwo.styles';
import { CrossIcon, EditIcon } from '@assets';

type Props = {
  onNext: () => void;
  onBack: () => void;
  ecoStore?: ECOStore;
};

const form: MobxReactForm = getFormValidation(Fields, null);

const StepTwo: FC<Props> = ({ onNext, onBack, ecoStore }) => {
  const classes = useStyles();
  const notification = useNotification();
  const params = useParams();
  const observable = useLocalObservable(() => ({
    user: null,
    isOpen: false,
    isEdit: false,
    isDelete: false,
  }));
  const { eco, ecoUsers } = ecoStore;

  useEffect(() => {
    if (params?.id) {
      getEcoUsers();
    }
    return () => {
      clear();
    };
  }, []);

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'User Role',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => UserRole[params.value],
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => renderActions(params),
    },
  ];

  const getEcoUsers = () => {
    UIStore.showLoader();
    ECOService.ecoUsers(params?.id || eco?.guid).subscribe({
      next: (response: UserModel[]) => {
        ecoStore.setECOUsers(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const clear = () => {
    form.reset();
    ecoStore.setECOUsers([]);
  };

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isEdit = false;
      observable.isDelete = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const onUserAction = (user: UserModel, isEdit = false) => {
    runInAction(() => {
      observable.user = user;
      if (isEdit) {
        getEcoUserById(user.id);
        return;
      }
      observable.isDelete = true;
    });
  };

  const getEcoUserById = (ecoUserId: number) => {
    UIStore.showLoader();
    ECOService.getEcoUserById(ecoUserId).subscribe({
      next: (response: UserModel) => {
        const { firstName, lastName, email, phoneNumber, type } = response;
        form.$('firstName').set(firstName);
        form.$('lastName').set(lastName);
        form.$('email').set(email);
        form.$('phoneNumber').set(phoneNumber);
        form.$('type').set(UserType[type]);
        runInAction(() => {
          observable.user = response;
          observable.isEdit = true;
          observable.isOpen = true;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const addUser = () => {
    form.submit({
      onSuccess: () => {
        const { firstName, lastName, email, phoneNumber, type } = form.values();
        const req = { firstName, lastName, email, phoneNumber, type, ecoId: eco?.id };
        UIStore.showLoader();
        ECOService.addEcoUser(req).subscribe({
          next: (response: UserModel) => {
            runInAction(() => {
              const users = [...ecoUsers, response];
              ecoStore.setECOUsers(users);
            });
            handleClose();
            UIStore.hideLoader();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const editUser = () => {
    const { firstName, lastName, email, phoneNumber, type } = form.values();
    const { userId } = observable.user;
    form.submit({
      onSuccess: () => {
        const request = { firstName, lastName, email, phoneNumber, type, id: userId };
        UIStore.showLoader();
        UserService.updateUser(request).subscribe({
          next: (response: boolean) => {
            handleClose();
            getEcoUsers();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const deleteUser = () => {
    const { userId } = observable.user;
    UIStore.showLoader();
    ECOService.deleteEcoUser(eco?.id, userId).subscribe({
      next: (response: boolean) => {
        handleClose();
        getEcoUsers();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    if (observable.isEdit) {
      editUser();
      return;
    }
    addUser();
  };

  const renderActions = params => {
    return (
      <>
        <Actions btnText="Edit" onClick={() => onUserAction(params.row, true)} imgSrc={EditIcon} />
        <Actions btnText="Delete" onClick={() => onUserAction(params.row)} imgSrc={CrossIcon} />
      </>
    );
  };

  return (
    <div>      
      <Box component="div" display="flex" flexDirection="column">
        <Button className={classes.button} onClick={() => runInAction(() => (observable.isOpen = true))}>
          <AddCircleIcon className={classes.icon} />
          <Typography variant="body1" className={classes.text}>
            ADD USERS
          </Typography>
        </Button>
        <CustomDataGrid
          columns={columns}
          data={ecoUsers}
          pagination={false}
          styleClasses={{ gridControl: classes.grid }}
        />
        <div className={classes.btnContainer}>
          {params?.id && (
            <Button className={classes.goBackBtn} onClick={onBack}>
              Go Back
            </Button>
          )}
          <Button variant="contained" className={classes.saveBtn} onClick={onNext} disabled={!Boolean(ecoUsers.length)}>
            Save
          </Button>
        </div>
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit User' : 'Add User'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('firstName')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'firstName')}
            form={form}
          />
          <FormInputControl
            field={form.$('lastName')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'lastName')}
            form={form}
          />
          <FormInputControl
            field={form.$('email')}
            onValueChange={value => handleChange(value, 'email')}
            showLabel={true}
            styleClasses={{ inputControl: classes.input }}
            form={form}
          />
          <FormInputControl
            field={form.$('phoneNumber')}
            onValueChange={value => handleChange(value, 'phoneNumber')}
            showLabel={true}
            styleClasses={{ inputControl: classes.input }}
            form={form}
          />
          <CustomDropDown
            value={form.$('type').value}
            field={form.$('type')}
            options={UserRoleOptions}
            className={classes.selectBox}
            placeholder={form.$('type').label}
            onValueChange={value => handleChange(value, 'type')}
            form={form}
          />
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this user?"
          handleClose={handleClose}
          handleDelete={deleteUser}
        />
      )}
    </div>
  );
};

export default inject('ecoStore')(observer(StepTwo));
