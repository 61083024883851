import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainConatiner: {
    marginBottom: 8,
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: 5,
  },
  selectLabel: {
    '&.MuiInputLabel-root': {
      fontSize: '16px',
      fontWeight: 600,
      color: theme.palette.grey[500],
    },
  },
}));
