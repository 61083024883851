import React, { FC, Suspense, lazy } from 'react';
import { Outlet, Navigate } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { DialogProvider } from '@surinderlohat/react-dialog';
import { Authorization, GlobalStyles, Layout, NotificationProvider, PrivateRoute, theme } from '@shared';
import { AccessToken, ConfirmPassword, ForgotPassword, Login, RegistrationThankYou, Survey, ThanksPage } from '@modules';
import { AuthStore, RootStore } from '@stores';
import ChildSurvey from './Modules/Public/PublicSurvey/PublicSurvey';
import RegisterFamily from './Modules/Public/RegisterFamily/RegisterFamily';
import PrintableSurvey from './Modules/Public/PrintableSurvey/PrintableSurvey';

const Admin = lazy(() => import('./Modules/Admin/Admin'));
const Caregiver = lazy(() => import('./Modules/Caregiver/Caregiver'));
const ECO = lazy(() => import('./Modules/ECO/ECO'));
const Agency = lazy(() => import('./Modules/Agency/Agency'));
const Site = lazy(() => import('./Modules/Site/Site'));
const ProviderModule = lazy(() => import('./Modules/Provider/Provider'));

const getURL = (): string => {
  let url = '/';
  if (AuthStore.isAdmin) url = '/admin/users';
  if (AuthStore.isFamily) url = '/caregiver/*';
  if (AuthStore.isEcoAdmin || AuthStore.isCoach || AuthStore.isCoordinator) url = '/eco/users';
  if (AuthStore.isProvider) url = '/provider/users';
  if (AuthStore.isAgencyAdmin) url = '/agency/users';
  if (AuthStore.isSiteAdmin) url = '/site/teachers';
  return url;
};

// If user is Logged in then redirect to dashboard directly
const NavigateToDashboard = () => {
  return AuthStore.isAuthorized ? <Navigate to={getURL()} /> : <Outlet />;
};

// If user is Logged in then redirect to dashboard directly
const NavigateToLogin = () => {
  return AuthStore.isAuthorized ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/auth/login" />
  );
};

const App: FC = () => {
  return (
    <Provider {...RootStore}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <GlobalStyles />
          <DialogProvider>
            <NotificationProvider>
              <BrowserRouter>
                <Suspense fallback={<div>Loading...</div>}></Suspense>
                <Authorization>
                  <Routes>
                    <Route path="/*" element={<NavigateToLogin />}>
                      <Route path="" element={<Navigate to={getURL()} />} />
                      <Route
                        path="admin/*"
                        element={<PrivateRoute element={<Admin />} hasPermission={AuthStore.isAdmin} />}
                      />
                      <Route
                        path="caregiver/*"
                        element={<PrivateRoute element={<Caregiver />} hasPermission={AuthStore.isFamily} />}
                      />
                      <Route
                        path="eco/*"
                        element={
                          <PrivateRoute
                            element={<ECO />}
                            hasPermission={AuthStore.isEcoAdmin || AuthStore.isCoach || AuthStore.isCoordinator}
                          />
                        }
                      />
                      <Route
                        path="site/*"
                        element={<PrivateRoute element={<Site />} hasPermission={AuthStore.isSiteAdmin} />}
                      />
                      <Route
                        path="provider/*"
                        element={<PrivateRoute element={<ProviderModule />} hasPermission={AuthStore.isProvider} />}
                      />
                      <Route
                        path="agency/*"
                        element={<PrivateRoute element={<Agency />} hasPermission={AuthStore.isAgencyAdmin} />}
                      />
                    </Route>
                    <Route path="/auth/*" element={<NavigateToDashboard />}>
                      <Route path="login" element={<Login />} />
                      <Route path="forgot-password" element={<ForgotPassword />} />
                      <Route path="recovery/:token" element={<ConfirmPassword />} />
                    </Route>
                    <Route path="/survey/:id" element={<Survey />} />
                    <Route path="//survey/:id" element={<Survey />} />                    
                    <Route path="/thanks" element={<ThanksPage />} />
                    <Route path="/access-token" element={<AccessToken />} />
                    <Route path="/home/registerfamily" element={<RegisterFamily />} />
                    <Route path="/registrationthankyou" element={<RegistrationThankYou />} />
                    <Route path="/printablesurvey/:id" element={<PrintableSurvey />} />
                  </Routes>
                </Authorization>
              </BrowserRouter>
            </NotificationProvider>
          </DialogProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default observer(App);
