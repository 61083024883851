import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    emailLabel:{
        marginTop:'12px',
        color: '#393D42',
        fontSize: '16px',
        fontWeight: 600,
        marginBottom: '6px',
    },
    emailBox:{
        backgroundColor: `${theme.palette.primary[100]} !important` ,
        padding: '11px !important',
        borderRadius:'16px !important',
        fontSize:' 0.8125rem !important'
    }
}));
