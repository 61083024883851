import React, { useEffect } from 'react';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { Typography, Box, Stepper, Step, StepLabel } from '@mui/material';
import { ProfileStore, UIStore } from '@stores';
import ConsentForm from './ConsentForm/ConsentForm';
import DemographicsForm from './DemographicsForm/DemographicsForm';
import { useStyles } from './AccountConsent.styles';
import { ProfileModel } from '@models';
import { UserService } from '@services';
import { AxiosError } from 'axios';

const steps = ['Consent Form', 'Demographics'];

type Props = {
    profileStore?: ProfileStore;
  };

const AccountConsent = ({ profileStore }: Props) => {
    const classes = useStyles();
    const observable = useLocalObservable(() => ({
        activeStep: 0,
    }));

    useEffect(() => {
        userProfile();
      }, []);
    
      const userProfile = () => {
        UIStore.showLoader();
        UserService.userProfile().subscribe({
          next: (response: ProfileModel) => {
            profileStore.setProfile(response);
            UIStore.hideLoader();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
          },
        });
      };
    
    const handleNext = (step: number) => {
        runInAction(() => {
            observable.activeStep = step;
        });
    };

    const renderSteps = () => {
        if (observable.activeStep === 0) {
            return <ConsentForm onNext={() => handleNext(1)} caregiverName={profileStore.profile.firstName + ' ' + profileStore.profile.lastName} caregiverEmail={profileStore.profile.email} />;
        }
        if (observable.activeStep === 1) {
            return <DemographicsForm />;
        }
    };

    return (
        <>
            {!UIStore.pageLoading && <Box sx={{ width: '100%' }}>
                <Typography className={classes.heading} variant="h4">
                    Account Consent
                </Typography>
                <div>
                    <Stepper activeStep={observable.activeStep} className={classes.stepper}>
                        {steps.map((label) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <Box>{renderSteps()}</Box>
            </Box>}
        </>
    );
};

export default inject('profileStore') (observer(AccountConsent));
