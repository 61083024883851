import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { inject, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import MobxReactForm, { Field } from 'mobx-react-form';
import { Button, Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { FormInputControl, getFormValidation, useNotification } from '@shared';
import { UIStore, ECOStore } from '@stores';
import { ECOService } from '@services';
import { ECOModel } from '@models';
import { StepOneFields } from './StepOneFields';
import { useStyles } from './StepOne.styles';

type Props = {
  onNext?: () => void;
  onCancel?: () => void;
  ecoStore?: ECOStore;
};

const form: MobxReactForm = getFormValidation(StepOneFields, null);

const StepOne: FC<Props> = ({ ecoStore, onNext, onCancel }: Props) => {
  const classes = useStyles();
  const params = useParams();
  const notification = useNotification();

  useEffect(() => {
    if (params?.id) {
      getECOById();
    }
    return () => {
      clear();
    };
  }, []);

  const getECOById = () => {
    UIStore.showLoader();
    ECOService.getECOById(params?.id).subscribe({
      next: (response: ECOModel) => {
        form.$('name').set(response.name);
        ecoStore.setECO(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const clear = () => {
    form.reset();
    runInAction(() => {
      form.$submitted = 0;
    });
  };

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value, field: string) => {
    getField(field).set(value);
  };

  const addEco = req => {
    ECOService.addEco(req).subscribe({
      next: (response: ECOModel) => {
        ecoStore.setECO(response);
        UIStore.hideLoader();
        onNext();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const updateEco = req => {
    ECOService.updateEco(req).subscribe({
      next: response => {
        ecoStore.setECO({ ...ecoStore.eco, name: req.name });
        UIStore.hideLoader();
        onNext();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    const { name } = form.values();
    const req = { name };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        if (params?.id) {
          updateEco({ ...req, id: ecoStore.eco?.id });
          return;
        }
        addEco(req);
      },
      onError: error => {},
    });
  };

  return (
    <Box className={classes.innerContainer}>
      <Typography>
       Please enter the ECO Name and continue to the user setup by clicking 'Save & Move Next'
      </Typography>
      <Grid container spacing={3} marginTop="12px">
        <Grid xs={12} xsOffset={3} md={6}>
          <FormInputControl
            field={form.$('name')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'name')}
            form={form}
          />
          <Box className={classes.btnContainer}>
            {params?.id && (
              <Button className={classes.cancelBtn} onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button variant="contained" onClick={onSubmit}>
              {params?.id ? 'Update & Move Next' : 'Save & Move Next'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default inject('ecoStore')(observer(StepOne));
