import React, { FC, useEffect } from 'react';
import { AxiosError } from 'axios';
import { observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import MobxReactForm, { Field } from 'mobx-react-form';
import { Box, Typography } from '@mui/material';
import { CustomDropDown, ReportService, UIStore, getFormValidation, useNotification } from '@shared';
import { DashboardReportModel, OptionsModel } from '@models';
import { Fields } from './Fields';
import { useStyles } from './ReportingDashboard.styles';
import AssessmentDetails from './AssessmentDetails/AssessmentDetails';
import HubAccountDetails from './HubAccountDetails/HubAccountDetails';
import ResultsByDomain from './ResultsByDomain/ResultsByDomain';

type Props = {};

const form: MobxReactForm = getFormValidation(Fields, null);

const ReportingDashboard: FC<Props> = () => {
  const classes = useStyles();
  const notification = useNotification();
  const observable = useLocalObservable(() => ({
    years: [],
    dashboardReport: new DashboardReportModel(),
  }));

  useEffect(() => {
    getYears();
  }, []);

  const getYears = () => {
    UIStore.showLoader();
    ReportService.getYears().subscribe({
      next: (response: OptionsModel[]) => {
        runInAction(() => {
          observable.years = response;
          form.$('year').set(response[0].value);
        });
        getDashboardReport(response[0].label);
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const getDashboardReport = (year: string) => {
    UIStore.showLoader();
    ReportService.getDashboardReport(year).subscribe({
      next: (response: DashboardReportModel) => {
        UIStore.hideLoader();
        runInAction(() => {
          observable.dashboardReport = response;
        });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
    const year = observable.years?.find(year => year.value === value)?.label;
    getDashboardReport(year);
  };

  return (
    <Box component="div" display="flex" flexDirection="column" my={3}>
      <CustomDropDown
        value={form.$('year').value}
        options={observable.years}
        className={classes.selectBox}
        label="Year"
        onValueChange={value => handleChange(value, 'year')}
      />
      <Box component="div" my={3}>
        <Typography variant="h5">{`Total Assesments - (${observable.dashboardReport?.totalAssessments})`}</Typography>
        <Typography variant="h5">{`SWYC Assesments - (${observable.dashboardReport?.swycAssessments})`}</Typography>
        <Typography variant="h5">{`ASQ Assesments - (${observable.dashboardReport?.asqAssessments})`}</Typography>
        <Typography variant="h5">{`Non-Hub Assesments - (${observable.dashboardReport?.nonHubAssessments})`}</Typography>
      </Box>
      <Box component="div" mt={3}>
        <AssessmentDetails progress={observable.dashboardReport?.assessmentDetails} />
      </Box>
      <Box component="div" mt={3}>
        <HubAccountDetails progress={observable.dashboardReport?.newHubAccountsDetails} />
      </Box>
      <Box component="div" mt={3}>
        <ResultsByDomain progress={observable.dashboardReport?.resultsByDomain} />
      </Box>
    </Box>
  );
};

export default observer(ReportingDashboard);
