import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { UIStore } from '@stores';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

type Props = {
  open?: boolean;
  title?: string;
  buttonText?: string;
  children?: ReactNode;
  fullWidth?: boolean;
  height?: string;
  onClose?: () => void;
  onSubmit?: () => void;
};

const CustomDialog: FC<Props> = ({
  open,
  title,
  children,
  onClose,
  onSubmit,
  buttonText = '',
  fullWidth = false,
  height = 'auto',
}: Props) => {
  const onModalClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <BootstrapDialog
      onClose={onModalClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      sx={{
        '& .MuiPaper-root': {
          height: height,
        },
      }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={onSubmit} sx={{ minWidth: 100, maxWidth: 150 }}>
          {UIStore.pageLoading ? (
            <CircularProgress style={{ width: 25, height: 25 }} sx={{ color: theme => theme.palette.common.white }} />
          ) : (
            <Typography>{buttonText || 'Save'}</Typography>
          )}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default observer(CustomDialog);
