import React, { FC } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { InputLabel } from '@material-ui/core';
import { useStyles } from './MultiEmail.styles';

interface Props {
  emails?: string[];
  label?: string;
  isRemoved?: boolean;
  onChangeEmails?: (_emails: string[]) => void;
  placeholder?: string;
}

const MultiEmail: FC<Props> = ({ emails, onChangeEmails, placeholder, label, isRemoved = true }: Props) => {
  const classes = useStyles();

  return (
    <form>
      <InputLabel id="select-label" className={classes.emailLabel}>
        {label}
      </InputLabel>
      <ReactMultiEmail
        style={{ height: '100px', borderColor: '#BCBCBC', overflowY: 'auto' }}
        placeholder={placeholder}
        emails={emails}
        onDisabled={() => true}
        enable={() => (isRemoved ? true : false)}
        onChange={(_emails: string[]) => {
          onChangeEmails(_emails);
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag className={classes.emailBox} key={index}>
              <div data-tag-item>{email}</div>
              {isRemoved && (
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              )}
            </div>
          );
        }}
      />
    </form>
  );
};

export default MultiEmail;
