import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Typography, Button } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  Actions,
  CustomDataGrid,
  CustomDatePicker,
  CustomDialog,
  CustomDropDown,
  CycleStatus,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  SurveyCycleStatus,
  useNotification,
  UserSearch,
  Utils,
} from '@shared';
import { UIStore, AuthStore, CycleStore } from '@stores';
import { EcoSiteMappingService } from '@services';
import { CycleModel, CyclePaginationModel } from '@models';
import { useStyles } from './EcoCycles.styles';
import { Fields } from './Fields';
import { EditIcon } from '@assets';

type Props = {
  cycleStore?: CycleStore;
};

const form: MobxReactForm = getFormValidation(Fields, null);

const EcoCycles: FC<Props> = ({ cycleStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const params = useParams();
  const observable = useLocalObservable(() => ({
    cycle: new CycleModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    showEcos: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    totalNumberOfRecords: 0,
    sortModel: [],
  }));

  const coachColumns = [
    { field: 'name', headerName: 'Cycle Name', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => SurveyCycleStatus[params.value],
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },
    {
      field: 'completedStudents',
      headerName: 'Completed Students',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'totalStudents',
      headerName: 'Total Students',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columns = AuthStore.isEcoAdmin
    ? [
        ...coachColumns,
        {
          field: 'action',
          headerName: 'Action',
          flex: 1,
          headerClassName: 'super-app-theme--header',
          sortable: false,
          renderCell: params => renderActions(params),
        },
      ]
    : coachColumns;

  useEffect(() => {
    getCycleList();
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isDelete = false;
      observable.showEcos = false;
      observable.isEdit = false;
      observable.isOpen = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getCycleList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getCycleList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
    });
    getCycleList();
  };

  const getCycleList = () => {
    const { pageNumber, pageSize, sortModel, query } = observable;
    UIStore.showLoader();
    EcoSiteMappingService.getAllCycles(pageNumber, pageSize, sortModel, Number(params?.mappingId), query).subscribe({
      next: (response: CyclePaginationModel) => {
        cycleStore.setCycleList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSearch = (searchText: string) => {
    runInAction(() => {
      observable.query = searchText;
      observable.pageNumber = 1;
    });
    getCycleList();
  };

  const onAction = (cycle: CycleModel, isEdit = false) => {
    runInAction(() => {
      observable.cycle = cycle;
      if (!isEdit) {
        observable.isDelete = true;
        return;
      }
      getCycleById();
    });
  };

  const getCycleById = () => {
    UIStore.showLoader();
    EcoSiteMappingService.getCycleById(observable.cycle?.id).subscribe({
      next: (response: CycleModel) => {
        setFormValues(response);
        runInAction(() => {
          observable.isEdit = true;
          observable.isOpen = true;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const setFormValues = (cycle: CycleModel) => {
    form.$('name').set(cycle?.name);
    form.$('startDate').set(cycle?.startDate);
    form.$('endDate').set(cycle?.endDate);
    form.$('statusId').set(cycle?.statusId);
  };

  const deleteCycle = () => {
    UIStore.showLoader();
    EcoSiteMappingService.deleteCycle(observable.cycle?.id).subscribe({
      next: (response: boolean) => {
        handleClose();
        getCycleList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
    });
    getCycleList();
  };

  const addCycle = req => {
    EcoSiteMappingService.addCycle(req).subscribe({
      next: (response: boolean) => {
        handleClose();
        getCycleList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const updateCycle = req => {
    EcoSiteMappingService.updateCycle(req).subscribe({
      next: (response: boolean) => {
        handleClose();
        getCycleList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    const { name, startDate, endDate, statusId } = form.values();
    const req = {
      name,
      startDate: Utils.formattedDate(startDate, 'MM/DD/YYYY'),
      endDate: Utils.formattedDate(endDate, 'MM/DD/YYYY'),
      statusId,
      ecoSiteMappingId: Number(params?.mappingId),
    };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        if (observable.isEdit) {
          updateCycle({ ...req, id: observable.cycle?.id });
          return;
        }
        addCycle(req);
      },
      onError: error => {},
    });
  };

  const renderActions = (params) =>{
    return(
      <>
      <Actions btnText="Edit" onClick={() => onAction(params.row, true)} imgSrc={EditIcon} />
      </>
    )
  }

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Cycle List
      </Typography>
      <div style={{ marginBottom: 15 }}>
        <UserSearch onSearch={onSearch} onClear={onClear} />
      </div>
      <Box component="div" display="flex" flexDirection="column">
        {AuthStore.isEcoAdmin && (
          <Button
            className={classes.button}
            onClick={() =>
              runInAction(() => {
                observable.isOpen = true;
              })
            }
          >
            <AddCircleIcon className={classes.icon} />
            <Typography variant="body1" className={classes.text}>
              ADD CYCLE
            </Typography>
          </Button>
        )}
        <CustomDataGrid
          columns={columns}
          data={cycleStore.cycleList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
        />
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit Cycle' : 'Add Cycle'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('name')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'name')}
            showLabel={true}
            form={form}
          />
          <CustomDatePicker
            field={form.$('startDate')}
            onValueChange={value => handleChange(value, 'startDate')}
            value={form.$('startDate').value}
            endDate={form.$('endDate').value}
            showLabel={true}
            className={classes.dateInput}
            form={form}
          />
          <CustomDatePicker
            field={form.$('endDate')}
            onValueChange={value => handleChange(value, 'endDate')}
            value={form.$('endDate').value}
            startDate={form.$('startDate').value}
            showLabel={true}
            className={classes.dateInput}
            form={form}
          />
          <CustomDropDown
            value={form.$('statusId').value}
            field={form.$('statusId')}
            options={CycleStatus}
            className={classes.selectBox}
            placeholder={form.$('statusId').label}
            onValueChange={value => handleChange(value, 'statusId')}
            form={form}
          />
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this cycle?"
          handleClose={handleClose}
          handleDelete={deleteCycle}
        />
      )}
    </div>
  );
};

export default inject('cycleStore')(observer(EcoCycles));
