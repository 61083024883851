import { action, makeObservable, observable } from 'mobx';
import { UserModel } from '../Models';

class UserStore {
  public userList: UserModel[] = [];

  constructor() {
    makeObservable(this, {
      userList: observable,
      setUserList: action,
      clear: action,
    });
  }

  public setUserList(users: UserModel[]): void {
    this.userList = users;
  }

  public clear(): void {
    this.userList = [];
  }
}

export default UserStore;
