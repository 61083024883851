import { IAssessmentDetails, IDashboardReport, INewHubAccountsDetails, IResultsByDomain } from '../Interfaces';

export class DashboardReportModel {
  totalAssessments: number = 0;
  swycAssessments: number = 0;
  asqAssessments: number = 0;
  nonHubAssessments: number = 0;
  assessmentDetails: AssessmentDetailsModel[] = [];
  newHubAccountsDetails: NewHubAccountsDetailsModel[] = [];
  resultsByDomain: ResultsByDomainModel[] = [];

  constructor(data?: Partial<DashboardReportModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IDashboardReport): DashboardReportModel {
    if (!apiData) {
      return new DashboardReportModel();
    }

    const data: Partial<DashboardReportModel> = {
      totalAssessments: apiData.TotalAssessments,
      swycAssessments: apiData.SwycAssessments,
      asqAssessments: apiData.AsqAssessments,
      nonHubAssessments: apiData.NonHubAssessments,
      assessmentDetails: AssessmentDetailsModel.deserializeList(apiData.AssessmentDetails),
      newHubAccountsDetails: NewHubAccountsDetailsModel.deserializeList(apiData.NewHubAccountsDetails),
      resultsByDomain: ResultsByDomainModel.deserializeList(apiData.ResultsByDomain),
    };

    return new DashboardReportModel(data);
  }

  static deserializeList(reportList: IDashboardReport[]): DashboardReportModel[] {
    return reportList
      ? reportList.map(
          (apiData: IDashboardReport) => new DashboardReportModel(DashboardReportModel.deserialize(apiData))
        )
      : [];
  }
}

export class AssessmentDetailsModel {
  assessmentMonth: string;
  month: string;
  totalAssessmentCount: number;
  color: string;
  borderColor: string;

  constructor(data?: Partial<AssessmentDetailsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IAssessmentDetails): AssessmentDetailsModel {
    if (!apiData) {
      return new AssessmentDetailsModel();
    }

    const data: Partial<AssessmentDetailsModel> = {
      assessmentMonth: apiData.AssessmentMonth,
      month: apiData.Month,
      totalAssessmentCount: apiData.TotalAssessmentCount,
      color: apiData.Color,
      borderColor: apiData.BorderColor,
    };

    return new AssessmentDetailsModel(data);
  }

  static deserializeList(detailList: IAssessmentDetails[]): AssessmentDetailsModel[] {
    return detailList
      ? detailList.map(
          (apiData: IAssessmentDetails) => new AssessmentDetailsModel(AssessmentDetailsModel.deserialize(apiData))
        )
      : [];
  }
}

export class NewHubAccountsDetailsModel {
  createdOnMonth: string;
  month: string;
  totalFamilyCount: number;
  color: string;
  borderColor: string;

  constructor(data?: Partial<NewHubAccountsDetailsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: INewHubAccountsDetails): NewHubAccountsDetailsModel {
    if (!apiData) {
      return new NewHubAccountsDetailsModel();
    }

    const data: Partial<NewHubAccountsDetailsModel> = {
      createdOnMonth: apiData.CreatedOnMonth,
      month: apiData.Month,
      totalFamilyCount: apiData.TotalFamilyCount,
      color: apiData.Color,
      borderColor: apiData.BorderColor,
    };

    return new NewHubAccountsDetailsModel(data);
  }

  static deserializeList(accountList: INewHubAccountsDetails[]): NewHubAccountsDetailsModel[] {
    return accountList
      ? accountList.map(
          (apiData: INewHubAccountsDetails) =>
            new NewHubAccountsDetailsModel(NewHubAccountsDetailsModel.deserialize(apiData))
        )
      : [];
  }
}

export class ResultsByDomainModel {
  sectionId: number;
  sectionName: string;
  sectionAbbreviation: string;
  needsReview: number;
  meetsExpectations: number;
  needsReviewColor: string;
  meetsExpectationsColor: string;
  meetsExpectationsBorderColor: string;
  needsReviewBorderColor: string;

  constructor(data?: Partial<ResultsByDomainModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IResultsByDomain): ResultsByDomainModel {
    if (!apiData) {
      return new ResultsByDomainModel();
    }

    const data: Partial<ResultsByDomainModel> = {
      sectionId: apiData.SectionId,
      sectionName: apiData.SectionName,
      sectionAbbreviation: apiData.SectionAbbreviation,
      needsReview: apiData.NeedsReview,
      meetsExpectations: apiData.MeetsExpectations,
      needsReviewColor: apiData.NeedsReviewColor,
      meetsExpectationsColor: apiData.MeetsExpectationsColor,
      meetsExpectationsBorderColor: apiData.MeetsExpectationsBorderColor,
      needsReviewBorderColor: apiData.NeedsReviewBorderColor,
    };

    return new ResultsByDomainModel(data);
  }

  static deserializeList(resultList: IResultsByDomain[]): ResultsByDomainModel[] {
    return resultList
      ? resultList.map(
          (apiData: IResultsByDomain) => new ResultsByDomainModel(ResultsByDomainModel.deserialize(apiData))
        )
      : [];
  }
}
