import { action, makeObservable, observable } from 'mobx';
import { ProviderModel } from '../Models';

class ProviderStore {
  public providerList: ProviderModel[] = [];

  constructor() {
    makeObservable(this, {
      providerList: observable,
      setProviderList: action,
      clear: action,
    });
  }

  public setProviderList(providers: ProviderModel[]): void {
    this.providerList = providers;
  }

  public clear(): void {
    this.providerList = [];
  }
}

export default ProviderStore;
