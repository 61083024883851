import { FC } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { MobxReactForm, Field } from 'mobx-react-form';
import { getFormValidation } from '@shared';
import { useStyles } from '../AccountConsent.styles';
import { ConsentFormFields } from '../Fields';

type Props = {
  onNext: () => void;
  caregiverName?: string;
  caregiverEmail?: string;
};

const form: MobxReactForm = getFormValidation(ConsentFormFields);
const ConsentForm: FC<Props> = ({ onNext, caregiverName, caregiverEmail }) => {
  const classes = useStyles();
  const { information, becc } = form.values();

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: boolean, field: string) => {
    getField(field).set(value ? 'checked' : '');
  };

  const onSubmit = () => {
    form.submit({
      onSuccess: () => {
        form.reset();
        onNext();
      },
      onError: error => {},
    });
  };

  return (
    <>
      <Box className={classes.boxContainer}>
        <Typography>
          Welcome to the Boone County Early Childhood Coalition’s Survey of Well-Being of Young Children (SWYC&#8482;)
          Screening Hub. The information you supply through the surveys in this hub will help find your child’s
          strengths, possible areas of concern, and determine if there are community resources or services that may be
          useful for your child or family.
        </Typography>
        <Typography mt={2}>
          With your consent on this screen, you are opting to participate in the SWYC&#8482; Screening Hub. The Screening Hub
          is a way to organize children’s screening information and provide you with information on how your child is
          developing and resources to provide support when needed. Participating in the screening hub will remind you
          when it is time to do another check. The hub will be used to help increase regular screening within families
          and reduce any unnecessary screening. Project administrative staff and providers that you give permission to
          will have access to your family’s information. The SWYC&#8482; information will be kept confidential to the extent
          permissible under applicable laws. Any data submitted to the grant funding agency will be de-identified.
        </Typography>
        <Typography mt={2} fontWeight={600}>
          Please read the text below and check the box if you want your child’s SWYC&#8482; information to be included in
          the Boone County Early Childhood Coalition's screening hub. Please note: your child may still participate in
          the screening even if you do not choose to participate in the screening hub.
        </Typography>
        <Box mt={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={information ? true : false}
                onChange={e => handleChange(e.target.checked, 'information')}
              />
            }
            label="I give permission to have this information included in the screening hub and for an account to be made for my family"
          />
        </Box>
        <Box>
          <FormControlLabel
            control={<Checkbox checked={becc ? true : false} onChange={e => handleChange(e.target.checked, 'becc')} />}
            label="I give permission for my providers or BCECC project administration to contact me regarding the results of the surveys and my account, either in person, through email, or a provided phone number"
          />
        </Box>
        <Box>
          <Typography mt={2}>Caregiver name for hub account: {caregiverName}</Typography>
          <Typography mt={2}>Email for hub account: {caregiverEmail}</Typography>
          <Typography mt={2}>
            You may cancel your consent and remove your information from the hub at any point by contacting Cindy Reese
            by email at cindyreese@health.missouri.edu or by calling at (573)884-4891.
          </Typography>
        </Box>
        <div className={classes.btn}>
          <Button variant="contained" onClick={onSubmit}>
            I Agree & Proceed
          </Button>
        </div>
      </Box>
    </>
  );
};

export default inject('profileStore')(observer(ConsentForm));
