import React, { ChangeEvent, FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import MobxReactForm, { Field } from 'mobx-react-form';
import classNames from 'classnames';
import { TextField } from '@mui/material';
import { IClasses } from '@interfaces';
import { useStyles } from './FormInputControl.styles';

interface Props {
  form?: MobxReactForm;
  field?: Field;
  value?: any;
  showLabel?: boolean;
  isDisabled?: boolean;
  isAdornment?: boolean;
  endAdornment?: any;
  startAdornment?: any;
  inputProps?: any;
  width?: number;
  type?: string;
  styleClasses?: IClasses;
  multiline?: boolean;
  onValueChange?: (value: any, fieldKey?: string) => void;
}

const FormInputControl: FC<Props> = (props: Props) => {
  const classes = useStyles();

  const onValueChange = (value: any, fieldKey: string): void => {
    props.field.showErrors(true);
    props.onValueChange(value, fieldKey);
  };

  const editableContent = (): ReactNode => {
    const {
      type,
      field,
      showLabel,
      isDisabled,
      isAdornment,
      endAdornment,
      startAdornment,
      form,
      inputProps,
      width,
      styleClasses,
    } = props;
    const { hasError, touched, value, placeholder, key, errorSync } = field;

    return (
      <TextField
        autoComplete="off"
        {...field.bind()}
        label={showLabel && field.label}
        onChange={action(({ target }: ChangeEvent<HTMLInputElement>) => {
          onValueChange(target.value, key);
        })}
        onBlur={() => {
          field.onBlur();
        }}
        onFocus={() => {
          field.onFocus();
        }}
        type={type || 'text'}
        variant="outlined"
        fullWidth={true}
        value={props.value || value || ''}
        placeholder={placeholder || field.label}
        error={Boolean((Boolean(form?.submitted) || touched) && hasError)}
        helperText={Boolean((Boolean(form?.submitted) || touched) && hasError) && errorSync}
        disabled={isDisabled}
        multiline={props.multiline}
        InputProps={{
          endAdornment: isAdornment ? endAdornment : <></>,
          startAdornment: isAdornment ? startAdornment : <></>,
          inputProps: inputProps && inputProps,
        }}
        className={classNames({
          [classes.input]: true,
          [styleClasses?.inputControl]: styleClasses?.inputControl,
        })}
        sx={{ my: 1, width: width || '100%' }}
      />
    );
  };

  return <div>{editableContent()}</div>;
};

export default observer(FormInputControl);
