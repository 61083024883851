const AdminList = [
  {
    name: 'Provider List',
    navigate: '/admin/providers',
  },
  {
    name: 'Agencies',
    navigate: '/admin/agencies',
  },
  {
    name: 'Families',
    navigate: '/admin/families',
  },
  {
    name: 'Users',
    navigate: '/admin/users',
  },
  {
    name: "ECO's",
    navigate: '/admin/ecos',
  },
  {
    name: 'Sites',
    navigate: '/admin/sites',
  },
  {
    name: 'Teachers',
    navigate: '/admin/teachers',
  },
  {
    name: 'Children',
    navigate: '/admin/children',
  },
];

const ReportingList = [
  {
    name: 'Reporting Dashboard',
    navigate: '/',
  },
  {
    name: 'Download Overall Results',
    navigate: '/',
  },
  {
    name: 'Download Development Milestones',
    navigate: '/',
  },
  {
    name: 'Download BPSC',
    navigate: '/',
  },
  {
    name: 'Download PPSC',
    navigate: '/',
  },
  {
    name: 'Download POSI',
    navigate: '/',
  },
  {
    name: 'Download Family Questions',
    navigate: '/',
  },
  {
    name: 'Download Accumulated Report',
    navigate: '/',
  },
];

export const AdminOptions = [
  {
    name: 'Admin',
    isDropdown: true,
    dropdownList: AdminList,
    navigate: '/admin',
  },
  {
    name: 'Reporting Dashboard',
    isDropdown: false,
    dropdownList: [],
    navigate: '/admin/reporting-dashboard',    
  }, 
  {
    name: 'Survey Cycles',
    isDropdown: false,
    dropdownList: [],
    navigate: '/admin/send-survey',
  },
];

export const FamilyOptions = [
  {
    name: 'My Child',
    isDropdown: false,
    dropdownList: [],
    navigate: '/caregiver/children',
  },
];

export const EcoAdminOptions = [
  {
    name: 'Sites',
    isDropdown: false,
    dropdownList: [],
    navigate: '/eco/sites',
  },
  {
    name: 'Users',
    isDropdown: false,
    dropdownList: [],
    navigate: '/eco/users',
  },
  {
    name: 'Teachers',
    isDropdown: false,
    dropdownList: [],
    navigate: '/eco/teachers',
  },
  {
    name: 'Students',
    isDropdown: false,
    dropdownList: [],
    navigate: '/eco/students',
  },
  {
    name: 'Survey Cycles',
    isDropdown: false,
    dropdownList: [],
    navigate: '/eco/send-survey',
  },
];

export const EcoOptions = [
  {
    name: 'Survey Cycles',
    isDropdown: false,
    dropdownList: [],
    navigate: '/eco/send-survey',
  },
  {
    name: 'Students',
    isDropdown: false,
    dropdownList: [],
    navigate: '/eco/students',
  },
];

export const SiteOptions = [
  {
    name: 'Teachers',
    isDropdown: false,
    dropdownList: [],
    navigate: '/site/teachers',
  },
  {
    name: 'Students',
    isDropdown: false,
    dropdownList: [],
    navigate: '/site/students',
  },
];

export const AgencyOptions = [
  {
    name: 'Providers',
    isDropdown: false,
    dropdownList: [],
    navigate: '/agency/providers',
  },
];

export const ProviderOptions = [
  {
    name: 'Families',
    isDropdown: false,
    dropdownList: [],
    navigate: '/provider/families',
  },
];
