import { map, Observable } from 'rxjs';
import { GridSortModel } from '@mui/x-data-grid';
import { ProviderPaginationModel } from '../Models';
import { IAPIResponse, IProviderPagination } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { Sort } from '../Enums';

class ProviderService {

    public getAllProvider(
        pageNumber: number,
        pageSize: number,
        sortModel: GridSortModel,
        query: string,
        isArchived: boolean
    ): Observable<ProviderPaginationModel> {
        const http: HttpClient = new HttpClient();
        const params = Utils.buildParamString({
            PageNumber: pageNumber,
            PageSize: pageSize,
            Sort: sortModel[0]?.field || 'name',
            Dir: Sort[sortModel[0]?.sort] || Sort.asc,
            IsArchived: isArchived || false,
            Query: query,
        })
        return http
            .get<IAPIResponse<IProviderPagination>>(`${ApiUrl.providers}?${params}`)
            .pipe(map((response: IAPIResponse<IProviderPagination>) => ProviderPaginationModel.deserialize(response.Data)));
    }

    public addProvider(req): Observable<number> {
        const http: HttpClient = new HttpClient();
        return http
            .post<IAPIResponse<number>>(ApiUrl.providers, req)
            .pipe(map((response: IAPIResponse<number>) => (response.Data)));
    }

    public deleteProvider(id: string): Observable<boolean> {
        const http: HttpClient = new HttpClient();
        return http
            .delete<IAPIResponse<boolean>>(`${ApiUrl.providers}/${id}`)
            .pipe(map((response: IAPIResponse<boolean>) => response.Data));
    };

    public updateProvider(req): Observable<boolean> {
        const http: HttpClient = new HttpClient();
        return http
            .put<IAPIResponse<boolean>>(ApiUrl.providers, req)
            .pipe(map((response: IAPIResponse<boolean>) => (response.Data)));
    }
}

export default new ProviderService();
