import React, { FC, Fragment, useEffect, useRef } from 'react';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { Box, Button, Typography } from '@mui/material';
import { SurveyQuestionsModel } from '@models';
import { ChildSurveyStore } from '@stores';
import { ControlType } from '@shared';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomRadioButton from '../CustomRadioButton/CustomRadioButton';
import { useStyles } from './SurveyQuestions.styles';
import parse from 'html-react-parser';

type Props = {
  childSurveyStore?: ChildSurveyStore;
  showBack: boolean;
  handleNext: () => void;
  goBack: () => void;
};
const SurveyQuestions: FC<Props> = ({ handleNext, goBack, childSurveyStore, showBack }: Props) => {
  const classes = useStyles();
  const myRef = useRef(null);
  const { survey, surveySection } = childSurveyStore;
  const observable = useLocalObservable(() => ({
    requiredIds: [],
    error: false,
  }));

  useEffect(() => {
    executeScroll();
  }, []);

  const executeScroll = () => myRef.current.scrollIntoView();

  const getValue = (questionId: number): number => {
    const questionIndex = surveySection?.surveyQuestions?.findIndex(x => x.questionId === questionId);
    return (
      surveySection?.surveyQuestions[questionIndex]?.surveyQuestionOptions?.find(x => x.isSelected)?.questionOptionId ||
      0
    );
  };

  const getDefaultValue = (questionId: number): number[] => {
    const questionIndex = surveySection?.surveyQuestions?.findIndex(x => x.questionId === questionId);
    return (
      surveySection?.surveyQuestions[questionIndex]?.surveyQuestionOptions
        ?.filter(x => x.isSelected)
        ?.map(x => x.questionOptionId) || []
    );
  };

  const getRequiredIds = (): number[] => {
    let requiredIds = [];
    surveySection.surveyQuestions?.forEach(x => {
      if (x.isRequired) {
        requiredIds = [...requiredIds, x.questionId];
      }
    });
    return requiredIds;
  };

  const getLastSectionId = (): number => {
    return Boolean(survey?.surveySections)
      ? survey?.surveySections[survey?.surveySections?.length - 1]?.orderIndex
      : 0;
  };

  const onChange = (value: string, question: SurveyQuestionsModel, controlTypeId?: number, checked?: boolean) => {
    const { surveyQuestions } = surveySection;
    const questionIndex = surveyQuestions.findIndex(x => x.questionId === question.questionId);
    const surveySectionIndex = survey?.surveySections?.findIndex(
      x => x.surveySectionId === surveySection.surveySectionId
    );
    if (controlTypeId === ControlType.RADIO) {
      const updatedSurveyQuestionOptions = surveyQuestions[questionIndex].surveyQuestionOptions.map(option => {
        return {
          ...option,
          isSelected: option.questionOptionId === Number(value),
        };
      });
      surveyQuestions[questionIndex].surveyQuestionOptions = updatedSurveyQuestionOptions;
      if (question.isRequired) {
        const isExist = surveySection.requiredIds?.includes(question.questionId);
        runInAction(() => {
          if (isExist) {
            return;
          }
          surveySection.requiredIds = [...surveySection.requiredIds, question.questionId];
          observable.requiredIds = [...surveySection.requiredIds, question.questionId];
        });
      }
      survey[surveySectionIndex] = surveySection;
      return;
    }
    const optionIndex = surveyQuestions[questionIndex].surveyQuestionOptions.findIndex(
      x => x.questionOptionId === Number(value)
    );
    surveyQuestions[questionIndex].surveyQuestionOptions[optionIndex].isSelected = checked;
    if (question.isRequired) {
      const isExist = surveySection.requiredIds?.includes(question.questionId);
      const isOptionSelected = surveyQuestions[questionIndex].surveyQuestionOptions?.some(x => x.isSelected);
      runInAction(() => {
        if (isExist && isOptionSelected) {
          return;
        }
        if (isExist && !isOptionSelected) {
          const filterRequiredIds = surveySection.requiredIds?.filter(id => id !== question.questionId);
          surveySection.requiredIds = filterRequiredIds;
          observable.requiredIds = filterRequiredIds;
          return;
        }
        surveySection.requiredIds = [...surveySection.requiredIds, question.questionId];
        observable.requiredIds = [...surveySection.requiredIds, question.questionId];
      });
    }
    survey[surveySectionIndex] = surveySection;
  };

  const onNext = () => {
    if (getRequiredIds().length !== surveySection?.requiredIds?.length) {
      runInAction(() => {
        observable.error = true;
      });
      return;
    }
    runInAction(() => {
      observable.error = false;
    });
    executeScroll();
    handleNext();
  };

  const onBack = () => {
    runInAction(() => {
      observable.error = false;
    });
    executeScroll();
    goBack();
  };

  const getError = (questionId: number) => {
    return !observable.requiredIds?.includes(questionId);
  };

  return (
    <Box>
      <div ref={myRef}>
        <Typography component="div" padding="12px">
          {parse(`<div>${surveySection?.description}</div>`)}
        </Typography>
        <Box className={classes.radioContainer}>
          {surveySection?.surveyQuestions?.map(question => (
            <Fragment key={question?.questionId}>
              {question.controlTypeId === ControlType.RADIO ? (
                <CustomRadioButton
                  key={question.questionId}
                  options={question}
                  className={classes.radioButton}
                  defaultValue={getValue(question.questionId)}
                  error={observable.error && getError(question.questionId)}
                  onValueChange={value => onChange(value, question, 1)}
                />
              ) : (
                <CustomCheckbox
                  key={question.questionId}
                  options={question}
                  className={classes.radioButton}
                  defaultValue={getDefaultValue(question.questionId)}
                  error={observable.error && getError(question.questionId)}
                  onValueChange={(value, checked) => onChange(value, question, 2, checked)}
                />
              )}
            </Fragment>
          ))}
          {surveySection?.surveySectionId && (
            <div style={{ display: 'flex', justifyContent: showBack ? 'space-between' : 'flex-end', marginBottom: 10 }}>
              {showBack && (
                <Button onClick={onBack} className={classes.backBtn}>
                  Go Back
                </Button>
              )}
              <Button className={classes.saveBtn} variant="contained" onClick={onNext}>
                {surveySection?.surveySectionId === getLastSectionId() ? 'Save & Submit' : 'Save & Next'}
              </Button>
            </div>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default inject('childSurveyStore')(observer(SurveyQuestions));
