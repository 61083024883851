import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontWeight: 'bold',
        marginTop: 10,
    },
    button: {
        display: 'flex',
        alignSelf: 'flex-end',
        margin: '10px 0px'
    },
    icon: {
        color: theme.palette.info.main,
    },
    text: {
        padding: 2,
        fontWeight: 'bold',
        color: theme.palette.info.main,
    },
    input: {
        width: 400,
        margin: '16px 0 4px',
        '& .MuiFormHelperText-root': {
            marginTop: '-15px',
            marginBottom: '12px',
            marginLeft: 0,
        },
        '& .MuiInputBase-root': {
            marginTop: '9px',
            marginBottom: '20px'
        },
        '& .MuiInputLabel-root': {
            width: '100%',
            top: '-27px',
            transform: 'none',
            maxWidth: '100%',
            pointerEvents: 'all',
            color: theme.palette.grey[500],
            fontWeight: 600,
            fontSize: '16px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            top: '-3px',
            '& legend': {
                display: 'none',
            },
        },
        '& .MuiOutlinedInput-root': {
            padding: '5px 10px',
            '& .MuiInputAdornment-root': {
                '& .MuiButtonBase-root': {
                    padding: '5px 8px 8px',
                },
            },
        },
        '& .MuiInputBase-input': {
            padding: '7px 1px 10px',
        },
        '& MuiFormHelperText-root': {
            marginLeft: 0,
        },
    },
}));
