import { IAgency } from '../Interfaces';
import { UserModel } from './User.model';

export class AgencyModel {
  id: number;
  guid: string;
  name: string;
  agencyUserMappingId: string;
  agencyAdmin: UserModel;
  isMasterAgency: boolean;

  constructor(data?: Partial<AgencyModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IAgency): AgencyModel {
    if (!apiData) {
      return new AgencyModel();
    }

    const data: Partial<AgencyModel> = {
      id: apiData.AgencyId,
      guid: apiData.AgencyGuid,
      name: apiData.Name,
      agencyUserMappingId: apiData.AgencyUserMappingId,
      isMasterAgency: apiData.IsMasterAgency,
      agencyAdmin: UserModel.deserialize(apiData.AgencyAdmin),
    };

    return new AgencyModel(data);
  }

  static deserializeList(agencyList: IAgency[]): AgencyModel[] {
    return agencyList ? agencyList.map((apiData: IAgency) => new AgencyModel(AgencyModel.deserialize(apiData))) : [];
  }
}
