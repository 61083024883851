import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainHeader: {
    height: '65px',
    flex: 1,
  },
  notification: {
    marginLeft: '10px',
    height: '45px',
    width: '45px',
    '& svg': {
      height: '45px',
      width: '45px',
    },
    '& .MuiBadge-badge': {
      right: 17,
      top: 9,
    },
  },
  toolBar: {
    padding: '2px 40px',
    height: '85px',
    [theme.breakpoints.down('md')]: {
      padding: '2px 20px',
    },
    justifyContent: 'space-between',
  },
  badge: {
    fontSize: '0.55rem',
    minWidth: '17px',
    height: '17px',
  },
  headerLogo: {
    width: 'auto',
    height: '95px',
    marginTop: '5px',
  },
  userButton: {
    color: theme.palette.common.black,
    marginRight: '4rem',
    textTransform: 'inherit',
  },
  activeLink: {
    color: theme.palette.primary.main,
    marginRight: '4rem',
    textTransform: 'inherit',
  },
  list: {
    '& .MuiPaper-root ': {
      padding: '12px',
    },
  },
}));
