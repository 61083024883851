import { action, makeObservable, observable } from 'mobx';
import { TeacherModel } from '../Models';

class TeacherStore {
  public teacherList: TeacherModel[] = [];

  constructor() {
    makeObservable(this, {
      teacherList: observable,
      setTeacherList: action,
      clear: action,
    });
  }

  public setTeacherList(teachers: TeacherModel[]): void {
    this.teacherList = teachers;
  }

  public clear(): void {
    this.teacherList = [];
  }
}

export default TeacherStore;
