export const PasswordFields = [
    {
        name: 'password',
        type: 'password',
        placeholder: 'Enter your Password',
        label: 'Password',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'confirmPassword',
        type: 'password',
        placeholder: 'Enter your Confirm Password',
        label: 'Confirm Password',
        rules: 'required|string|same:password',
        value: '',
    },
];
