import { ICycle } from '../Interfaces';

export class CycleModel {
  id: number;
  ecoSiteMappingId: number;
  name: number;
  statusId: number;
  status: string;
  startDate: string;
  endDate: string;
  completedStudents: number;
  totalStudents: number;
  siteId: any;

  constructor(data?: Partial<CycleModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ICycle): CycleModel {
    if (!apiData) {
      return new CycleModel();
    }

    const data: Partial<CycleModel> = {
      id: apiData.Id,
      ecoSiteMappingId: apiData.EcoSiteMappingId,
      name: apiData.Name,
      statusId: apiData.StatusId,
      status: apiData.Status,
      startDate: apiData.StartDate,
      endDate: apiData.EndDate,
      completedStudents: apiData.CompletedStudents,
      totalStudents: apiData.TotalStudents,
      siteId: apiData.SiteId,
    };

    return new CycleModel(data);
  }

  static deserializeList(cycleList: ICycle[]): CycleModel[] {
    return cycleList ? cycleList.map((apiData: ICycle) => new CycleModel(CycleModel.deserialize(apiData))) : [];
  }
}
