import { IUser } from '../Interfaces';

export class UserModel {
  id: number;
  userId: number;
  siteUserId: number;
  firstName: string;
  lastName: string;
  email: string;
  active: boolean;
  type: string;
  status: string;
  createdOn: string;
  updatedOn: string;
  createdBy: string;
  updatedBy: string;
  phoneNumber: string;
  name?: string;
  isMasterUser?: boolean;

  constructor(data?: Partial<UserModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IUser): UserModel {
    if (!apiData) {
      return new UserModel();
    }

    const data: Partial<UserModel> = {
      id: apiData.Id,
      userId: apiData.UserId,
      siteUserId: apiData.SiteUsersId,
      firstName: apiData.FirstName,
      lastName: apiData.LastName,
      email: apiData.Email,
      active: apiData.Active,
      type: apiData.Type,
      status: apiData.Status,
      createdOn: apiData.CreatedOn,
      createdBy: apiData.CreatedBy,
      updatedOn: apiData.UpdatedOn,
      phoneNumber: apiData.PhoneNumber,
      name: apiData.Name,
      isMasterUser: apiData.IsMasterUser,
    };

    return new UserModel(data);
  }

  static deserializeList(userList: IUser[]): UserModel[] {
    return userList ? userList.map((apiData: IUser) => new UserModel(UserModel.deserialize(apiData))) : [];
  }
}
