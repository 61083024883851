export const UserFields = [
    {
        name: 'name',
        type: 'string',
        label: 'Agency Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'firstName',
        type: 'string',
        label: 'Admin First Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'lastName',
        type: 'string',
        label: 'Admin Last Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'email',
        type: 'string',
        label: 'Admin Email',
        rules: 'required|string|email',
        value: '',
    },
];
