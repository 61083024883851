import React, { ReactElement, FC, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useParams } from 'react-router';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { Typography, Box, Stepper, Step, StepLabel } from '@mui/material';
import { ChildrenStore, ProfileStore, UIStore } from '@stores';
import { ChildrenService, UserService } from '@services';
import { ChildrenModel, ProfileModel } from '@models';
import { useNotification } from '@shared';
import ConsentForm from './ConsentForm/ConsentForm';
import DemographicsForm from './DemographicsForm/DemographicsForm';
import { useStyles } from './SurveyConsent.styles';

type Props = {
  childrenStore?: ChildrenStore;
  profileStore?: ProfileStore;
};

const steps = ['Consent Form', 'Demographics'];

const SurveyConsent: FC<Props> = ({ childrenStore, profileStore }: Props) => {
  const classes = useStyles();
  const params = useParams();
  const notification = useNotification();
  const observable = useLocalObservable(() => ({
    activeStep: 0,
  }));
  const { child } = childrenStore;

  useEffect(() => {
    getChildById();
    userProfile();
    return () => {
      childrenStore.clear();      
    };
  }, []);

  const getChildById = () => {
    UIStore.showLoader();
    ChildrenService.getChildById(Number(params?.id)).subscribe({
      next: (response: ChildrenModel) => {
        childrenStore.setChild(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const userProfile = () => {
    UIStore.showLoader();
    UserService.userProfile().subscribe({
      next: (response: ProfileModel) => {
        profileStore.setProfile(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const handleNext = (step: number) => {
    runInAction(() => {
      observable.activeStep = step;
    });
  };

  const renderSteps = (): ReactElement => {
    if (observable.activeStep === 0) {
      return <ConsentForm handleNext={() => handleNext(1)} caregiverName={profileStore.profile.firstName + ' ' + profileStore.profile.lastName} caregiverEmail={profileStore.profile.email} />;
    }
    if (observable.activeStep === 1) {
      return <DemographicsForm />;
    }
  };

  return (
    <>
      {!UIStore.pageLoading && (
        <Box sx={{ width: '100%' }}>
          <Typography
            style={{ marginBottom: '2rem', marginTop: '2rem', fontWeight: 600, fontSize: 24 }}
            variant="body1"
          >
            {`Child Consent - ${child?.firstName} ${child?.lastName}`}
          </Typography>
          <div>
            <Stepper activeStep={observable.activeStep} className={classes.stepper}>
              {steps.map(label => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <Box>{renderSteps()}</Box>
        </Box>
      )}
    </>
  );
};

export default inject('childrenStore','profileStore')(observer(SurveyConsent));
