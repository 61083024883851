export const Fields = [
  {
    name: 'name',
    label: 'Cycle Name',
    rules: 'required|string',
    value: '',
  },
  {
    name: 'statusId',
    label: 'Status',
    placeholder: 'Status',
    rules: 'required',
    value: '',
  },
  {
    name: 'startDate',
    type: 'string',
    placeholder: 'Start Date',
    label: 'Start Date',
    rules: 'required',
    value: '',
  },
  {
    name: 'endDate',
    type: 'string',
    placeholder: 'End Date',
    label: 'End Date',
    rules: 'required',
    value: '',
  },
];
