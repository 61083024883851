import React, { ChangeEvent, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { TextField } from '@mui/material';
import { useStyles } from './CustomInputField.styles';

type Props = {
  value: string;
  onValueChange: (value: string) => void;
  searchPlaceholder: string;
};

const CustomInputField: FC<Props> = ({ searchPlaceholder, value, onValueChange }: Props) => {
  const classes = useStyles();

  return (
    <TextField
      placeholder={searchPlaceholder || 'Search'}
      value={value || ''}
      onChange={action(({ target }: ChangeEvent<HTMLInputElement>) => {
        onValueChange(target.value);
      })}
      inputProps={{
        style: {
          padding: '6px 10px',
        },
      }}
      className={classes.inputClass}
    />
  );
};
export default observer(CustomInputField);
