import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Box, Typography } from '@mui/material';
import { ResultsByDomainModel } from '@models';

type Props = {
  progress: ResultsByDomainModel[];
};

const ResultsByDomain: FC<Props> = ({ progress }: Props) => {
  const data = {
    labels: progress?.map(x => x?.sectionAbbreviation),
    datasets: [
      {
        label: 'Meets Expectations',
        data: progress?.map(x => x?.meetsExpectations),
        backgroundColor: progress?.map(x => x?.meetsExpectationsColor),
        borderColor: progress?.map(x => x?.meetsExpectationsBorderColor),
        borderWidth: 1,
      },
      {
        label: 'Needs Review',
        data: progress?.map(x => x?.needsReview),
        backgroundColor: progress?.map(x => x?.needsReviewColor),
        borderColor: progress?.map(x => x?.needsReviewBorderColor),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box style={{ padding: '20px' }}>
      <Bar data={data} width="1362px" height="400px" />
      <Typography textAlign="center">Results By Domain</Typography>
    </Box>
  );
};

export default ResultsByDomain;
