import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    width: '50%',
    marginLeft: '25%',
    '& .MuiStepLabel-label': {
      fontWeight: 600,
      fontSize: '20px',
    },
    '& .Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },
  },
  demoContainer: {
    marginTop: '2rem',
    width: '50%',
    margin: 'auto',
  },
  boxContainer: {
    marginTop: '2rem',
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    fontSize: '16px',
    marginTop: '2rem',
  },
  selectBox: {
    marginTop: '1rem',
    '& .MuiFormLabel-root': {
      fontWeight: 600,
      fontSize: '16px',
      color: theme.palette.grey[500],
      top: '-9px',
    },
    '&.MuiInputBase-root ': {
      marginTop: '3px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
  },
  radioBtn: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioLabel: {
    color: '#393D42',
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '1rem',
  },
  submitBtn: {
    width: '200px',
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));
