import { IProfile } from '../Interfaces';

export class ProfileModel {
    id: number;
    userTypeRefrenceId: number;
    userTypeRefrenceGuid: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    userType: string;
    active: boolean;

    constructor(data?: Partial<ProfileModel>) {
        Object.assign(this, data);
    }

    static deserialize(apiData: IProfile): ProfileModel {
        if (!apiData) {
            return new ProfileModel();
        }

        const data: Partial<ProfileModel> = {
            id: apiData.Id,
            userTypeRefrenceId: apiData.UserTypeRefrenceId,
            userTypeRefrenceGuid: apiData.UserTypeRefrenceGuid,
            firstName: apiData.FirstName,
            lastName: apiData.LastName,
            fullName: apiData.FullName,
            email: apiData.Email,
            userType: apiData.UserType,
            active: apiData.Active,
        };

        return new ProfileModel(data);
    }

}
