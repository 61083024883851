import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
        backgroundColor: theme.palette.common.black,
        padding: '10px 10px 7px',
        minHeight: '64px',
    },
    drawer: {
        display: 'none',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
        },
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '260px',
        },
    },
    collapseListItem: {
        flexDirection: 'column',
        alignItems: 'baseline',
        paddingTop: 0,
        '& a': {
            textDecoration: 'none',
            color: theme.palette.common.black,
        },
        '& svg': {
            position: 'relative',
            top: '6px',
        },
    },
    itemText: {
        width: '100%',
        padding: '8px 16px',
        margin: '0',
    },
    collapsePanel: {
        paddingLeft: '15px',
    },
    childButton: {
        padding: 0,
    },
    headerLogo: {
        width: 'auto',
        height: '50px'
    }
}));
