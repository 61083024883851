import { ITeacherPagination } from '../Interfaces';
import { PageModel } from './Page.model';
import { TeacherModel } from './Teacher.model';

export class TeacherPaginationModel extends PageModel {
  results: TeacherModel[];

  constructor(data?: Partial<TeacherPaginationModel>) {
    super();
    Object.assign(this, data);
  }

  static deserialize(apiData: ITeacherPagination): TeacherPaginationModel {
    if (!apiData) {
      return new TeacherPaginationModel();
    }

    const data: Partial<TeacherPaginationModel> = {
      pageNumber: apiData.PageNumber,
      pageSize: apiData.PageSize,
      totalNumberOfRecords: apiData.TotalNumberOfRecords,
      results: TeacherModel.deserializeList(apiData.Results),
    };

    return new TeacherPaginationModel(data);
  }
}
