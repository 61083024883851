export class LocalStorageService {
  // Authentication Related Methods
  static saveAuthToken(token: string): void {
    localStorage.setItem('token', token);
  }

  static getAuthToken(): string {
    return localStorage.getItem('token') || null;
  }

  static removeAuthToken(): void {
    localStorage.setItem('token', '');
  }

  static saveRedirectUrl(redirectUrl: string): void {
    localStorage.setItem('redirectUrl', redirectUrl);
  }

  static getRedirectUrl(): string {
    return localStorage.getItem('redirectUrl') || null;
  }

  static removeRedirectUrl(): void {
    localStorage.setItem('redirectUrl', '');
  }

  static clear(): void {
    this.removeAuthToken();
    this.removeRedirectUrl();
  }
}
