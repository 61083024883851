import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Button, Typography } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  Actions,
  CustomDataGrid,
  CustomDatePicker,
  CustomDialog,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  ParentEntityType,
  Sort,
  useNotification,
  UserSearch,
  Utils,
} from '@shared';
import { UIStore, ChildrenStore, ProfileStore } from '@stores';
import { ChildrenService, CommonService } from '@services';
import { ChildrenModel, ChildrenPaginationModel, OptionsModel } from '@models';
import { Fields } from './Fields';
import { useStyles } from './Children.styles';
import { CrossIcon, EditIcon, PencilIcon, VisibilityIcon } from '@assets';

type Props = {
  childrenStore?: ChildrenStore;
  profileStore?: ProfileStore;
};

const form: MobxReactForm = getFormValidation(Fields, null);

const Children: FC<Props> = ({ childrenStore, profileStore }: Props) => {
  const notification = useNotification();
  const classes = useStyles();
  const navigate = useNavigate();

  const observable = useLocalObservable(() => ({
    child: new ChildrenModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    sort: 'firstName',
    dir: Sort.asc,
    totalNumberOfRecords: 0,
    providerId: 0,
    sortModel: [],
    providerList: [],
  }));
  const columns = [
    {
      field: 'providerName',
      headerName: 'Provider Name',
      minWidth: 100,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    { field: 'firstName', headerName: 'First Name', headerClassName: 'super-app-theme--header' },
    { field: 'lastName', headerName: 'Last Name', headerClassName: 'super-app-theme--header' },
    {
      field: 'weekPremature',
      headerName: 'Weeks Premature',
      minWidth: 100,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
    },
    {
      field: 'birthDate',
      headerName: 'Birth Date',
      minWidth: 100,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },

    {
      field: 'action',
      headerName: 'Action',
      minWidth: 300,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => renderActions(params),
    },
  ];

  const { profile } = profileStore;

  useEffect(() => {
    if (profile?.userTypeRefrenceId) {
      getProviderOptions();
    }
  }, [profile?.userTypeRefrenceId]);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isEdit = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getChildrenList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getChildrenList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
      observable.sort = newSortModel[0]?.field;
      observable.dir = Sort[newSortModel[0]?.sort];
    });
    getChildrenList();
  };

  const handleDeleteClose = () => {
    runInAction(() => {
      observable.isDelete = false;
    });
  };

  const getProviderOptions = () => {
    UIStore.showLoader();
    CommonService.getProviderOptions().subscribe({
      next: (response: OptionsModel[]) => {
        runInAction(() => {
          observable.providerList = [{ value: 0, label: 'All Provider' }, ...response];
        });
        getChildrenList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const getChildrenList = () => {
    const { pageNumber, pageSize, sort, dir, query, providerId } = observable;
    UIStore.showLoader();
    ChildrenService.getFamilyChildren(
      pageNumber,
      pageSize,
      sort,
      dir,
      query,
      providerId,
      profile?.userTypeRefrenceId
    ).subscribe({
      next: (response: ChildrenPaginationModel) => {
        childrenStore.setChildrenList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onUserAction = (child: ChildrenModel, isEdit = false, isSurvey = false) => {
    runInAction(() => {
      observable.child = child;
      if (isSurvey) {
        navigate(`/caregiver/child-survey/${child?.childSurveyGuid}`);
        return;
      }
      if (isEdit) {
        getChildById();
        return;
      }
      observable.isDelete = true;
    });
  };

  const getChildById = () => {
    UIStore.showLoader();
    ChildrenService.getChildById(observable.child?.id).subscribe({
      next: (response: ChildrenModel) => {
        const { firstName, lastName, birthDate, weekPremature } = response;
        form.$('firstName').set(firstName);
        form.$('lastName').set(lastName);
        form.$('weekPremature').set(weekPremature);
        form.$('birthDate').set(birthDate);
        runInAction(() => {
          observable.isEdit = true;
          observable.isOpen = true;
          observable.child = response;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    if (observable.isEdit) {
      editChild();
      return;
    }
    addChild();
  };

  const editChild = () => {
    const { firstName, lastName, birthDate, weekPremature } = form.values();
    const { id } = observable.child;
    const request = {
      id,
      firstName,
      lastName,
      birthDate: Utils.formattedDate(birthDate, 'MM/DD/YYYY'),
      weekPremature: Number(weekPremature),
      parentEntityId: observable.child?.parentEntityId,
    };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildrenService.updateChild(request).subscribe({
          next: (response: boolean) => {
            handleClose();
            getChildrenList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const addChild = () => {
    const { firstName, lastName, birthDate, weekPremature } = form.values();
    const { userTypeRefrenceId } = profileStore?.profile;
    const request = {
      firstName,
      lastName,
      birthDate: Utils.formattedDate(birthDate, 'MM/DD/YYYY'),
      weekPremature: Number(weekPremature),
      parentEntityId: userTypeRefrenceId,
      parentEntityType: ParentEntityType.FAMILY,
    };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildrenService.addChild(request).subscribe({
          next: (response: number) => {
            handleClose();
            getChildrenList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const deleteChild = () => {
    UIStore.showLoader();
    ChildrenService.deleteChild(observable.child?.id).subscribe({
      next: (response: boolean) => {
        handleDeleteClose();
        getChildrenList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSearch = (searchText: string, isActive: number, providerId: number) => {
    runInAction(() => {
      observable.query = searchText;
      observable.providerId = providerId;
      observable.pageNumber = 1;
    });
    getChildrenList();
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
      observable.providerId = 0;
    });
    getChildrenList();
  };

  const renderActions = params => {
    return (
      <>
        {params?.row?.showTakeSurvey && (          
          <Button variant="contained" color="primary" className={classes.button} onClick={() => onUserAction(params.row, false, true)}>
          Take Survey
        </Button>
        )}
        <Actions
          showTooltip={true}
          tooltipTitle="Edit"
          onClick={() => onUserAction(params.row, true)}
          imgSrc={PencilIcon}
        />
        <Actions showTooltip={true} tooltipTitle="Delete" onClick={() => onUserAction(params.row)} imgSrc={CrossIcon} />
        
        {params.row?.showProgress && (
          <Actions
            showTooltip={true}
            tooltipTitle="View Progress"
            onClick={() => navigate(`/caregiver/child-progress/${params?.row?.id}`)}
            imgSrc={VisibilityIcon}
          />
        )}
      </>
    );
  };

  return (
    <div className={classes.mainContainer}>
      <Box component="div" display="flex" flexDirection="column">
        <Typography variant="h5" className={classes.title}>
          Children
        </Typography>
        <UserSearch onSearch={onSearch} onClear={onClear} provider={true} providerOptions={observable.providerList} />
        {childrenStore.childrenList?.length <= 10 && (
          <Button
            className={classes.button}
            onClick={() =>
              runInAction(() => {
                observable.isOpen = true;
              })
            }
          >
            <AddCircleIcon className={classes.icon} />
            <Typography variant="body1" className={classes.text}>
              ADD CHILD
            </Typography>
          </Button>
        )}
        <CustomDataGrid
          columns={columns}
          data={childrenStore.childrenList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
        />
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit Child' : 'Add Child'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('firstName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'firstName')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('lastName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'lastName')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('weekPremature')}
            type="number"
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'weekPremature')}
            showLabel={true}
            form={form}
          />
          <CustomDatePicker
            field={form.$('birthDate')}
            onValueChange={value => handleChange(value, 'birthDate')}
            value={form.$('birthDate').value}
            showLabel={true}
            isBirthDate={true}
            endDate={Utils.formattedDate(String(new Date()), 'MM/DD/YYYY')}
            className={classes.dateInput}
            form={form}
          />
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this child?"
          handleClose={handleDeleteClose}
          handleDelete={deleteChild}
        />
      )}
    </div>
  );
};

export default inject('childrenStore', 'profileStore')(observer(Children));
