import { IFamily } from '../Interfaces';

export class FamilyModel {
  id: number;
  userId: number;
  guid: string;
  providerId: number;
  providerName: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  active: boolean;
  type: string;
  childrenCount: number;
  isArchived: boolean;
  isAssignedToMasterProvider: boolean;

  constructor(data?: Partial<FamilyModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IFamily): FamilyModel {
    if (!apiData) {
      return new FamilyModel();
    }

    const data: Partial<FamilyModel> = {
      id: apiData.Id,
      userId: apiData.UserId,
      guid: apiData.FamilyGuid,
      providerId: apiData.ProviderId,
      providerName: apiData.ProviderName,
      fullName: apiData.FullName,
      firstName: apiData.FirstName,
      lastName: apiData.LastName,
      email: apiData.Email,
      phoneNumber: apiData.PhoneNumber,
      active: apiData.Active,
      type: apiData.Type,
      isArchived: apiData.IsArchived,
      childrenCount: apiData.ChildrenCount,
      isAssignedToMasterProvider: apiData.IsAssignedToMasterProvider,
    };

    return new FamilyModel(data);
  }

  static deserializeList(familyList: IFamily[]): FamilyModel[] {
    return familyList ? familyList.map((apiData: IFamily) => new FamilyModel(FamilyModel.deserialize(apiData))) : [];
  }
}
