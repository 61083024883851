import { IPagination, } from '../Interfaces';
import { PageModel } from './Page.model';
import { UserModel } from './User.model';

export class PaginationModel extends PageModel {
    results: UserModel[];

    constructor(data?: Partial<PaginationModel>) {
        super();
        Object.assign(this, data);
    }

    static deserialize(apiData: IPagination): PaginationModel {
        if (!apiData) {
            return new PaginationModel();
        }

        const data: Partial<PaginationModel> = {
            pageNumber: apiData.PageNumber,
            pageSize: apiData.PageSize,
            totalNumberOfRecords: apiData.TotalNumberOfRecords,
            results: UserModel.deserializeList(apiData.Results),
        };

        return new PaginationModel(data);
    }
}
