import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#2da5d9',
      main: '#169BD5',
      dark: '#138bbf',
      contrastText: '#fff',
      100: '#BAE4EE',
    },
    grey: {
      100: '#BCBCBC',
      500: '#393D42',
    },
    info: {
      main: '#0000FF',
    },
    success: {
      light: '#4CAF50',
      main: '#2E7D32',
      dark: '#1B5E20',
      contrastText: '#fff',
      100: '#80C883',
    },
    warning: {
      light: '#FF9800',
      main: '#ED6C02',
      dark: '#E65100',
      contrastText: '#fff',
      100: '#FCEEA9',
    },
  },
  typography: {},
  components: {},
});
