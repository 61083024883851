import React, { FC } from 'react';
import { useParams } from 'react-router';
import { runInAction } from 'mobx';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { Box, Button, Typography } from '@mui/material';
import { Template } from '@assets';
import { useNotification, Utils } from '@shared';
import { SiteService } from '@services';
import { SiteStore, UIStore } from '@stores';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useStyles } from './StepThree.styles';
import ChildrenGrid from './ChildrenGrid/ChildrenGrid';

type Props = {
  onNext: () => void;
  onBack: () => void;
  siteStore?: SiteStore;
};

const StepThree: FC<Props> = ({ onNext, onBack, siteStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const params = useParams();
  const observable = useLocalObservable(() => ({
    selectedFile: null,
  }));

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(Template);
    const wb = { Sheets: { template: ws }, SheetNames: ['template'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(data, 'template' + '.xlsx');
  };

  const onFileChange = event => {
    runInAction(() => {
      observable.selectedFile = event.target.files[0];
    });
  };

  const onFileUpload = () => {
    const file = Utils.getFileAsFormData(observable.selectedFile);
    file.append('siteId', String(siteStore.site?.id));
    UIStore.showLoader();
    SiteService.addSiteChildren(file).subscribe({
      next: (response: string) => {
        UIStore.hideLoader();
        notification.success(response);
        onNext();
      },
      error: error => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSave = () => {
    if (params?.id) {
      onNext();
      return;
    }
    onFileUpload();
  };

  return (
    <>
      {params?.id ? (
        <>
        <ChildrenGrid />
        <Box className={classes.outerContainer}>
          <Typography variant="h6" className={classes.heading}>
            Download Import Template
          </Typography>          
          <Button variant="contained" className={classes.downloadBtn} onClick={exportToExcel}>
            Download Template
          </Button>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" className={classes.heading}>
              Import File
            </Typography>
            <input type="file" accept=".xlsx" onChange={onFileChange} />
          </Box>
          <Button variant="contained" className={classes.downloadBtn} onClick={onFileUpload}>
            Import Students
          </Button>
        </Box>
        </>
      ) : (
        <Box className={classes.outerContainer}>
          <Typography variant="h6" className={classes.heading}>
            Download Import Template
          </Typography>          
          <Button variant="contained" className={classes.downloadBtn} onClick={exportToExcel}>
            Download Template
          </Button>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" className={classes.heading}>
              Import File
            </Typography>
            <input type="file" accept=".xlsx" onChange={onFileChange} />
          </Box>
          <Button variant="contained" className={classes.downloadBtn} onClick={onFileUpload}>
            Import Students
          </Button>
        </Box>
      )}
      <div className={classes.btnContainer}>
        {params?.id && (
          <Button className={classes.backBtn} onClick={onBack}>
            Go Back
          </Button>
        )}
        <Button variant="contained" className={classes.saveBtn} onClick={onSave}>
          Save
        </Button>
      </div>
    </>
  );
};

export default inject('siteStore')(observer(StepThree));
