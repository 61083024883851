import { action, makeObservable, observable } from 'mobx';
import { ChildrenModel, ChildTeachersModel } from '../Models';

class ChildrenStore {
  public childrenList: ChildrenModel[] = [];
  public childTeachers: ChildTeachersModel = new ChildTeachersModel();
  public child: ChildrenModel = new ChildrenModel();

  constructor() {
    makeObservable(this, {
      childrenList: observable,
      childTeachers: observable,
      child: observable,
      setChildrenList: action,
      setChildTeachers: action,
      setChild: action,
      clear: action,
    });
  }

  public setChildrenList(children: ChildrenModel[]): void {
    this.childrenList = children;
  }

  public setChildTeachers(childTeachers: ChildTeachersModel): void {
    this.childTeachers = childTeachers;
  }

  public setChild(child: ChildrenModel): void {
    this.child = child;
  }

  public clear(): void {
    this.childrenList = [];
    this.childTeachers = new ChildTeachersModel();
    this.child = new ChildrenModel();
  }
}

export default ChildrenStore;
