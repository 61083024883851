export const Fields = [
  {
    name: 'siteId',
    label: 'Site',
    placeholder: 'Select',
    rules: 'required',
    value: '',
  },
];

export const CycleFields = [
  {
    name: 'cycleId',
    label: 'Cycle',
    placeholder: 'Select',
    rules: 'required',
    value: '',
  },
];

export const SurveyFields = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Select',
    rules: 'required',
    value: [],
  },
];
