import React, { FC } from 'react';
import {
    ClickAwayListener,
    Drawer,
    List,
    ListItem,
    ListItemButton,
} from '@mui/material';
import { useStyles } from './SideNavBar.styles';
import { AdminOptions } from '../HeaderOptions';
import UserDropdown from '../UserDropdown/UserDropdown';

interface Props {
    openRightPanel: boolean;
    setOpenRightPanel?: (open: boolean) => void;
}

const SideNavBar: FC<Props> = props => {
    const { openRightPanel, setOpenRightPanel } = props;
    const styledClasses = useStyles();

    const navigateToPath = path => {
        setOpenRightPanel(false);
    };

    const getOptions = () => {
        return AdminOptions;
    }

    const renderMenu = (value) => {
        return (
            <ListItem>
                <UserDropdown title={value.name} options={value.dropdownList} isMobileView={true} />
            </ListItem>
        );
    };

    const collapseRightSideMenu = () => {
        if (openRightPanel) {
            setOpenRightPanel(false);
        }
    };

    return (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => collapseRightSideMenu()}>
            <Drawer variant="persistent" anchor="right" open={openRightPanel} className={styledClasses.drawer}>
                <List>
                    {getOptions().map((value, index) => {
                        return (
                            <div key={index}>{renderMenu(value)}</div>
                        );
                    })}
                    <ListItem>
                        <ListItemButton onClick={() => navigateToPath('')}>Contact</ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigateToPath('')}>About</ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </ClickAwayListener>
    );
};

export default SideNavBar;
