import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Typography, Button, List, ListItem, Checkbox, ListItemButton, ListItemText } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import { Actions, CustomDataGrid, CustomDialog, DeleteDialog, Status, useNotification, UserSearch } from '@shared';
import { UIStore, ECOStore } from '@stores';
import { CircleCheckGreen, CircleCheckGrey, CrossIcon, EditIcon, VisibilityIcon } from '@assets';
import { ECOService, EcoSiteMappingService } from '@services';
import { ECOModel, ECOPaginationModel, SiteModel } from '@models';
import { useStyles } from './ECO.styles';

type Props = {
  ecoStore?: ECOStore;
};

const ECO: FC<Props> = ({ ecoStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const navigate = useNavigate();
  const observable = useLocalObservable(() => ({
    eco: new ECOModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    showSites: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    status: null,
    totalNumberOfRecords: 0,
    sortModel: [],
  }));
  const { sites } = ecoStore;

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'active',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params =>
        params.value ? <img src={CircleCheckGreen} alt="active" /> : <img src={CircleCheckGrey} alt="inactive" />,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    getECOList();
  }, []);

  const handleClose = () => {
    runInAction(() => {
      observable.isDelete = false;
      observable.showSites = false;
    });
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getECOList();
  };

  const onChange = (checked: boolean, siteId: number) => {
    const index = sites?.findIndex(x => x.id === siteId);
    if (index < 0) {
      return;
    }
    const updatedSites = {
      ...sites[index],
      isAssociated: checked,
    };
    ecoStore.setSites([...sites.slice(0, index), updatedSites, ...sites.slice(index + 1)]);
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getECOList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
    });
    getECOList();
  };

  const getECOList = () => {
    const { pageNumber, pageSize, sortModel, query, status } = observable;
    UIStore.showLoader();
    ECOService.getAllECOs(pageNumber, pageSize, sortModel, query, status).subscribe({
      next: (response: ECOPaginationModel) => {
        ecoStore.setECOList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSearch = (searchText: string, status: number) => {
    runInAction(() => {
      observable.query = searchText;
      observable.status = status - 1;
      observable.pageNumber = 1;
    });
    getECOList();
  };

  const getEcoSites = () => {
    UIStore.showLoader();
    EcoSiteMappingService.getEcoSites(observable.eco?.id).subscribe({
      next: (response: SiteModel[]) => {
        ecoStore.setSites(response);
        UIStore.hideLoader();
        runInAction(() => {
          observable.showSites = true;
        });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onAction = (eco: ECOModel, isEdit = false, isMapping = false) => {
    runInAction(() => {
      observable.eco = eco;
      if (isMapping) {
        getEcoSites();
        return;
      }
      if (!isEdit) {
        observable.isDelete = true;
        return;
      }
      navigate(`/admin/ecos/${eco.guid}`);
    });
  };

  const deleteECO = () => {
    UIStore.showLoader();
    ECOService.deleteECO(observable.eco?.guid).subscribe({
      next: (response: boolean) => {
        handleClose();
        getECOList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
      observable.status = null;
    });
    getECOList();
  };

  const onSubmit = () => {
    const filteredSitesIds = sites?.filter(site => site.isAssociated)?.map(site => site.id);
    const req = {
      ecoId: observable.eco?.id,
      siteIds: filteredSitesIds,
    };
    UIStore.showLoader();
    EcoSiteMappingService.mapSitesToEco(req).subscribe({
      next: (response: boolean) => {
        UIStore.hideLoader();
        handleClose();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const renderActions = params => {
    return (
      <>
        <Actions btnText="Edit" onClick={() => onAction(params.row, true)} imgSrc={EditIcon} />
        <Actions btnText="Delete" onClick={() => onAction(params.row)} imgSrc={CrossIcon} />
        <Actions btnText="Sites" onClick={() => onAction(params.row, false, true)} imgSrc={VisibilityIcon} />
      </>
    );
  };

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        ECO List
      </Typography>
      <UserSearch options={Status} showUserType={true} onSearch={onSearch} onClear={onClear} />
      <Box component="div" display="flex" flexDirection="column">
        <Button className={classes.button} onClick={() => navigate('/admin/add-eco')}>
          <AddCircleIcon className={classes.icon} />
          <Typography variant="body1" className={classes.text}>
            ADD ECO
          </Typography>
        </Button>
        <CustomDataGrid
          columns={columns}
          data={ecoStore.ecoList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
        />
      </Box>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this ECO?"
          handleClose={handleClose}
          handleDelete={deleteECO}
        />
      )}
      {observable.showSites && (
        <CustomDialog open={observable.showSites} title="Sites" onClose={handleClose} onSubmit={onSubmit}>
          <List dense className={classes.list}>
            {sites?.map(site => {
              const labelId = `checkbox-list-secondary-label-${site.id}`;
              return (
                <ListItem
                  key={site.id}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={e => onChange(e.target.checked, site.id)}
                      checked={site.isAssociated}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText id={labelId} primary={site.name} className={classes.listText} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </CustomDialog>
      )}
    </div>
  );
};

export default inject('ecoStore')(observer(ECO));
