export const UserFields = [
    {
        name: 'name',
        type: 'string',
        label: 'Provider Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'email',
        type: 'string',
        label: 'Email',
        rules: 'required|string|email',
        value: '',
    },
    {
        name: 'firstName',
        type: 'string',
        label: 'First Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'lastName',
        type: 'string',
        label: 'Last Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'agencyId',
        type: 'string',
        placeholder: 'Agency',
        rules: 'required',
        label: "Agency",
    },
    {
        name: 'isAsqProfile',
        type: 'boolean',
        label: "ASQ Profile",
        value: false,
    },
    {
        name: 'isArchived',
        type: 'boolean',
        label: "Archive",
        value: false,
    },
];

