export const changePasswordFields = [
  {
    name: 'oldPassword',
    type: 'password',
    label: 'Current Password',
    placeholder: 'Current Password',
    rules: 'required|string',
  },
  {
    name: 'newPassword',
    type: 'password',
    label: 'New Password',
    placeholder: 'New Password',
    rules: 'required|string',
  },
  {
    name: 'confirmPassword',
    type: 'password',
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
    rules: 'required|string|same:newPassword',
  },
];
