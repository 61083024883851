import { action, makeObservable, observable } from 'mobx';
import { OptionsModel } from '../Models';

class CommonStore {
  public providers: OptionsModel[] = [];
  public sites: OptionsModel[] = [];
  public sitesAndCycles: OptionsModel[] = [];
  public externalProviders: OptionsModel[] = [];

  constructor() {
    makeObservable(this, {
      providers: observable,
      sites: observable,
      externalProviders: observable,
      setProviders: action,
      setSites: action,
      setExternalProviders: action,
      clear: action,
    });
  }

  public setProviders(providers: OptionsModel[]): void {
    this.providers = providers;
  }

  public setSites(sites: OptionsModel[]): void {
    this.sites = sites;
  }

  public setSitesAndCycles(sitesAndCycles: OptionsModel[]): void {
    this.sitesAndCycles = sitesAndCycles;
  }

  public setExternalProviders(externalProviders: OptionsModel[]): void {
    this.externalProviders = externalProviders;
  }

  public clear(): void {
    this.providers = [];
    this.sites = [];
    this.sitesAndCycles = [];
    this.externalProviders = [];
  }
}

export default CommonStore;
