import { map, Observable } from 'rxjs';
import { GridSortModel } from '@mui/x-data-grid';
import { OptionsModel, SiteModel, SitePaginationModel, UserModel } from '../Models';
import { IAPIResponse, IOptions, ISite, ISitePagination, IUser } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { Sort } from '../Enums';

class SiteService {
  public getAllSites(
    pageNumber: number,
    pageSize: number,
    sortModel: GridSortModel,
    query: string,
    status: number
  ): Observable<SitePaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      PageNumber: pageNumber,
      PageSize: pageSize,
      Sort: sortModel[0]?.field || 'name',
      Dir: Sort[sortModel[0]?.sort] || Sort.asc,
      Query: query,
      Status: status >= 0 ? status : null,
    });
    return http
      .get<IAPIResponse<ISitePagination>>(`${ApiUrl.sites}?${params}`)
      .pipe(map((response: IAPIResponse<ISitePagination>) => SitePaginationModel.deserialize(response.Data)));
  }

  public addSite(req): Observable<SiteModel> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<ISite>>(ApiUrl.sites, req)
      .pipe(map((response: IAPIResponse<ISite>) => SiteModel.deserialize(response.Data)));
  }

  public deleteSite(siteGuid: string): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.sites}/${siteGuid}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public getSiteById(id: string): Observable<SiteModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<ISite>>(`${ApiUrl.sites}/${id}`)
      .pipe(map((response: IAPIResponse<ISite>) => SiteModel.deserialize(response.Data)));
  }

  public updateSite(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.sites, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public addSiteUser(req): Observable<UserModel> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<IUser>>(ApiUrl.siteUser, req)
      .pipe(map((response: IAPIResponse<IUser>) => UserModel.deserialize(response.Data)));
  }

  public deleteSiteUser(id: number): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ id });
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.siteUser}?${params}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public siteUsers(siteGuid: string): Observable<UserModel[]> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ siteGuid });
    return http
      .get<IAPIResponse<IUser[]>>(`${ApiUrl.siteUsers}?${params}`)
      .pipe(map((response: IAPIResponse<IUser[]>) => UserModel.deserializeList(response.Data)));
  }

  public getSiteUserById(id: number): Observable<UserModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ id });
    return http
      .get<IAPIResponse<IUser>>(`${ApiUrl.siteUser}?${params}`)
      .pipe(map((response: IAPIResponse<IUser>) => UserModel.deserialize(response.Data)));
  }

  public updateSiteUser(req): Observable<UserModel> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<IUser>>(ApiUrl.siteUser, req)
      .pipe(map((response: IAPIResponse<IUser>) => UserModel.deserialize(response.Data)));
  }

  public addSiteChildren(file): Observable<string> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<string>>(ApiUrl.importSiteChildren, file)
      .pipe(map((response: IAPIResponse<string>) => response.Data));
  }

  public siteAdminsAndTeachers(id: number): Observable<OptionsModel[]> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IOptions[]>>(`${ApiUrl.siteAdminsAndTeachers}/${id}`)
      .pipe(map((response: IAPIResponse<IOptions[]>) => OptionsModel.deserializeList(response.Data)));
  }

  public mergedDemographics(file): Observable<string> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<string>>(ApiUrl.mergedDemographics, file)
      .pipe(map((response: IAPIResponse<string>) => response.Data));
  }
}

export default new SiteService();
