import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.primary.light,
            border: 0,
            color: "#fff",
            padding: "20px 10px",

        },
        pagePaper: {
            display: 'block'
        },
    })
);