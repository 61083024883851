export const Fields = [
  {
    name: 'accessToken',
    type: 'string',
    placeholder: 'Enter your access token',
    label: 'Access Token',
    rules: 'required|string',
    value: '',
  },
];
