import React, { FC } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { Button } from '@mui/material';
import { IDropdownOptions } from '@interfaces';
import CustomDropDown from '../CustomDropDown/CustomDropDown';
import CustomInputField from '../CustomInputField/CustomInputField';
import { useStyles } from './UserSearch.styles';

type Props = {
  options?: IDropdownOptions[];
  showUserType?: boolean;
  onSearch?: (searchText: string, type?: number, providerId?: number) => void;
  onClear?: () => void;
  providerOptions?: IDropdownOptions[];
  provider?: boolean;
  searchPlaceholder?: string;
};

const UserSearch: FC<Props> = ({
  options,
  onSearch,
  onClear,
  providerOptions,
  provider = false,
  showUserType = false,
  searchPlaceholder,
}: Props) => {
  const classes = useStyles();
  const observable = useLocalObservable(() => ({
    searchText: '',
    type: '',
    providerId: '',
  }));

  const onSelect = (value: string | number) => {
    runInAction(() => {
      observable.type = String(value);
    });
  };

  const onProviderSelect = (value: string | number) => {
    runInAction(() => {
      observable.providerId = String(value);
    });
  };

  const handleChange = (value: string) => {
    runInAction(() => {
      observable.searchText = value;
    });
  };

  const handleSearch = () => {
    const { searchText, type, providerId } = observable;
    onSearch(searchText, Number(type), Number(providerId));
  };

  const handleClear = () => {
    runInAction(() => {
      observable.searchText = '';
      observable.type = '';
      observable.providerId = '';
    });
    onClear();
  };

  return (
    <div>
      <fieldset className={classes.mainContainer}>
        <legend>Search</legend>
        <div className={classes.stack}>
          <div className={classes.marginRight}>
            <CustomInputField
              value={observable.searchText}
              onValueChange={handleChange}
              searchPlaceholder={searchPlaceholder}
            />
          </div>
          {showUserType && (
            <div className={classes.marginRight}>
              <CustomDropDown
                value={Number(observable.type)}
                options={options}
                height={35}
                onValueChange={onSelect}
                className={classes.dropDown}
              />
            </div>
          )}
          {provider && (
            <div className={classes.marginRight}>
              <CustomDropDown
                value={Number(observable.providerId)}
                options={providerOptions}
                height={35}
                onValueChange={onProviderSelect}
                className={classes.dropDown}
              />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button variant="contained" color="primary" className={classes.searchButton} onClick={handleSearch}>
              Search
            </Button>
            <Button variant="outlined" className={classes.clearButton} onClick={handleClear}>
              Clear
            </Button>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default observer(UserSearch);
