import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  
    inputClass:{
        minWidth:'400px',
        [theme.breakpoints.between('md','lg')]: {
            minWidth: '311px',
          },
          [theme.breakpoints.only('lg')]: {
            minWidth: '320px',
          },
    }
}));
