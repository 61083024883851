import { UserRole } from '../Enums';
import { Utils } from '../Utilities';

export class AuthUserModel {
    id: string;
    roleId: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    exp?: number;
    iat?: number;
    nbf?: number;

    constructor(user?: Partial<AuthUserModel>) {
        Object.assign(this, user);
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    static deserialize(accessToken: string): AuthUserModel {
        if (!accessToken) {
            return new AuthUserModel();
        }

        const decodeToken = Utils.getDecodedAuthToken(accessToken);

        const data: Partial<AuthUserModel> = {
            id: decodeToken.Id,
            roleId: decodeToken.RoleId,
            firstName: decodeToken.FirstName,
            lastName: decodeToken.LastName,
            email: decodeToken.email,
            role: UserRole[decodeToken.Role],
            exp: decodeToken.exp,
            iat: decodeToken.iat,
            nbf: decodeToken.nbf,
        };

        return new AuthUserModel(data);
    }
}
