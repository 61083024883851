import { IChildSurvey, ISurveyQuestionOptions, ISurveyQuestions, ISurveySections } from '../Interfaces';

export class ChildSurveyModel {
  childSurveyId: number;
  childName: string = '';
  surveyName: string = '';
  ageGroupId: number;
  surveyDescription: string = '';
  version: string = '';
  hasChildConsent: boolean;
  childrenId: number;
  surveySections: SurveySectionsModel[] = [];

  constructor(data?: Partial<ChildSurveyModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IChildSurvey): ChildSurveyModel {
    if (!apiData) {
      return new ChildSurveyModel();
    }

    const data: Partial<ChildSurveyModel> = {
      childSurveyId: apiData.ChildSurveyId,
      childName: apiData.ChildName,
      surveyName: apiData.SurveyName,
      ageGroupId: apiData.AgeGroupId,
      surveyDescription: apiData.SurveyDescription,
      version: apiData.Version,
      hasChildConsent: apiData.HasChildConsent,
      childrenId: apiData.ChildrenId,
      surveySections: SurveySectionsModel.deserializeList(apiData.SurveySections),
    };

    return new ChildSurveyModel(data);
  }

  static deserializeList(surveyList: IChildSurvey[]): ChildSurveyModel[] {
    return surveyList
      ? surveyList.map((apiData: IChildSurvey) => new ChildSurveyModel(ChildSurveyModel.deserialize(apiData)))
      : [];
  }
}

export class SurveySectionsModel {
  surveySectionId: number;
  name: string;
  description: string = '';
  subTitle: string;
  orderIndex: number;
  surveyQuestions: SurveyQuestionsModel[];
  requiredIds?: number[];

  constructor(data?: Partial<SurveySectionsModel>) {
    let ids = [];
    data?.surveyQuestions?.forEach(x => {
      if (x.isRequired) {
        const isOptionSelected = x.surveyQuestionOptions?.some(option => option.isSelected);
        if (isOptionSelected) {
          ids = [...ids, x.questionId];
        }
      }
    });
    Object.assign(this, {
      ...data,
      requiredIds: ids,
    });
  }

  static deserialize(apiData: ISurveySections): SurveySectionsModel {
    if (!apiData) {
      return new SurveySectionsModel();
    }

    const data: Partial<SurveySectionsModel> = {
      surveySectionId: apiData.SurveySectionId,
      name: apiData.Name,
      description: apiData.Description,
      subTitle: apiData.SubTitle,
      orderIndex: apiData.OrderIndex,
      surveyQuestions: SurveyQuestionsModel.deserializeList(apiData.SurveyQuestions),
    };

    return new SurveySectionsModel(data);
  }

  static deserializeList(sectionList: ISurveySections[]): SurveySectionsModel[] {
    return sectionList
      ? sectionList.map((apiData: ISurveySections) => new SurveySectionsModel(SurveySectionsModel.deserialize(apiData)))
      : [];
  }
}

export class SurveyQuestionsModel {
  questionId: number;
  controlTypeId: number;
  title: string;
  isRequired: boolean;
  orderIndex: number;
  subSectionId: number;
  surveyQuestionOptions: SurveyQuestionOptionsModel[];

  constructor(data?: Partial<SurveyQuestionsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ISurveyQuestions): SurveyQuestionsModel {
    if (!apiData) {
      return new SurveyQuestionsModel();
    }

    const data: Partial<SurveyQuestionsModel> = {
      questionId: apiData.QuestionId,
      controlTypeId: apiData.ControlTypeId,
      title: apiData.Title,
      isRequired: apiData.IsRequired,
      orderIndex: apiData.OrderIndex,
      subSectionId: apiData.SubSectionId,
      surveyQuestionOptions: SurveyQuestionOptionsModel.deserializeList(apiData.SurveyQuestionOptions),
    };

    return new SurveyQuestionsModel(data);
  }

  static deserializeList(questionsList: ISurveyQuestions[]): SurveyQuestionsModel[] {
    return questionsList
      ? questionsList.map(
          (apiData: ISurveyQuestions) => new SurveyQuestionsModel(SurveyQuestionsModel.deserialize(apiData))
        )
      : [];
  }
}

export class SurveyQuestionOptionsModel {
  questionOptionId: number;
  optionText: string;
  optionValue: string;
  orderIndex: number;
  isSelected: boolean;

  constructor(data?: Partial<SurveyQuestionOptionsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ISurveyQuestionOptions): SurveyQuestionOptionsModel {
    if (!apiData) {
      return new SurveyQuestionOptionsModel();
    }

    const data: Partial<SurveyQuestionOptionsModel> = {
      questionOptionId: apiData.QuestionOptionId,
      optionText: apiData.OptionText,
      optionValue: apiData.OptionValue,
      orderIndex: apiData.OrderIndex,
      isSelected: apiData.IsSelected,
    };

    return new SurveyQuestionOptionsModel(data);
  }

  static deserializeList(optionsList: ISurveyQuestionOptions[]): SurveyQuestionOptionsModel[] {
    return optionsList
      ? optionsList.map(
          (apiData: ISurveyQuestionOptions) =>
            new SurveyQuestionOptionsModel(SurveyQuestionOptionsModel.deserialize(apiData))
        )
      : [];
  }
}
