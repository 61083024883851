export const Fields = [
    {
        name: 'firstName',
        type: 'string',
        placeholder: 'First Name',
        label: 'First Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'lastName',
        type: 'string',
        placeholder: 'Last Name',
        label: 'Last Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'email',
        type: 'string',
        placeholder: 'Email Address',
        label: 'Email',
        rules: 'required|string|email',
        value: '',
    },
    {
        name: 'phoneNumber',
        type: 'string',
        label: 'Phone Number',
        placeholder: 'Phone Number',
        rules: 'required|min:10|max:10',
        value: '',
    },
    {
        name: "type",
        label: "User Role",
        placeholder: 'User Role',
        rules: "required",
        value: '',
    }
];

export const UserRoleOptions = [
    { label: 'ECO Admin', value: 3 },
    { label: 'Coach', value: 4 },
    { label: 'Coordinator', value: 5 },
];
