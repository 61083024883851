export const ApiUrl = {
  login: 'v1/login',
  familyLogin: 'v1/login/family',
  users: 'v1/users',
  userProfile: 'v1/users/profile',
  sendEmail: 'v1/users/recovery',
  validateRecoveryToken: 'v1/users/recovery/token',
  resetPassword: 'v1/users/recovery/password',
  agencies: 'v1/agencies',
  providers: 'v1/providers',
  agencyOptions: 'v1/common/agencies',
  ecos: 'v1/ecos',
  ecoUser: 'v1/ecouser',
  ecoUsers: 'v1/ecousers',
  providerOptions: 'v1/common/providers',
  families: 'v1/families',
  familyAccessToken: 'v1/families/accesstoken',
  children: 'v1/children',
  familyChildren: 'v1/familychildren',
  familyOptions: 'v1/common/families',
  verifyConsent: 'v1/users/verifyconsent',
  consent: 'v1/users/consent',
  sites: 'v1/sites',
  siteOptions: 'v1/common/sites',
  siteAndCycleOptions: 'v1/ecositemapping/siteswithcycles',
  externalProviders: 'v1/common/externalproviders',
  siteUser: 'v1/siteuser',
  siteUsers: 'v1/siteusers',
  sitesMapping: 'v1/ecositemapping/sites',
  mapSitesToEco: 'v1/ecositemapping/mapsitestoeco',
  ecosMapping: 'v1/ecositemapping/ecos',
  mapEcosToSite: 'v1/ecositemapping/mapecostosite',
  cycle: 'v1/ecositemapping/cycle',
  childSurvey: 'v1/child/survey',
  childSurveyReport: 'v1/child/survey/report',
  childProgress: 'v1/child/childprogress',
  retakeChildSurvey: 'v1/child/childsurveyretake',
  childTipSheets: 'v1/child/tipsheets',
  publicChildSurvey: 'v1/child/survey/public',
  ecoSendSurveyRequest: 'v1/child/ecosendsurveyrequest',
  surveySectionNote: 'v1/child/surveysectionnote',
  reportStatus: 'v1/child/childsurveyreportstatus',
  referChild: 'v1/child/referchild',
  teachers: 'v1/teachers',
  teachersPagination: 'v1/teachers/paginated',
  siteChildren: 'v1/sitechildren',
  childAndTeachers: 'v1/children/childandteachers',
  allocateTeacher: 'v1/children/allocateteacher',
  childConsent: 'v1/children/consent',
  importSiteChildren: 'v1/sites/children',
  studentsToSendSurvey: 'v1/ecositemapping/studentstosendsurvey',
  activeCycle: 'v1/ecositemapping/activecycle',
  getCycleByCycleId: 'v1/ecositemapping/cycle',
  siteAdminsAndTeachers: 'v1/sites/siteadminsandteachers',
  changePassword: 'v1/users/update/password',
  reportingDashboardYears: 'v1/reportdashboard/year',
  dashboardReport: 'v1/reportdashboard',
  registerFamily: '/v1/families/registerfamily',
  deleteECOSurvey: 'v1/survey/deleteecosurvey',
  mergedDemographics: 'v1/sites/mergedDemographics'
};
