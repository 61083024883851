export const Fields = [
    {
        name: 'firstName',
        type: 'string',
        label: 'First Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'lastName',
        type: 'string',
        label: 'Last Name',
        rules: 'required|string',
        value: '',
    },
    {
        name: 'birthDate',
        type: 'string',
        placeholder: 'Birth Date',
        label: 'Birth Date',
        rules: 'required',
        value: '',
    },
    {
        name: 'weekPremature',
        type: 'string',
        label: 'Weeks Premature',
        placeholder: 'Weeks Premature',
        rules: 'required',
        value: '',
    },
];
