import { CircularProgress } from "@mui/material";
import { useStyles } from "./Overlay.styles";

const Overlay = () => {
    const classes = useStyles()
  return (
    <div className={classes.overlay}>
         <CircularProgress size={60} />
    </div>
  )
}

export default Overlay;
