import { IECOPagination } from '../Interfaces';
import { ECOModel } from './ECO.model';
import { PageModel } from './Page.model';

export class ECOPaginationModel extends PageModel {
    results: ECOModel[];

    constructor(data?: Partial<ECOPaginationModel>) {
        super();
        Object.assign(this, data);
    }

    static deserialize(apiData: IECOPagination): ECOPaginationModel {
        if (!apiData) {
            return new ECOPaginationModel();
        }

        const data: Partial<ECOPaginationModel> = {
            pageNumber: apiData.PageNumber,
            pageSize: apiData.PageSize,
            totalNumberOfRecords: apiData.TotalNumberOfRecords,
            totalPages: apiData.TotalPages,
            results: ECOModel.deserializeList(apiData.Results),
        };

        return new ECOPaginationModel(data);
    }
}
