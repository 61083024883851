import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './DeleteDialog.styles';

type Props = {
  open?: boolean;
  content?: string;
  btnText?: string;
  handleClose: () => void;
  handleDelete: () => void;
};

const DeleteDialog: FC<Props> = ({ open, content, btnText, handleClose, handleDelete }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.mainDialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton aria-label="close" onClick={handleClose} className={classes.iconBtn}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Typography className={classes.heading}>{content}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button onClick={handleClose} variant="contained" className={classes.cancelButton}>
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="contained" className={btnText ? '' : classes.removeButton}>
          {btnText || 'Remove'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
