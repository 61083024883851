import React, { FC, useEffect } from 'react';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Typography, Button } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  Actions,
  CustomDataGrid,
  CustomDialog,
  CustomDropDown,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  useNotification,
  UserSearch,
} from '@shared';
import { UIStore, TeacherStore } from '@stores';
import { CommonService, TeacherService } from '@services';
import { OptionsModel, TeacherModel, TeacherPaginationModel } from '@models';
import { useStyles } from './Teacher.styles';
import { Fields } from './Fields';
import { CrossIcon, EditIcon } from '@assets';

type Props = {
  teacherStore?: TeacherStore;
};

const form: MobxReactForm = getFormValidation(Fields, null);

const Teacher: FC<Props> = ({ teacherStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const observable = useLocalObservable(() => ({
    teacher: new TeacherModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    totalNumberOfRecords: 0,
    sortModel: [],
    siteList: [],
    siteId: 0,
  }));
  const columns = [
    { field: 'firstName', headerName: 'First Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lastName', headerName: 'Last Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
    },
    { field: 'siteName', headerName: 'Site', flex: 1, headerClassName: 'super-app-theme--header', sortable: false },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    getSiteOptions();
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isEdit = false;
      observable.isDelete = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getTeacherList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getTeacherList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
    });
    getTeacherList();
  };

  const getSiteOptions = () => {
    UIStore.showLoader();
    CommonService.getSiteOptions().subscribe({
      next: (response: OptionsModel[]) => {
        runInAction(() => {
          observable.siteList = response;
        });
        getTeacherList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const getTeacherList = () => {
    const { pageNumber, pageSize, sortModel, query, siteId } = observable;
    UIStore.showLoader();
    TeacherService.getAllTeachers(pageNumber, pageSize, sortModel, query, siteId).subscribe({
      next: (response: TeacherPaginationModel) => {
        teacherStore.setTeacherList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const getTeacherById = () => {
    UIStore.showLoader();
    TeacherService.getTeacherById(observable.teacher?.id).subscribe({
      next: (response: TeacherModel) => {
        const { firstName, lastName, email, phoneNumber, siteId } = response;
        form.$('firstName').set(firstName);
        form.$('lastName').set(lastName);
        form.$('email').set(email);
        form.$('phoneNumber').set(phoneNumber || '');
        form.$('siteId').set(siteId);
        runInAction(() => {
          observable.isEdit = true;
          observable.isOpen = true;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onAction = (teacher: TeacherModel, isEdit = false) => {
    runInAction(() => {
      observable.teacher = teacher;
      if (isEdit) {
        getTeacherById();
        return;
      }
      observable.isDelete = true;
    });
  };

  const onSearch = (searchText: string, type: number, siteId: number) => {
    runInAction(() => {
      observable.query = searchText;
      observable.siteId = siteId;
      observable.pageNumber = 1;
    });
    getTeacherList();
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
      observable.siteId = 0;
    });
    getTeacherList();
  };

  const deleteTeacher = () => {
    UIStore.showLoader();
    TeacherService.deleteTeacher(observable.teacher?.id).subscribe({
      next: (response: boolean) => {
        handleClose();
        getTeacherList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const editTeacher = () => {
    const { firstName, lastName, email, phoneNumber } = form.values();
    const { id } = observable.teacher;
    const request = { firstName, lastName, email, phoneNumber, id };
    UIStore.showLoader();
    TeacherService.updateTeacher(request).subscribe({
      next: (response: boolean) => {
        handleClose();
        getTeacherList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const addTeacher = () => {
    const { firstName, lastName, email, phoneNumber, siteId } = form.values();
    const request = { firstName, lastName, email, phoneNumber, siteId };
    UIStore.showLoader();
    TeacherService.addTeacher(request).subscribe({
      next: (response: number) => {
        handleClose();
        getTeacherList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    form.submit({
      onSuccess: () => {
        if (observable.isEdit) {
          editTeacher();
          return;
        }
        addTeacher();
      },
      onError: error => {},
    });
  };

  const renderActions = params => {
    return (
      <>
        <Actions btnText="Edit" onClick={() => onAction(params.row, true)} imgSrc={EditIcon} />
        <Actions btnText="Delete" onClick={() => onAction(params.row)} imgSrc={CrossIcon} />
      </>
    );
  };

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Teachers
      </Typography>
      <UserSearch
        searchPlaceholder="Enter name or email to search teacher"
        onSearch={onSearch}
        onClear={onClear}
        provider={true}
        providerOptions={[{ value: 0, label: 'All Site' }, ...observable.siteList]}
      />
      <Box component="div" display="flex" flexDirection="column">
        <Button
          className={classes.button}
          onClick={() =>
            runInAction(() => {
              observable.isOpen = true;
            })
          }
        >
          <AddCircleIcon className={classes.icon} />
          <Typography variant="body1" className={classes.text}>
            ADD TEACHER
          </Typography>
        </Button>
        <CustomDataGrid
          columns={columns}
          data={teacherStore.teacherList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
        />
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit Teacher' : 'Add Teacher'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('firstName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'firstName')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('lastName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'lastName')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('email')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'email')}
            showLabel={true}
            form={form}
          />
          <FormInputControl
            field={form.$('phoneNumber')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'phoneNumber')}
            showLabel={true}
            form={form}
          />
          {!observable.isEdit && (
            <CustomDropDown
              value={form.$('siteId').value}
              field={form.$('siteId')}
              options={observable.siteList}
              className={classes.selectBox}
              placeholder={form.$('siteId').label}
              onValueChange={value => handleChange(value, 'siteId')}
              form={form}
            />
          )}
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this teacher?"
          handleClose={handleClose}
          handleDelete={deleteTeacher}
        />
      )}
    </div>
  );
};

export default inject('teacherStore')(observer(Teacher));
