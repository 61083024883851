import React, { FC, useEffect } from 'react';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Typography, Button } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  Actions,
  CustomDataGrid,
  CustomDialog,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  useNotification,
  UserSearch,
} from '@shared';
import { UIStore, AgencyStore } from '@stores';
import { AgencyService } from '@services';
import { AgencyModel, AgencyPaginationModel } from '@models';
import { useStyles } from './Agency.styles';
import { UserFields } from './UserFields';
import { CrossIcon, EditIcon } from '@assets';

type Props = {
  agencyStore?: AgencyStore;
};

const form: MobxReactForm = getFormValidation(UserFields, null);

const Agency: FC<Props> = ({ agencyStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const observable = useLocalObservable(() => ({
    agency: new AgencyModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    totalNumberOfRecords: 0,
    sortModel: [],
  }));
  const columns = [
    { field: 'name', headerName: 'Agency Name', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'firstName',
      headerName: 'Admin First Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: params => params.row?.agencyAdmin?.firstName,
    },
    {
      field: 'lastName',
      headerName: 'Admin Last Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: params => params.row?.agencyAdmin?.lastName,
    },
    {
      field: 'email',
      headerName: 'Admin Email',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: params => params.row?.agencyAdmin?.email,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    getAgencyList();
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isEdit = false;
      observable.isDelete = false;
      form.$submitted = 0;
    });
    form.reset();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getAgencyList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getAgencyList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
    });
    getAgencyList();
  };

  const getAgencyList = () => {
    const { pageNumber, pageSize, sortModel, query } = observable;
    UIStore.showLoader();
    AgencyService.getAllAgency(pageNumber, pageSize, sortModel, query).subscribe({
      next: (response: AgencyPaginationModel) => {
        agencyStore.setAgencyList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    if (observable.isEdit) {
      editAgency();
      return;
    }
    addAgency();
  };

  const addAgency = () => {
    const { name, firstName, lastName, email } = form.values();
    const request = { name, firstName, lastName, email };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        AgencyService.addAgency(request).subscribe({
          next: (response: number) => {
            handleClose();
            getAgencyList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const editAgency = () => {
    const { name, firstName, lastName, email } = form.values();
    const { guid } = observable.agency;
    const request = { name, firstName, lastName, email, agencyGuid: guid };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        AgencyService.updateAgency(request).subscribe({
          next: response => {
            handleClose();
            getAgencyList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const onUserAction = (agency: AgencyModel, isEdit = false) => {
    runInAction(() => {
      observable.agency = agency;
      if (isEdit) {
        const { name, agencyAdmin } = observable.agency;
        form.$('name').set(name);
        form.$('firstName').set(agencyAdmin?.firstName);
        form.$('lastName').set(agencyAdmin?.lastName);
        form.$('email').set(agencyAdmin?.email);
        observable.isEdit = isEdit;
        observable.isOpen = true;
        return;
      }
      observable.isDelete = true;
    });
  };

  const deleteAgency = () => {
    UIStore.showLoader();
    AgencyService.deleteAgency(observable.agency?.guid).subscribe({
      next: (response: boolean) => {
        handleClose();
        getAgencyList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSearch = (searchText: string) => {
    runInAction(() => {
      observable.query = searchText;
      observable.pageNumber = 1;
    });
    getAgencyList();
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
    });
    getAgencyList();
  };

  const renderActions = params => {
    return (
      <>
        <div style={{ width: 70 }}>
          <Actions btnText="Edit" onClick={() => onUserAction(params.row, true)} imgSrc={EditIcon} />
        </div>
        <div style={{ width: 80 }}>
          {!params.row.isMasterAgency && (
            <Actions btnText="Delete" onClick={() => onUserAction(params.row)} imgSrc={CrossIcon} />
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Agency List
      </Typography>
      <UserSearch searchPlaceholder="Enter name or email to search agency" onSearch={onSearch} onClear={onClear} />
      <Box component="div" display="flex" flexDirection="column">
        <Button
          className={classes.button}
          onClick={() =>
            runInAction(() => {
              observable.isOpen = true;
            })
          }
        >
          <AddCircleIcon className={classes.icon} />
          <Typography variant="body1" className={classes.text}>
            ADD AGENCY
          </Typography>
        </Button>
        <CustomDataGrid
          columns={columns}
          data={agencyStore.agencyList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
        />
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit Agency' : 'Add Agency'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('name')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'name')}
            width={400}
            form={form}
          />
          <FormInputControl
            field={form.$('firstName')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'firstName')}
            width={400}
            form={form}
          />
          <FormInputControl
            field={form.$('lastName')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'lastName')}
            width={400}
            form={form}
          />
          <FormInputControl
            field={form.$('email')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'email')}
            width={400}
            form={form}
          />
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this agency?"
          handleClose={handleClose}
          handleDelete={deleteAgency}
        />
      )}
    </div>
  );
};

export default inject('agencyStore')(observer(Agency));
