import { action, makeObservable, observable } from 'mobx';
import { FamilyModel, OptionsModel } from '../Models';

class FamilyStore {
  public familyList: FamilyModel[] = [];
  public family: FamilyModel = new FamilyModel();
  public providers: OptionsModel[] = [];

  constructor() {
    makeObservable(this, {
      familyList: observable,
      family: observable,
      providers: observable,
      setFamilyList: action,
      setFamily: action,
      setProviders: action,
      clear: action,
    });
  }

  public setFamilyList(families: FamilyModel[]): void {
    this.familyList = families;
  }

  public setFamily(family: FamilyModel): void {
    this.family = family;
  }

  public setProviders(providers: OptionsModel[]): void {
    this.providers = providers;
  }

  public clear(): void {
    this.familyList = [];
    this.providers = [];
    this.family = new FamilyModel();
  }
}

export default FamilyStore;
