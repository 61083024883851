import React, { FC, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { useNavigate, useParams } from 'react-router';
import { Typography, Box, Stepper, Step, StepLabel } from '@mui/material';
import { StepOne, StepTwo } from './Steps';
import { useStyles } from './AddECO.styles';

const steps = ['Organization Information', 'Users Information'];

const AddEco: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const observable = useLocalObservable(() => ({
    activeStep: 0,
  }));

  useEffect(() => {
    runInAction(() => {
      observable.activeStep = 0;
    });
  }, [!params?.id]);

  const handleNext = (step: number) => {
    runInAction(() => {
      observable.activeStep = step;
    });
  };

  const renderSteps = () => {
    if (observable.activeStep === 0) {
      return <StepOne onNext={() => handleNext(1)} onCancel={() => navigate(-1)} />;
    }

    if (observable.activeStep === 1) {
      return <StepTwo onNext={() => navigate(-1)} onBack={() => handleNext(0)} />;
    }
  };

  return (
    <Box sx={{ width: '100%' }} className={classes.outerContainer}>
      <Typography className={classes.heading} variant="h4">
        {params?.id ? 'Edit ECO' : 'Add ECO'}
      </Typography>
      <Stepper activeStep={observable.activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box className={classes.innerContainer}>{renderSteps()}</Box>
    </Box>
  );
};

export default observer(AddEco);
