import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
    margin: '10px 0px',
  },
  icon: {
    color: theme.palette.info.main,
  },
  text: {
    padding: 2,
    fontWeight: 'bold',
    color: theme.palette.info.main,
  },
  list: {
    width: '100%',
    minWidth: 450,
    bgcolor: 'background.paper',
  },
  listText: {
    '& .MuiTypography-root': {
      fontSize: 16,
    },
  },
}));
