import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainDialog: {
    '&.MuiDialog-root': {
      margin: '0 auto',
    },
    '& .MuiDialog-paper': {
      maxWidth: '1000px',
      color: theme.palette.common.black,
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        minWidth: '500px',
      },
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[600],
    border: '1px solid',
    borderColor: theme.palette.grey[600],
    '&:hover': {
      background: theme.palette.grey[600],
      color: theme.palette.common.white,
    },
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      color: theme.palette.common.white,
    },
  },
  dialogAction: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },
  dialogTitle: {
    padding: '25px 40px 30px',
  },
  dialogContent: {
    padding: '35px 15px',
    textAlign: 'center',
  },
  heading: {
    color: theme.palette.primary[300],
    marginBottom: '20px',
  },
  iconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'black',
  },
}));
