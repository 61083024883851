import React, { FC, useEffect } from 'react';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Typography } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  Actions,
  ChildReportStatus,
  CustomDataGrid,
  CustomDatePicker,
  CustomDialog,
  DeleteDialog,
  FormInputControl,
  getFormValidation,
  ParentEntityType,
  Sort,
  useNotification,
  UserSearch,
  Utils,
} from '@shared';
import { UIStore, ChildrenStore, CommonStore } from '@stores';
import { ChildrenService, CommonService } from '@services';
import { ChildrenModel, ChildrenPaginationModel, OptionsModel } from '@models';
import { Fields } from './Fields';
import { useStyles } from './Children.styles';
import { CrossIcon, EditIcon, VisibilityIcon } from '@assets';
import { useNavigate } from 'react-router';

type Props = {
  childrenStore?: ChildrenStore;
  commonStore?: CommonStore;
};

const form: MobxReactForm = getFormValidation(Fields, null);

const Children: FC<Props> = ({ childrenStore, commonStore }: Props) => {
  const notification = useNotification();
  const navigate = useNavigate();
  const classes = useStyles();

  const observable = useLocalObservable(() => ({
    child: new ChildrenModel(),
    isDelete: false,
    isOpen: false,
    isEdit: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    totalNumberOfRecords: 0,
    sort: 'caregiverOrSiteName',
    dir: Sort.asc,
    sortModel: [],
    providerId: 0,
    reviewedStatus: null,
  }));
  const columns = [
    {
      field: 'caregiverOrSiteName',
      headerName: 'Caregiver/ Site Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    { field: 'providerName', headerName: 'Provider Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'childName', headerName: 'Child Name', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'weekPremature',
      headerName: 'Weeks Premature',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
    },
    {
      field: 'birthDate',
      headerName: 'Birth Date',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },
    {
      field: 'surveyCompleted',
      headerName: 'Survey Completed',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: params => Utils.formattedDate(params.value, 'MM/DD/YYYY'),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => renderActions(params),
    }
  ];

  useEffect(() => {
    getProviderList();
  }, []);

  const getProviderList = () => {
    UIStore.showLoader();
    CommonService.getProviderOptions().subscribe({
      next: (response: OptionsModel[]) => {
        commonStore.setProviders(response);
        getChildrenList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isEdit = false;
    });
    form.reset();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getChildrenList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getChildrenList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
      observable.sort = newSortModel[0]?.field;
      observable.dir = Sort[newSortModel[0]?.sort];
    });
    getChildrenList();
  };

  const handleDeleteClose = () => {
    runInAction(() => {
      observable.isDelete = false;
    });
  };

  const getChildrenList = () => {
    const { pageNumber, pageSize, sort, dir, query, providerId, reviewedStatus } = observable;
    UIStore.showLoader();
    ChildrenService.getChildrenList(pageNumber, pageSize, sort, dir, query, providerId, reviewedStatus).subscribe({
      next: (response: ChildrenPaginationModel) => {        
        childrenStore.setChildrenList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onUserAction = (child: ChildrenModel, isEdit = false) => {
    runInAction(() => {
      observable.child = child;
      if (isEdit) {
        getChildById();
        return;
      }
      observable.isDelete = true;
    });
  };

  
  const getChildById = () => {
    UIStore.showLoader();
    ChildrenService.getChildById(observable.child?.id).subscribe({
      next: (response: ChildrenModel) => {
        const { firstName, lastName, birthDate, weekPremature } = response;
        form.$('firstName').set(firstName);
        form.$('lastName').set(lastName);
        form.$('weekPremature').set(weekPremature);
        form.$('birthDate').set(birthDate);
        runInAction(() => {
          observable.isEdit = true;
          observable.isOpen = true;
          observable.child = response;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    if (observable.isEdit) {
      editChild();
      return;
    }
    addChild();
  };

  const onSearch = (searchText: string, status: number, providerId: number) => {
    runInAction(() => {
      observable.query = searchText;
      observable.providerId = providerId;
      observable.reviewedStatus = Boolean(status) ? status - 1 : null;
      observable.pageNumber = 1;
    });
    getChildrenList();
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
      observable.providerId = 0;
    });
    getChildrenList();
  };

  const editChild = () => {
    const { firstName, lastName, birthDate, weekPremature } = form.values();
    const { id } = observable.child;
    const request = {
      id,
      firstName,
      lastName,
      birthDate: Utils.formattedDate(birthDate, 'YYYY-MM-DD'),
      weekPremature: Number(weekPremature),
      parentEntityId: observable.child?.parentEntityId,
      parentEntityType: observable.child?.parentEntityType
    };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildrenService.updateChild(request).subscribe({
          next: (response: boolean) => {
            handleClose();
            getChildrenList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {notification.error(error?.message);},
    });
  };

  const addChild = () => {
    const { firstName, lastName, birthDate, weekPremature, parentEntityId } = form.values();
    const request = {
      firstName,
      lastName,
      birthDate: Utils.formattedDate(birthDate, 'YYYY-MM-DD'),
      weekPremature: Number(weekPremature),
      parentEntityId,
      parentEntityType: ParentEntityType.FAMILY,
    };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        ChildrenService.addChild(request).subscribe({
          next: (response: number) => {
            handleClose();
            getChildrenList();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const deleteChild = () => {
    UIStore.showLoader();
    ChildrenService.deleteChild(observable.child?.id).subscribe({
      next: (response: boolean) => {
        handleDeleteClose();
        getChildrenList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const renderActions = params => {
    return (
      <>
        <Actions btnText="Edit" onClick={() => onUserAction(params.row, true)} imgSrc={EditIcon} />
        <Actions btnText="Delete" onClick={() => onUserAction(params.row)} imgSrc={CrossIcon} />       
        {Boolean(params?.row?.showProgress) && (
          <Actions
            btnText="Progress"
            onClick={() => navigate(`/admin/child-progress/${params?.row?.id}`)}
            imgSrc={VisibilityIcon}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Children
      </Typography>
      <Box component="div" display="flex" flexDirection="column">
        <div style={{ marginBottom: 30 }}>
          <UserSearch
            onSearch={onSearch}
            onClear={onClear}
            provider={true}
            showUserType={true}
            options={ChildReportStatus}
            providerOptions={[{ value: 0, label: 'All Provider' }, ...commonStore.providers]}
          />
        </div>
        <CustomDataGrid
          columns={columns}
          data={childrenStore.childrenList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
          styleClasses={{ gridControl: classes.grid }}
        />
      </Box>
      <CustomDialog
        open={observable.isOpen}
        title={observable.isEdit ? 'Edit Child' : 'Add Child'}
        onClose={handleClose}
        onSubmit={onSubmit}
      >
        <div>
          <FormInputControl
            field={form.$('firstName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'firstName')}
            showLabel={true}
          />
          <FormInputControl
            field={form.$('lastName')}
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'lastName')}
            showLabel={true}
          />
          <FormInputControl
            field={form.$('weekPremature')}
            type="number"
            styleClasses={{ inputControl: classes.input }}
            onValueChange={value => handleChange(value, 'weekPremature')}
            showLabel={true}
          />
          <CustomDatePicker
            field={form.$('birthDate')}
            onValueChange={value => handleChange(value, 'birthDate')}
            value={form.$('birthDate').value}
            showLabel={true}
            isBirthDate={true}
            endDate={Utils.formattedDate(String(new Date()), 'MM/DD/YYYY')}
            className={classes.dateInput}
          />
        </div>
      </CustomDialog>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this child?"
          handleClose={handleDeleteClose}
          handleDelete={deleteChild}
        />
      )}
    </div>
  );
};

export default inject('childrenStore', 'commonStore')(observer(Children));
