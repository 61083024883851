import React, { useState, useContext, FC } from 'react';
import { useDialog } from '@surinderlohat/react-dialog';
import CustomAlert from '../CustomAlert/CustomAlert';

const NotificationContext = React.createContext({
    open: (data: any) => { },
    exception: (data: any, error: any) => { },
    exceptions: [],
    setExceptions: (data: any) => { },
});

export const NotificationProvider: FC<any> = ({ children }) => {
    const [exceptions, setExceptions] = useState([]);
    const dialog = useDialog();

    const contextValue = {
        exceptions,
        setExceptions,
        open(data: any) {
            dialog.openDialog(
                <CustomAlert {...data} handleClose={() => dialog.closeDialog()} />
            )
        },
        exception(from: any, data: any) {
        },
    };

    return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};



export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }


    const successAlert = (data: { message: string }) => {
        success(data.message);
    };

    const errorAlert = (data: { message: string }) => {
        error(data.message);
    };

    const success = (message: string) => {
        context.open({ message, severity: 'success' });
    };

    const error = (message: string) => {
        context.open({ message });
    };
    return { success, error, errorAlert, successAlert };
};