import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { inject, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import MobxReactForm, { Field } from 'mobx-react-form';
import { Button, Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { FormInputControl, getFormValidation, useNotification } from '@shared';
import { UIStore, SiteStore } from '@stores';
import { SiteService } from '@services';
import { SiteModel } from '@models';
import { StepOneFields } from './StepOneFields';
import { useStyles } from './StepOne.styles';

type Props = {
  onNext?: () => void;
  onCancel?: () => void;
  siteStore?: SiteStore;
};

const form: MobxReactForm = getFormValidation(StepOneFields, null);

const StepOne: FC<Props> = ({ siteStore, onNext, onCancel }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const params = useParams();
  const { site } = siteStore;

  useEffect(() => {
    if (params?.id) {
      getSiteById();
    }
    return () => {
      clear();
    };
  }, []);

  const getSiteById = () => {
    UIStore.showLoader();
    SiteService.getSiteById(params?.id).subscribe({
      next: (response: SiteModel) => {
        siteStore.setSite(response);
        setFormValues(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  const setFormValues = (site: SiteModel) => {
    form.$('name').set(site?.name);
    form.$('phoneNumber').set(site?.phoneNumber);
    form.$('address').set(site?.address);
  };

  const clear = () => {
    form.reset();
    runInAction(() => {
      form.$submitted = 0;
    });
  };

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value, field: string) => {
    getField(field).set(value);
  };

  const addSite = req => {
    SiteService.addSite(req).subscribe({
      next: (response: SiteModel) => {
        siteStore.setSite(response);
        UIStore.hideLoader();
        onNext();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const updateSite = req => {
    SiteService.updateSite(req).subscribe({
      next: response => {
        siteStore.setSite({ ...site, ...req });
        UIStore.hideLoader();
        onNext();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    const { name, phoneNumber, address } = form.values();
    const req = { name, phoneNumber, address };
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        if (params?.id) {
          updateSite({ ...req, siteId: site?.id });
          return;
        }
        addSite(req);
      },
      onError: error => {},
    });
  };

  return (
    <Box className={classes.innerContainer}>
      <Typography>
      Please complete the site information below and continue by clicking 'Save & Move Next'
      </Typography>
      <Grid container spacing={3} marginTop="12px">
        <Grid xs={12} xsOffset={3} md={6}>
          <FormInputControl
            field={form.$('name')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'name')}
            form={form}
          />
          <FormInputControl
            field={form.$('phoneNumber')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'phoneNumber')}
            form={form}
          />
          <FormInputControl
            field={form.$('address')}
            styleClasses={{ inputControl: classes.input }}
            showLabel={true}
            onValueChange={value => handleChange(value, 'address')}
            form={form}
          />
          <Box className={classes.btnContainer}>
            {params?.id && (
              <Button className={classes.cancelBtn} onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button variant="contained" onClick={onSubmit}>
              {params?.id ? 'Update & Move Next' : 'Save & Move Next'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default inject('siteStore')(observer(StepOne));
