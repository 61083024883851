import { IECO } from '../Interfaces';
import { UserModel } from './User.model';

export class ECOModel {
    id: number;
    guid: string;
    name: string;
    ecoSiteUserMappingId: string;
    providerId: number;
    active: boolean;
    isAssociated: boolean;
    ecoUsers: UserModel[];

    constructor(data?: Partial<ECOModel>) {
        Object.assign(this, data);
    }

    static deserialize(apiData: IECO): ECOModel {
        if (!apiData) {
            return new ECOModel();
        }

        const data: Partial<ECOModel> = {
            id: apiData.Id,
            guid: apiData.EcoGuid,
            name: apiData?.Name || '',
            ecoSiteUserMappingId: apiData.ECOSiteUserMappingId,
            providerId: apiData.ProviderId,
            active: apiData.Active,
            isAssociated: apiData.IsAssociated,
            ecoUsers: UserModel.deserializeList(apiData.ECOUsers),
        };

        return new ECOModel(data);
    }

    static deserializeList(ecoList: IECO[]): ECOModel[] {
        return ecoList
            ? ecoList.map((apiData: IECO) => new ECOModel(ECOModel.deserialize(apiData)))
            : [];
    }
}
