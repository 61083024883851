import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TaskAlt as TaskAltIcon } from '@mui/icons-material';
import { Button, Card, Typography } from '@mui/material';
import { useStyles } from './ThanksPage.styles';

type Props = {};

const ThanksPage: FC<Props> = () => {
  const classes = useStyles();
  const location = useLocation();
  const slash = location.pathname.indexOf('/', 1);
  const path = location.pathname.substring(0, slash);
  const navigate = useNavigate();

  const showButton = (): boolean => {
    return location?.pathname?.includes('/caregiver/thanks') || location?.pathname?.includes('/admin/thanks');
  };

  return (
    <div className={classes.mainWrapper}>
      <Card component="div" className={classes.container} elevation={4}>
        <TaskAltIcon style={{ color: 'green', fontSize: 120 }} />
        <Typography variant="h4">Thank You!</Typography>
        <Typography variant="body1" gutterBottom>
          Your survey has been submitted successfully.
        </Typography>
        {showButton() && (
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => navigate(`${path}/children`, { replace: true })}
          >
            Go to home
          </Button>
        )}
      </Card>
    </div>
  );
};

export default ThanksPage;
