import React, { FC, Fragment, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { runInAction } from 'mobx';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { Box, Drawer, List, Typography, Divider, ListItem, ListItemButton, Button } from '@mui/material';
import { AddCircle as AddCircleIcon, Margin } from '@mui/icons-material';
import { AuthStore, ChildSurveyStore, CommonStore, UIStore } from '@stores';
import { ChildReportModel, ChildTipSheetsModel, NotesModel, OptionsModel } from '@models';
import { ChildSurveyService, CommonService } from '@services';
import {
  CustomDialog,
  CustomDropDown,
  FormInputControl,
  MultiSelect,
  ParentEntityType,
  Utils,
  useNotification,
} from '@shared';
import QuestionsResponse from '../../../Shared/Components/ChildReport/QuestionsResponse/QuestionsResponse';
import { Fields, ReferralFields, TipSheetsFields } from '../../../Shared/Components/ChildReport/Fields';
import { getFormValidation } from '../../../Shared/Components/FormValidation/FormValidation';
import { useStyles } from './PrintableSurvey.styles';

type Props = {
  childSurveyStore?: ChildSurveyStore;
  commonStore?: CommonStore;
};

const form: MobxReactForm = getFormValidation(Fields, null);
const tipSheetsForm: MobxReactForm = getFormValidation(TipSheetsFields, null);
const referralform: MobxReactForm = getFormValidation(ReferralFields, null);

const PrintableSurvey: FC<Props> = ({ childSurveyStore, commonStore }: Props) => {
  const classes = useStyles();
  const params = useParams();
  const notification = useNotification();
  const location = useLocation();
  const slash = location.pathname.indexOf('/', 1);
  const path = location.pathname.substring(0, slash);
  const navigate = useNavigate();
  const { report } = childSurveyStore;
  const { associatedTipSheets } = report;
  const observable = useLocalObservable(() => ({
    orderIndex: 0,
    sectionId: 0,
    isOpen: false,
    isModalOpen: false,
    isReferModalOpened: false,
    tipSheets: new ChildTipSheetsModel(),
    canSeeScores: true,
    showCondensed: true,
    localReport: new ChildReportModel(),
    compactPrintModel:[
      {
        mainText:'',
        subTexts:[          
        ],
        orderIndex:null
    }]
  }));

  useEffect(() => {
    getReport();
    return () => {
      childSurveyStore.clear();
      commonStore.clear();
    };
  }, []);

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const isCanSeeScores = ()=>{return observable.canSeeScores;}

  const showCondensed = ()=>{return observable.showCondensed;}
  const toggleCondensed =()=>{observable.showCondensed = !observable.showCondensed;}

  const buildCompactPrintModel = () => {
    let ecCodePage = [
      {
         q: "I have been able to laugh and see the funny side of things",
         code: [0,1,2,3]
      },
      {
         q: "I have looked forward with enjoyment to things",
         code: [0,1,2,3]
      },
      {
         q: "I have blamed myself unnecessarily when things went wrong",
         code: [3,2,1,0]
      },
      {
         q: "I have been anxious or worried for no good reason",
         code: [0,1,2,3]
      },
      {
         q : "I have felt scared or panicky for no good reason",
         code: [3,2,1,0]
      },
      {
         q: "Things have been getting on top of me",
         code: [3,2,1,0]
      },
      {
         q: "I have been so unhappy that I have had difficulty sleeping",
         code: [3,2,1,0]
      },
      {
         q: "I have felt sad or miserable",
         code: [3,2,1,0]
      },
      {
         q: "I have been so unhappy that I have been crying",
         code: [3,2,1,0]
      },
      {
         q: "The thought of harming myself has occurred to me",
         code: [3,2,1,0]
      }
    ];

    let lReport = observable.localReport;
    let r = [
      {
        mainText:'',
        subTexts:[          
        ],
        orderIndex:null
    }];
    let counter = 1;    
    
    let hasECNB = lReport.reportSections.filter(f=>f.sectionName==="EMOTIONAL CHANGES WITH A NEW BABY").length>0;

    for(let x = 0;x<lReport.reportSections.length;x++){
      let section = lReport.reportSections[x];
      switch(lReport.reportSections[x].sectionName){
        case "DEVELOPMENTAL MILESTONES":
          r[x]={mainText:section.sectionName + ' - ' + (section.subSections[0].score?.result ?? 'No Score') + ' - ' + (section.subSections[0].score?.childScore ?? ''), subTexts:[],orderIndex:section.orderIndex};
        break;
        case "BABY PEDIATRIC SYMPTOM CHECKLIST (BPSC)":
          r[x]={mainText:section.sectionName, subTexts:section.subSections.map(m=>{ return {subText:m.subSectionName + ' - ' + m.score.result + ' - ' + m.score.childScore}}),orderIndex:section.orderIndex};
        break;
        case "PRESCHOOL PEDIATRIC SYMPTOM CHECKLIST (PPSC)":
          r[x]={mainText:section.sectionName + ' - ' + section.subSections[0].score.result + ' - ' + section.subSections[0].score.childScore, subTexts:[],orderIndex:section.orderIndex};
        break;
        case "OBSERVATIONS OF SOCIAL INTERACTIONS (POSI)":
          r[x]={mainText:section.sectionName + ' - ' + section.subSections[0].score.result + ' - ' + section.subSections[0].score.childScore, subTexts:[],orderIndex:section.orderIndex};
        break;
       
        case "FAMILY QUESTIONS":
          //Tobacco Section
          let tobaccoQuestion = section.subSections[0].questions[0];
          r[r.length]={mainText:'TOBACCO USE', subTexts:[{subText:tobaccoQuestion.responses[0].responseText}],orderIndex:section.orderIndex};
          counter++;

          //Substance Abuse
          let saQuestions = section.subSections[0].questions.filter(m=>m.orderIndex>1 && m.orderIndex<5);          
          r[r.length]={mainText:'SUBSTANCE ABUSE', subTexts:saQuestions.filter(m=>m.responses[0].responseText==="Yes").map(m=>{return {subText:m.questionText + ' - ' + m.responses[0].responseText};}),orderIndex:section.orderIndex+1};
          counter++;

          //Food Insecurity
          let fiQuestions = section.subSections[0].questions.filter(m=>m.orderIndex===5);          
          r[r.length]={mainText:'FOOD INSECURITY', subTexts:fiQuestions.filter(m=>m.responses[0].responseText.toLowerCase().includes('often') || m.responses[0].responseText.toLowerCase().includes('sometimes')).map(m=>{return {subText:m.questionText + ' - ' + m.responses[0].responseText};}),orderIndex:section.orderIndex+1};
          counter++;

          // Parental Depression        
          if(!hasECNB){  
          let pdQuestions = section.subSections[0].questions.filter(m=>m.orderIndex===6 || m.orderIndex===7);          
           // Code All Values
          let pdCoded = pdQuestions.map(m=>
            {return {q:m.questionText + ' - ' + m.responses[0].responseText,v:
            m.responses[0].responseText.toLowerCase().startsWith('not') ? 0 :
            m.responses[0].responseText.toLowerCase().startsWith('several') ? 1 :
            m.responses[0].responseText.toLowerCase().startsWith('more') ? 2 : 3};
          });          
          //Sum All Values
          let pdScore = pdCoded.map(m=>m.v).reduce((accumulator, currentValue) => accumulator + currentValue, 0);; 

          r[r.length]={mainText:'PARENTAL DEPRESSION', subTexts:pdScore>=3 ? pdCoded.map(m=>{return {subText:m.q};}) : [{subText:'No Concerns'}], orderIndex:section.orderIndex+1};
          counter++;
        }

          //Domestic Abuse
          let daQuestions = section.subSections[0].questions.filter(m=>m.orderIndex===8 || m.orderIndex===9);          
          let daResult = daQuestions.filter(f=>f.responses[0].responseText.toLowerCase().startsWith('great') || f.responses[0].responseText.toLowerCase().startsWith('a lot'))
          r[r.length]={mainText:'DOMESTIC ABUSE', subTexts:daResult.length>0 ? daResult.map(m=>{return {subText: m.questionText + ' - ' + m.responses[0].responseText};}) : [{subText:'No Concerns'}],orderIndex:section.orderIndex+1};
          counter++;
        break;

        case "EMOTIONAL CHANGES WITH A NEW BABY":
            r[r.length]={mainText:section.sectionName + ' - ' + section.subSections[0].score.result + ' - ' + section.subSections[0].score.childScore, 
            subTexts:(section.subSections[0].score.childScore <= section.subSections[0].score.cutScore ? [{subText:'No Concerns'}] : section.subSections[0].questions.map(m=>{return {subText: m.questionText + ' - ' + m.responses[0].responseText};})),
            orderIndex:99};        
          
        break;
        case "CAREGIVER CONCERNS":          
          let cgResults = section.subSections[0].questions.filter(f=>f.responses[0].responseText !== 'Not At All').map(m=> {return {subText:m.questionText + ' - ' + m.responses[0].responseText}});          
          r[x]={
            mainText:section.sectionName, 
            subTexts:cgResults.length===0 ? [{subText:'No Concerns'}] : cgResults,
            orderIndex:section.orderIndex
          };            
        break;
      }
      counter++;
    }
    
    observable.compactPrintModel = r;    
  }

  /*const handleChange = (value: any, field: string, form?: string): void => {
    getField(field).set(value);
  };

  const getTipSheetsFormField = (key: string): Field => {
    return tipSheetsForm.$(key);
  };

  const handleTipSheetsFormChange = (value: any | any[], field: string): Field => {
    getTipSheetsFormField(field).set(value);
  };

  const getReferralField = (key: string): Field => {
    return referralform.$(key);
  };

  const handleReferralChange = (value: any, field: string, form?: string): void => {
    getReferralField(field).set(value);
  };*/

  const handleClose = () => {
    runInAction(() => {
      observable.isOpen = false;
      observable.isModalOpen = false;
      observable.isReferModalOpened = false;
      observable.sectionId = 0;
      form.$submitted = 0;
      tipSheetsForm.$submitted = 0;
      referralform.$submitted = 0;
    });
    form.reset();
    tipSheetsForm.reset();
    referralform.reset();
  };

  const getLastIndex = (): number => {
    return associatedTipSheets?.behaviourTipSheets?.length - 1;
  };

  const getReport = () => {
    UIStore.showLoader();
    ChildSurveyService.getChildReport(params?.id).subscribe({
      next: (response: ChildReportModel) => {
        const reportSection = response?.reportSections[0];        
        childSurveyStore.setReport(response);
        observable.localReport = response;
        buildCompactPrintModel();
        runInAction(() => {
          observable.orderIndex = reportSection.orderIndex;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };
  
  const openModal = (sectionId: number) => {
    runInAction(() => {
      observable.sectionId = sectionId;
      observable.isOpen = true;
    });
  };

  const getTipSheets = () => {
    UIStore.showLoader();
    ChildSurveyService.getTipSheets().subscribe({
      next: (response: ChildTipSheetsModel) => {
        runInAction(() => {
          observable.tipSheets = response;
          observable.isModalOpen = true;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

       
  const renderHeader = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
      <Box component="div" display="flex" flexDirection="column">
        <Typography variant="body1" fontWeight="bold" sx={{ fontSize: '18px' }}>
          {`SWYC ${report?.surveyName}`}
        </Typography>
        <Typography>
          <span className={classes.heading}>Family Profile: </span>
          {report?.parentEntityFirstName + ' ' + report?.parentEntityLastName}
        </Typography>
        <Typography>
          <span className={classes.heading}>Child: </span>
          {report?.childFirstName + ' ' + report?.childLastName}
        </Typography>
        <Typography>
          <span className={classes.heading}>Birth Date: </span>
          {Utils.formattedDate(report?.childBirthDate, 'MM/DD/YYYY')}
        </Typography>
        <Typography>
          <span className={classes.heading}>Age In Months: </span>
          {report?.ageInMonths}
        </Typography>        
      </Box>
      {report?.associatedTipSheets && (
        <Box component="div" className={classes.tipSheetsSection}>
          <Typography variant="body1" fontWeight="bold" textAlign="center">
            Tip Sheets
          </Typography>
          <Box component="div">
            <Typography>
              <span className={classes.heading}>Developmental: </span>
              <Link
                to={`/Assets/DevelopmentalTipSheets/${associatedTipSheets?.developmentTipSheet?.fileName}`}
                target="_blank"
                className={classes.link}
              >
                {associatedTipSheets?.developmentTipSheet?.label}
              </Link>
            </Typography>
            {Boolean(associatedTipSheets?.behaviourTipSheets?.length) && (
              <Typography>
                <span className={classes.heading}>Behavioral: </span>
                {associatedTipSheets?.behaviourTipSheets?.map((behaviourTipSheet, index) => (
                  <Link
                    to={`/Assets/BehaviorTipSheets/${behaviourTipSheet?.fileName}`}
                    target="_blank"
                    className={classes.link}
                    key={behaviourTipSheet?.label}
                  >
                    {getLastIndex() === index ? `${behaviourTipSheet?.label}` : `${behaviourTipSheet?.label}, `}
                  </Link>
                ))}
              </Typography>
            )}
          </Box>
        </Box>
      )}      
      <Box component="div">       
          <Button className={classes.btn} onClick={() => toggleCondensed()}>
            {observable.showCondensed ? 'Show Full Report' : 'Show Condensed Report'}
          </Button>   
          <Button className={classes.btn} onClick={() => navigate(-1)}>
            Back
          </Button>       
      </Box>
    </Box>
  );

  const renderReport = () => (
    <Box className={classes.reportSection}>      
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }} className={classes.sideBox}>
        {report?.reportSections?.map(section => {
          return (
            <div key={section?.orderIndex}>
              <Box className={classes.mainHeading}>
                <Typography id={String(section?.orderIndex)}>{section?.sectionName}</Typography>
                {((path === '/eco' && AuthStore?.isEcoAdmin) || path === '/provider' || path === '/admin') && (
                  <Button className={classes.button} onClick={() => openModal(section?.surveySectionId)}>
                    <AddCircleIcon className={classes.icon} />
                    <Typography variant="body1" className={classes.text}>
                      Add Note
                    </Typography>
                  </Button>
                )}
              </Box>
              <QuestionsResponse sectionId={section.surveySectionId} canSeeScores={isCanSeeScores} />
            </div>
          );
        })}
      </Box>
    </Box>
  );

  const renderCondensedReport = () => (
    <Box className={classes.reportSection}>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }} className={classes.sideBox}>
        {observable.compactPrintModel.map(section => {
          return (
            <div key={section?.orderIndex}>
              <Box>
                <>                  
                  <Typography id={String(section?.orderIndex)}><strong>{section?.mainText}</strong></Typography>
                  {section.subTexts.map(ssection => {
                    return (<Typography>{ssection.subText ?? ''}</Typography>)
                  })}
                </>
              </Box>
            </div>
          );
        })}
      </Box>
      <div>{/*JSON.stringify(observable.compactPrintModel)*/}</div>
    </Box>
  );

  const renderCondensedReportOld = () => (
    <Box className={classes.reportSection}>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }} className={classes.sideBox}>
        {report?.reportSections?.map(section => {
          return (
            <div key={section?.orderIndex}>
              <Box>
                <>                  
                  <Typography id={String(section?.orderIndex)}>{section?.sectionName + (section.subSections.length===1 ? (' - ' + section.subSections[0].score?.result.toString() ?? '') + ' - ' + (section.subSections[0].score?.childScore.toString() ?? '') : '')}</Typography>
                  {section.subSections.filter(f=>f.subSectionId!=null).map(ssection => {
                    return (<Typography>{'Sub-Section ' + ssection.subSectionId ?? ''} - {ssection.score.result + ' - ' + ssection.score.childScore}</Typography>)
                  })}
                </>
              </Box>
            </div>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <>
      {renderHeader()}
      {!observable.showCondensed &&  renderReport()}                  
      {observable.showCondensed &&  renderCondensedReport()}                  
    </>
  );
};

export default inject('childSurveyStore', 'commonStore')(observer(PrintableSurvey));
