import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { AuthStore } from '@stores';
import { AccountConsent, Children, SurveyConsent, ChildSurvey } from './Components';
import { About, ChildProgress, ChildReport, MyProfile } from '@shared';
import { ThanksPage } from '../Public';

const Caregiver: FC = () => {
  return (
    <Routes>
      <Route path="/my-profile" element={<MyProfile />} />
      {AuthStore.hasConsent ? (
        <>
          <Route path="/children" element={<Children />} />
          <Route path="/child-consent/:id" element={<SurveyConsent />} />
          <Route path="/child-survey/:id" element={<ChildSurvey />} />
          <Route path="/child-progress/:id" element={<ChildProgress />} />
          <Route path="/child-report/:id" element={<ChildReport />} />
          <Route path="/thanks" element={<ThanksPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/*" element={<Navigate to="children" replace />} />
        </>
      ) : (
        <>
          <Route path="/account-consent" element={<AccountConsent />} />
          <Route path="/*" element={<Navigate to="account-consent" replace />} />
        </>
      )}
    </Routes>
  );
};

export default observer(Caregiver);
