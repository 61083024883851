import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { AppBar, Box } from '@mui/material';
import Header from './Header/Header';
import { useStyles } from './Layout.styles';
import { UIStore } from '@stores';
import Overlay from '../Overlay/Overlay';

type Props = {
  children?: ReactNode;
};

const Layout: FC<Props> = ({ children }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {UIStore.pageLoading && <Overlay />}
      <AppBar position="fixed" className={classes.header}>
        <Header />
      </AppBar>
      {children && (
        <Box component="main" className={classes.mainContainer}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default observer(Layout);
