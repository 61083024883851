import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    padding: '20px',
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  paragraph: {
    margin: '0px',
    width: '550px',
  },
  title: {
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    display: 'inline',
  },
  greenBackground: {
    backgroundColor: 'rgba(0, 128, 0, 1)',
    borderRadius: '5px',
    color: 'white',
  },
  yellowBackground: {
    backgroundColor: theme.palette.warning.light,
    borderRadius: '5px',
    color: 'white',
  },
  grid: {
    height: 'calc(100vh - 745px)',
    '& .MuiDataGrid-root .MuiDataGrid-cell': {
      whiteSpace: 'normal !important',
      wordWrap: 'break-word !important',
    },
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 650px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 650px)',
    },
  },
}));
