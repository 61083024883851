export const UserRoleOptions = [
  { label: 'All User Type', value: 0 },
  { label: 'Admin', value: 1 },
  { label: 'Coach', value: 4 },
  { label: 'Coordinator', value: 5 },
];

export const EcoUserRoleOptions = [
  { label: 'Eco Admin', value: 3 },
  { label: 'Coach', value: 4 },
  { label: 'Coordinator', value: 5 },
];

export const Status = [
  { label: 'Any Status', value: 0 },
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 2 },
];

export const SiteStatus = [
  { label: 'Any Status', value: 0 },
  { label: 'Active', value: 2 },
  { label: 'Inactive', value: 1 },
];

export const CycleStatus = [
  { label: 'Open', value: 1 },
  { label: 'Close', value: 2 },
];

export const ChildReportStatus = [
  { label: 'All', value: 0 },
  { label: 'Not Reviewed', value: 1 },
  { label: 'Reviewed', value: 2 },
];
