import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    color: theme.palette.info.main,
  },
  text: {
    padding: 2,
    fontWeight: 'bold',
    color: theme.palette.info.main,
  },
  grid: {
    height: 'calc(100vh - 338px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 80px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 80px)',
    },
  },
}));
