import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
    margin: '10px 0px',
  },
  icon: {
    color: theme.palette.info.main,
  },
  text: {
    padding: 2,
    fontWeight: 'bold',
    color: theme.palette.info.main,
  },
  selectBox: {
    '&.MuiInputBase-root ': {
      marginTop: '9px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
  },
  tab: {
    textTransform: 'none',
    fontSize: '16px',
    color: '#000000d1',
    borderRadius: '9px',
  },
  tabList: {
    marginTop: '1rem',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '5px',
    borderRadius: '9px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabPanel: {
    padding: '24px 0px',
  },
  input: {
    width: '100%',
    margin: '16px 0 4px',
    '& .MuiInputBase-root': {
      marginTop: '9px',
      marginBottom: '13px',
    },
    '& .MuiInputLabel-root': {
      width: '100%',
      top: '-27px',
      transform: 'none',
      maxWidth: '100%',
      pointerEvents: 'all',
      color: theme.palette.grey[500],
      fontWeight: 600,
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px 10px',
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: '5px 8px 8px',
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: '7px 1px 10px',
    },
    '& MuiFormHelperText-root': {
      marginLeft: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
}));
