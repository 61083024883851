export const EthnicityOptions = [
  { value: 1, label: 'Hispanic or Latino' },
  { value: 2, label: 'Not Hispanic or Latino' },
];

export const RaceOptions = [
  { value: 1, label: 'American Indian or Alaska Native' },
  { value: 2, label: 'Asian' },
  { value: 3, label: 'Black or African American' },
  { value: 4, label: 'Native Hawaiian or Other Pacific Islander' },
  { value: 5, label: 'White' },
  { value: 6, label: 'Multiple Races' },
  { value: 7, label: 'Other' },
];

export const GenderOptions = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Other', value: 3 },
]

export const ColumbiaResidentOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
