import { action, makeObservable, observable } from 'mobx';
import { map, Observable } from 'rxjs';
import { ApiUrl, LocalStorageService } from '../Services';
import { Utils } from '../Utilities';
import { IAPIResponse } from '../Interfaces';
import HttpClient from '../Tools/HttpClient';
import { AuthUserModel } from '../Models';
import { UserRole } from '../Enums';

class AuthStore {
  public accessToken: string = '';
  public authUser: AuthUserModel = null;
  public hasConsent: boolean = false;

  constructor() {
    makeObservable(this, {
      accessToken: observable,
      authUser: observable,
      hasConsent: observable,
      login: action,
      setAuthUser: action,
      setHasConsent: action,
      clear: action,
    });
  }

  public get isAdmin(): boolean {
    return this.authUser?.role === UserRole.SUPER_ADMIN;
  }

  public get isEcoAdmin(): boolean {
    return this.authUser?.role === UserRole.ECO_ADMIN;
  }

  public get isCoach(): boolean {
    return this.authUser?.role === UserRole.COACH;
  }

  public get isCoordinator(): boolean {
    return this.authUser?.role === UserRole.COORDINATOR;
  }

  public get isFamily(): boolean {
    return this.authUser?.role === UserRole.FAMILY;
  }

  public get isProvider(): boolean {
    return this.authUser?.role === UserRole.PROVIDER;
  }

  public get isAgencyAdmin(): boolean {
    return this.authUser?.role === UserRole.AGENCY_ADMIN;
  }

  public get isSiteAdmin(): boolean {
    return this.authUser?.role === UserRole.SITE_ADMIN;
  }

  public get isAuthorized(): boolean {
    return Boolean(this.accessToken);
  }

  public setAuthUser(token: string): void {
    const user = AuthUserModel.deserialize(token);
    this.authUser = user;
    this.accessToken = token;
  }

  public setHasConsent(hasConsent: boolean): void {
    this.hasConsent = hasConsent;
  }

  public clear(): void {
    this.accessToken = '';
    this.authUser = null;
    this.hasConsent = false;
    LocalStorageService.clear();
  }

  public login(email: string, password: string): Observable<AuthUserModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ email, password });
    return http.post<any>(`${ApiUrl.login}?${params}`, '').pipe(
      map((response: IAPIResponse<string>) => {
        LocalStorageService.saveAuthToken(response.Data);
        this.setAuthUser(response.Data);
        return this.authUser;
      })
    );
  }

  public familyLogin(accessToken: string): Observable<AuthUserModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ accessToken });
    return http.post<any>(`${ApiUrl.familyLogin}?${params}`, '').pipe(
      map((response: IAPIResponse<string>) => {
        LocalStorageService.saveAuthToken(response.Data);
        this.setAuthUser(response.Data);
        return this.authUser;
      })
    );
  }

  public sendEmail(email: string): Observable<string> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ email });
    return http
      .post<any>(`${ApiUrl.sendEmail}?${params}`, null)
      .pipe(map((response: IAPIResponse<string>) => response.Data));
  }

  public validateUser(userGuid: string): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ userGuid });
    return http
      .post<any>(`${ApiUrl.validateRecoveryToken}?${params}`, null)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public resetPassword(userGuid: string, newPassword: string): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ userGuid, newPassword });
    return http
      .post<any>(`${ApiUrl.resetPassword}?${params}`, null)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public verifyConsent(): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<boolean>>(ApiUrl.verifyConsent)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<string> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<string>>(ApiUrl.changePassword, { oldPassword, newPassword })
      .pipe(map((response: IAPIResponse<string>) => response.Data));
  }
}

export default new AuthStore();
