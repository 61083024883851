import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    borderRadius: 10,
    borderColor: '#FAF9F6',
    padding: '25px 30px 30px',
  },
  stack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  marginRight: {
    marginRight: 20,
    [theme.breakpoints.down('lg')]: {
      display: 'grid',
      marginBottom: 15,
    },
  },
  searchButton: {
    alignItems: 'center',
    borderRadius: '8px',
    width: 250,
    height: '35px',
    borderColor: theme.palette.primary.main,
    marginRight: 20,
  },
  clearButton: {
    width: 250,
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
    borderRadius: '8px',
    height: '35px',
  },
  dropDown: {
    [theme.breakpoints.between('md', 'lg')]: {
      minWidth: '170px',
    },
    [theme.breakpoints.only('lg')]: {
      minWidth: '250px',
    },
  },
}));
