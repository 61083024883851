import React, { FC, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { MobxReactForm, Field } from 'mobx-react-form';
import { Checkbox, FormControlLabel, Typography, Button, Box } from '@mui/material';
import { ChildrenStore, ProfileStore, UIStore, UserService, Utils, getFormValidation } from '@shared';
import { ConsentFields } from '../Fields';
import { useStyles } from './ConsentForm.styles';
import { ProfileModel } from '@models';
import { AxiosError } from 'axios';

type Props = {
  handleNext: () => void;
  childrenStore?: ChildrenStore;
  profileStore?: ProfileStore;
  caregiverName?: string;
  caregiverEmail?: string;
};

const form: MobxReactForm = getFormValidation(ConsentFields);

const ConsentForm: FC<Props> = ({ handleNext, childrenStore, caregiverName, caregiverEmail }: Props) => {
  const classes = useStyles();
  const { child } = childrenStore;
  const { information, survey } = form.values();
  
  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: boolean, field: string) => {
    getField(field).set(value ? 'checked' : '');
  };

  const onSubmit = (): void => {
    form.submit({
      onSuccess: () => {
        handleNext();
        form.reset();
      },
      onError: error => {},
    });
  };

  return (
    <>
      <Typography>
        Child Name : <span className={classes.boldText}>{`${child?.firstName} ${child?.lastName}`}</span>
        Birth Date : <span className={classes.boldText}> {Utils.formattedDate(child?.birthDate, 'MM-DD-YYYY')}</span>
      </Typography>
      <Typography mt={1}>
        The following questionnaire, the Survey of Wellbeing of Young Children (SWYC&#8482;), includes questions that
        will provide a quick check of your child’s development. The information you supply will help find your child’s
        strengths, possible areas of concern, and determine if there are community resources or services that may be
        useful for your child or family.
      </Typography>
      <Typography mt={1}>
        As a reminder, all surveys completed will be stored within the BCECC SWYC&#8482; Screening Hub. Only project
        administration and providers that you give permission to will have access to these survey results and your
        information that is stored in the hub. Provider permissions can be removed by you at any time.
      </Typography>
      <Typography fontWeight="bold" mt={1} mb={1}>
        Please read the text below and check the blank if you agree with the statement and want your child to
        participate in the screening.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={information ? true : false}
            onChange={e => handleChange(e.target.checked, 'information')}
          />
        }
        label={`I am the parent or legal guardian of the child identified above or I spend a minimum of 20 hours
        per week with the child identified above and have permission from the parent or legal guardian to
        complete the screening. I have read the information provided about the SWYC\u2122. I understand that the
        SWYC\u2122 is a screening tool that will help identify strengths and areas of concern for my child. Your
        provider in the screening hub will review the results with you. I understand that I will be provided access
        to the results, and I can contact my child’s physician or another appropriate health professional with
        questions about the results of the screening. I understand the SWYC\u2122 is not designed to and does not
        provide medical advice or diagnosis. I wish to have my child participate in the screening program. I will
        complete the questionnaire about my child’s development.`}
      />
      <Typography mt={1} mb={1} fontWeight="bold">
        Please read the text below and check the blank if you want your child’s SWYC&#8482; information to be included
        in the Boone County Early Childhood Coalition’s screening hub. Please note: your child may still participate in
        the screening even if you do not choose to participate in the screening hub, you will need to speak with your
        provider if that is the case.
      </Typography>
      <FormControlLabel
        control={<Checkbox checked={survey ? true : false} onChange={e => handleChange(e.target.checked, 'survey')} />}
        label={`I understand that this information will be stored in my screening hub account and BCECC project
        administrative staff and providers that I’ve given consent to will have access to my personal information.
        The SWYC\u2122 information will be kept confidential to the extent permissible under applicable laws. Any
        data submitted to the grant funding agency will be de-identified.`}
      />
      <Box>
        <Typography mt={2}>Caregiver name for hub account: {caregiverName}</Typography>
        <Typography mt={2}>Email for hub account: {caregiverEmail}</Typography>
        <Typography mt={2}>
          You may cancel your consent and remove your information from the hub at any point by contacting Cindy Reese by
          email at cindyreese@health.missouri.edu or by calling at (573)884-4891.
        </Typography>
      </Box>     
      <div className={classes.btnContainer}>
        <Button variant="contained" onClick={onSubmit} className={classes.btn}>
          I Agree & Proceed
        </Button>
      </div>
    </>
  );
};

export default inject('childrenStore')(observer(ConsentForm));
