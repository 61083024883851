import { map, Observable } from 'rxjs';
import { IAPIResponse, IDashboardReport } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { DashboardReportModel, OptionsModel } from '../Models';

class ReportService {
  public getYears(): Observable<OptionsModel[]> {
    const http: HttpClient = new HttpClient();
    return http.get<IAPIResponse<number[]>>(ApiUrl.reportingDashboardYears).pipe(
      map((response: IAPIResponse<number[]>) => {
        const years = response.Data?.map((year, index) => {
          return { value: index + 1, label: String(year) };
        });
        return years;
      })
    );
  }

  public getDashboardReport(year: string): Observable<DashboardReportModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      year,
    });
    return http
      .get<IAPIResponse<IDashboardReport>>(`${ApiUrl.dashboardReport}?${params}`)
      .pipe(map((response: IAPIResponse<IDashboardReport>) => DashboardReportModel.deserialize(response.Data)));
  }
}

export default new ReportService();
