import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { GridSortModel } from '@mui/x-data-grid';
import { Actions, CustomDataGrid, DeleteDialog, useNotification, UserSearch } from '@shared';
import { UIStore, FamilyStore } from '@stores';
import { FamilyService } from '@services';
import { FamilyModel, FamilyPaginationModel, OptionsModel } from '@models';
import { useStyles } from './CaregiverGrid.styles';
import { CrossIcon, EditIcon } from '@assets';

type Props = {
  providerList: OptionsModel[];
  familyStore?: FamilyStore;
};

const CaregiverGrid: FC<Props> = ({ familyStore, providerList }: Props) => {
  const notification = useNotification();
  const navigate = useNavigate();
  const classes = useStyles();

  const observable = useLocalObservable(() => ({
    family: new FamilyModel(),
    isDelete: false,
    isEdit: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    isArchived: false,
    providerId: 0,
    totalNumberOfRecords: 0,
    sortModel: [],
  }));

  const columns = [
    { field: 'fullName', headerName: 'Caregiver/ Site Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'providerName', headerName: 'Provider Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'childrenCount',
      headerName: '#. Of Children',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    getFamilyList();
  }, []);

  const deleteFamily = () => {
    UIStore.showLoader();
    FamilyService.deleteFamily(observable.family?.guid).subscribe({
      next: (response: boolean) => {
        handleDeleteClose();
        getFamilyList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const handleDeleteClose = () => {
    runInAction(() => {
      observable.isDelete = false;
    });
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getFamilyList();
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getFamilyList();
  };

  const handleSortModel = (newSortModel: GridSortModel) => {
    runInAction(() => {
      observable.sortModel = newSortModel;
    });
    getFamilyList();
  };

  const getFamilyList = () => {
    const { pageNumber, pageSize, sortModel, query, isArchived, providerId } = observable;
    UIStore.showLoader();
    FamilyService.getFamilyList(pageNumber, pageSize, sortModel, query, isArchived, providerId).subscribe({
      next: (response: FamilyPaginationModel) => {
        familyStore.setFamilyList(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onUserAction = (family: FamilyModel) => {
    runInAction(() => {
      observable.family = family;
      observable.isDelete = true;
    });
  };

  const onSearch = (searchText: string, status: number, providerId: number) => {
    runInAction(() => {
      observable.query = searchText;
      observable.providerId = providerId;
      observable.pageNumber = 1;
    });
    getFamilyList();
  };

  const onClear = () => {
    runInAction(() => {
      observable.query = '';
      observable.providerId = 0;
    });
    getFamilyList();
  };

  const onArchived = (isArchived: boolean) => {
    runInAction(() => {
      observable.isArchived = isArchived;
      observable.pageNumber = 1;
    });
    getFamilyList();
  };

  const renderActions = params => {
    return (
      <>
        <div style={{ width: 70 }}>
          <Actions btnText="Edit" onClick={() => navigate(`/admin/families/${params.row?.guid}`)} imgSrc={EditIcon} />
        </div>
        <div style={{ width: 80 }}>
          {!params.row.isAssignedToMasterProvider && (
            <Actions btnText="Delete" onClick={() => onUserAction(params.row)} imgSrc={CrossIcon} />
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Box component="div" display="flex" flexDirection="column">
        <UserSearch
          searchPlaceholder="Enter name or email to search family"
          onSearch={onSearch}
          onClear={onClear}
          provider={true}
          providerOptions={[{ value: 0, label: 'All Provider' }, ...providerList]}
        />
        <div className={classes.button}>
          <FormControlLabel
            control={<Checkbox checked={observable.isArchived} onChange={e => onArchived(e.target.checked)} />}
            label={<Typography>Archive</Typography>}
          />
          <Button onClick={() => navigate('/admin/add-family')}>
            <AddCircleIcon className={classes.icon} />
            <Typography variant="body1" className={classes.text}>
              ADD FAMILY
            </Typography>
          </Button>
        </div>
        <CustomDataGrid
          columns={columns}
          data={familyStore.familyList}
          totalNumberOfRecords={observable.totalNumberOfRecords}
          pageSize={observable.pageSize}
          pageNumber={observable.pageNumber}
          sortModel={observable.sortModel}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={onPageChange}
          onSortModelChange={handleSortModel}
          styleClasses={{ gridControl: classes.grid }}
        />
      </Box>
      {observable.isDelete && (
        <DeleteDialog
          open={observable.isDelete}
          content="Do you really want to delete this caregiver?"
          handleClose={handleDeleteClose}
          handleDelete={deleteFamily}
        />
      )}
    </div>
  );
};

export default inject('familyStore')(observer(CaregiverGrid));
