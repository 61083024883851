import React, { FC } from 'react';
import { Button, Divider, ListItemButton, Menu, MenuItem, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { useStyles } from '../Header.styles';

type Props = {
    title: string;
    options: any[];
    isMobileView?: boolean;
    className?:string;
}

const UserDropdown: FC<Props> = ({ title, options, isMobileView = false,className }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const classes = useStyles();
    const navigate = useNavigate();

    const renderMenuItems = () => {
        return options.map((option, index) => (
            <div key={index}>
                <MenuItem onClick={() => navigate(option.navigate)}>
                    {option.name}
                </MenuItem>
                <Divider />
            </div>
        ))
    }

    return (
        <>
            {isMobileView
                ?
                <ListItemButton onClick={handleClick}>{title}</ListItemButton>
                :
                <Button
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? `${title.toLowerCase()}-menu` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    className= { className }
                >
                    <Typography variant="body1" noWrap component="div" sx={{ fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                </Button>}
            <Menu
                anchorEl={anchorEl}
                id={`${title.toLowerCase()}-menu`}
                open={open}
                className={classes.list}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {renderMenuItems()}
            </Menu>
        </>
    );
};

export default observer(UserDropdown);
