export const sideBareOptions = [
  {
    id: 1,
    label: 'SWYC Development',
  },
  {
    id: 2,
    label: 'SWYC Behaviour',
  },
  {
    id: 3,
    label: 'Reports',
  },
  {
    id: 4,
    label: 'Referrals',
  },
];
