import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import MobxReactForm, { Field } from 'mobx-react-form';
import {
  FormHelperText,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useStyles } from './CustomDropdown.styles';

type Props = {
  value?: string | number;
  options?: any[];
  placeholder?: string;
  label?: string;
  field?: Field;
  form?: MobxReactForm;
  height?: number;
  className?: string;
  error?: boolean;
  onValueChange?: (value: string | number) => void;
};

const CustomDropDown: FC<Props> = ({
  value,
  label,
  className,
  options,
  placeholder,
  field,
  form,
  height,
  onValueChange,
  error = false,
}: Props) => {
  const classes = useStyles(); 
  const handleChange = (event: SelectChangeEvent<string | number>) => {
    onValueChange(event.target.value);
  };

  return (
    <div className={className}>
      <InputLabel id="select-label">{field?.label || label}</InputLabel>
      <Select
        fullWidth
        value={value}
        className={className}
        onChange={handleChange}
        displayEmpty
        {...field?.bind()}
        renderValue={selected => options?.find(x => x?.value === selected)?.label || placeholder || field?.placeholder}
        error={Boolean((Boolean(form?.submitted) || field?.touched) && (field?.hasError || error))}
        sx={{ minWidth: 400, height: height || 55, color: value || value === 0 ? 'black' : '#BCBCBC' }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: theme => theme.palette.primary.main,
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: theme => theme.palette.primary[100],
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                backgroundColor: theme => theme.palette.primary.main,
              },
            },
          },
        }}
      >
        {placeholder ||
          (field?.placeholder && (
            <MenuItem value="">
              <Typography color="#BCBCBC">{placeholder || field?.placeholder}</Typography>
            </MenuItem>
          ))}
        {options?.map(item => {
          return (
            <MenuItem value={item?.value} key={item?.value}>
              {value === item?.value && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
              {item?.label}
            </MenuItem>
          );
        })}
      </Select>
      {Boolean((Boolean(form?.submitted) || field?.touched) && (field?.hasError || error)) && (
        <FormHelperText className={classes.errorText}>{`${
          placeholder || field?.placeholder
        } field is required.`}</FormHelperText>
      )}
    </div>
  );
};
export default observer(CustomDropDown);
