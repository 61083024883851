import { IProviderPagination } from '../Interfaces';
import { PageModel } from './Page.model';
import { ProviderModel } from './Provider.model';

export class ProviderPaginationModel extends PageModel {
    results: ProviderModel[];

    constructor(data?: Partial<ProviderPaginationModel>) {
        super();
        Object.assign(this, data);
    }

    static deserialize(apiData: IProviderPagination): ProviderPaginationModel {
        if (!apiData) {
            return new ProviderPaginationModel();
        }

        const data: Partial<ProviderPaginationModel> = {
            pageNumber: apiData.PageNumber,
            pageSize: apiData.PageSize,
            totalNumberOfRecords: apiData.TotalNumberOfRecords,
            totalPages: apiData.TotalPages,
            results: ProviderModel.deserializeList(apiData.Results),
        };

        return new ProviderPaginationModel(data);
    }
}
