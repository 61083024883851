import {
  IChildProgress,
  IChildSurveyAssessmentReports,
  IReferrals,
  ISWYCBehaviourProgress,
  ISWYCDevelopmentProgress,
  ISection,
} from '../Interfaces';

export class ChildProgressModel {
  childId: number;
  childFirstName: string = '';
  childLastName: string = '';
  swycDevelopmentProgress: SWYCDevelopmentProgressModel;
  swycBehaviourProgress: SWYCBehaviourProgressModel;
  childSurveyAssessmentReports: ChildSurveyAssessmentReportsModel[] = [];
  referrals: ReferralsModel[] = [];

  constructor(data?: Partial<ChildProgressModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IChildProgress): ChildProgressModel {
    if (!apiData) {
      return new ChildProgressModel();
    }

    const data: Partial<ChildProgressModel> = {
      childId: apiData.ChildId,
      childFirstName: apiData?.ChildFirstName || '',
      childLastName: apiData?.ChildLastName || '',
      swycDevelopmentProgress: SWYCDevelopmentProgressModel.deserialize(apiData.SWYCDevelopmentProgress),
      swycBehaviourProgress: SWYCBehaviourProgressModel.deserialize(apiData.SWYCBehaviourProgress),
      childSurveyAssessmentReports: ChildSurveyAssessmentReportsModel.deserializeList(
        apiData.ChildSurveyAssessmentReports
      ),
      referrals: ReferralsModel.deserializeList(apiData.Referrals),
    };

    return new ChildProgressModel(data);
  }

  static deserializeList(progressList: IChildProgress[]): ChildProgressModel[] {
    return progressList
      ? progressList.map((apiData: IChildProgress) => new ChildProgressModel(ChildProgressModel.deserialize(apiData)))
      : [];
  }
}

export class SWYCDevelopmentProgressModel {
  dm: SectionModel[];
  posi: SectionModel[];
  labels: string[];
  dmData: number[];
  posiData: number[];

  constructor(data?: Partial<SWYCDevelopmentProgressModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ISWYCDevelopmentProgress): SWYCDevelopmentProgressModel {
    if (!apiData) {
      return new SWYCDevelopmentProgressModel();
    }

    const data: Partial<SWYCDevelopmentProgressModel> = {
      dm: SectionModel.deserializeList(apiData.DM),
      posi: SectionModel.deserializeList(apiData.POSI),
      labels: apiData?.Labels || [],
      dmData: apiData?.Labels?.map(label => {
        const result = apiData?.DM?.find(x => x.SurveyName === label);
        if (result?.Observation) return result?.Observation;
        return 0;
      }),
      posiData: apiData?.Labels?.map(label => {
        const result = apiData?.POSI?.find(x => x.SurveyName === label);
        if (result?.Observation) return result?.Observation;
        return 0;
      }),
    };

    return new SWYCDevelopmentProgressModel(data);
  }

  static deserializeList(developmentList: ISWYCDevelopmentProgress[]): SWYCDevelopmentProgressModel[] {
    return developmentList
      ? developmentList.map(
          (apiData: ISWYCDevelopmentProgress) =>
            new SWYCDevelopmentProgressModel(SWYCDevelopmentProgressModel.deserialize(apiData))
        )
      : [];
  }
}

export class SWYCBehaviourProgressModel {
  bpsc: SectionModel[];
  ppsc: SectionModel[];
  labels: string[];
  bpscData: number[];
  ppscData: number[];

  constructor(data?: Partial<SWYCBehaviourProgressModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ISWYCBehaviourProgress): SWYCBehaviourProgressModel {
    if (!apiData) {
      return new SWYCBehaviourProgressModel();
    }

    const data: Partial<SWYCBehaviourProgressModel> = {
      bpsc: SectionModel.deserializeList(apiData.BPSC),
      ppsc: SectionModel.deserializeList(apiData.PPSC),
      labels: apiData?.Labels || [],
      bpscData: apiData?.Labels?.map(label => {
        const result = apiData?.BPSC?.find(x => x.SurveyName === label);
        if (result?.Observation) return result?.Observation;
        return 0;
      }),
      ppscData: apiData?.Labels?.map(label => {
        const result = apiData?.PPSC?.find(x => x.SurveyName === label);
        if (result?.Observation) return result?.Observation;
        return 0;
      }),
    };

    return new SWYCBehaviourProgressModel(data);
  }

  static deserializeList(behaviourList: ISWYCBehaviourProgress[]): SWYCBehaviourProgressModel[] {
    return behaviourList
      ? behaviourList.map(
          (apiData: ISWYCBehaviourProgress) =>
            new SWYCBehaviourProgressModel(SWYCBehaviourProgressModel.deserialize(apiData))
        )
      : [];
  }
}

export class SectionModel {
  surveyId: number;
  surveyName: string;
  sectionId: number;
  sectionName: string;
  sectionAbbreviation: string;
  cutScore: number;
  childScore: number;
  observation: number;

  constructor(data?: Partial<SectionModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ISection): SectionModel {
    if (!apiData) {
      return new SectionModel();
    }

    const data: Partial<SectionModel> = {
      surveyId: apiData.SurveyId,
      surveyName: apiData.SurveyName,
      sectionId: apiData.SectionId,
      sectionName: apiData.SectionName,
      sectionAbbreviation: apiData.SectionAbbreviation,
      cutScore: apiData.CutScore,
      childScore: apiData.ChildScore,
      observation: apiData.Observation,
    };

    return new SectionModel(data);
  }

  static deserializeList(sectionList: ISection[]): SectionModel[] {
    return sectionList
      ? sectionList.map((apiData: ISection) => new SectionModel(SectionModel.deserialize(apiData)))
      : [];
  }
}

export class ChildSurveyAssessmentReportsModel {
  id: number;
  surveyName: string;
  submittedOn: string;
  status: string;
  childSurveyGuid: string;
  displayReportLink: boolean;

  constructor(data?: Partial<ChildSurveyAssessmentReportsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IChildSurveyAssessmentReports): ChildSurveyAssessmentReportsModel {
    if (!apiData) {
      return new ChildSurveyAssessmentReportsModel();
    }

    const data: Partial<ChildSurveyAssessmentReportsModel> = {
      id: apiData.SurveyId,
      surveyName: apiData.SurveyName,
      submittedOn: apiData.SubmittedOn,
      status: apiData.Status,
      childSurveyGuid: apiData.ChildSurveyGuid,
      displayReportLink: apiData.DisplayReportLink,
    };

    return new ChildSurveyAssessmentReportsModel(data);
  }

  static deserializeList(reportList: IChildSurveyAssessmentReports[]): ChildSurveyAssessmentReportsModel[] {
    return reportList
      ? reportList.map(
          (apiData: IChildSurveyAssessmentReports) =>
            new ChildSurveyAssessmentReportsModel(ChildSurveyAssessmentReportsModel.deserialize(apiData))
        )
      : [];
  }
}

export class ReferralsModel {
  id: number;
  referredBy: string;
  referredTo: string;
  referralDate: string;

  constructor(data?: Partial<ReferralsModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IReferrals): ReferralsModel {
    if (!apiData) {
      return new ReferralsModel();
    }

    const data: Partial<ReferralsModel> = {
      id: apiData.ReferralId,
      referredBy: apiData.ReferredBy,
      referredTo: apiData.ReferredTo,
      referralDate: apiData.ReferralDate,
    };

    return new ReferralsModel(data);
  }

  static deserializeList(referralList: IReferrals[]): ReferralsModel[] {
    return referralList
      ? referralList.map((apiData: IReferrals) => new ReferralsModel(ReferralsModel.deserialize(apiData)))
      : [];
  }
}
