import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { inject, observer, useLocalObservable } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import {
  CustomDataGrid,
  CustomDialog,
  CustomDropDown,
  getFormValidation,
  useNotification,
  Utils,
  SurveyCycleStatus,
  MultiSelect,
  MultiEmail,
  RowColor,
  DeleteDialog,
  Actions,
} from '@shared';
import { UIStore, CycleStore, CommonStore, AuthStore } from '@stores';
import { ChildSurveyService, CommonService, EcoSiteMappingService, SiteService } from '@services';
import { ChildrenModel, ChildrenPaginationModel, CycleModel, CyclePaginationModel, OptionsModel } from '@models';
import { CycleFields, SurveyFields } from './Fields';
import { useStyles } from './EcoSurvey.styles';
import { Link } from 'react-router-dom';
import { CrossIcon } from '@assets';

type Props = {
  cycleStore?: CycleStore;
  commonStore?: CommonStore;
};

const form: MobxReactForm = getFormValidation(CycleFields, null);
const surveyForm: MobxReactForm = getFormValidation(SurveyFields, null);

const EcoSurvey: FC<Props> = ({ cycleStore, commonStore }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const navigate = useNavigate();
  const observable = useLocalObservable(() => ({
    cycle: new CyclePaginationModel(),
    child: new ChildrenModel(),
    childrenIds: [],
    emailsTo: [],
    additionalEmails: [],
    isSiteModalOpened: false,
    isSurveyModalOpened: false,
    showDeleteSurvey: false,
    deleteSurveyId:0,
    showEcos: false,
    pageNumber: 1,
    pageSize: 10,
    query: '',
    totalNumberOfRecords: 0,
    sortModel: [],
    siteId: 0,
  }));
  const { cycle, students } = cycleStore;

  const columns = [
    {
      field: 'action',
      headerName: '',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => (
        <>
          {!params.row.isSubmitted && (
            <Checkbox checked={params.row.isSelected} onChange={e => onChange(e, params.row.id)} />
          )}
        </>
      ),
    },
    { field: 'childName', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header', sortable: false },
    {
      field: 'birthDate',
      headerName: 'Birth Date',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
     
    },
    {
      field: 'studentId',
      headerName: 'Student Id',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'submittedOn',
      headerName: 'Completed Date',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => Utils.formattedDate(params?.value, 'MM/DD/YYYY'),
    },
    {
      field: 'swycVersion',
      headerName: 'SWYC Version',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'isReviewed',
      flex: 1,
      headerName: 'Reviewed',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => (
        <>
          {params.row.isSubmitted && (
            <Checkbox checked={params.row.isReviewed} onChange={() => onStatusUpdate(params.row)} />
          )}
        </>
      ),
    },
    {
      field: '',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: params => (
        <>
          {params.row?.isSubmitted && params.row?.childSurveyGuid && (
            <Link target="_blank" to={`/eco/student-report/${params.row?.childSurveyGuid}`} className={classes.link}>
              View Report
            </Link>
          )}
        </>
      ),
    },
    {
      field: 'deleteSurveyAction',
      headerName: 'DeleteSurvey',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      renderCell: params => renderActions(params),
    },
  ];

  useEffect(() => {
    const loadCycles = async()=>{
      getSiteAndCycleOptions();
    }
    loadCycles();
    return () => {
      runInAction(() => {        
        form.$submitted = 0;
      });
      form.reset();
    };
  }, []);

  /*const getSiteOptions = () => {
    UIStore.showLoader();
    CommonService.getSiteOptions().subscribe({
      next: (response: OptionsModel[]) => {
        commonStore.setSites(response);
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };*/

  const getSiteAndCycleOptions = () => {
    //UIStore.showLoader();
    EcoSiteMappingService.getSiteAndCycleOptions().subscribe({
      next: (response: OptionsModel[]) => {        
        commonStore.setSitesAndCycles(response);
        observable.isSiteModalOpened=true;
        observable.isSurveyModalOpened=false;
      //  UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
      //  UIStore.hideLoader();
      },
    });
  };

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const onChange = (e, id: number) => {
    const studentIndex = students?.findIndex(x => x.id === id);
    students[studentIndex].isSelected = e.target.checked;
    const isIdExist = observable.childrenIds?.some(x => x === id);
    runInAction(() => {
      if (isIdExist) {
        observable.childrenIds = observable.childrenIds?.filter(x => x !== id);
        return;
      }
      observable.childrenIds = [...observable.childrenIds, id];
    });
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value);
  };

  const getSurveyFormField = (key: string): Field => {
    return surveyForm.$(key);
  };

  const handleSurveyFormChange = (value: any[], field: string): Field => {
    getSurveyFormField(field).set(value);
    runInAction(() => {
      observable.emailsTo = value?.map(x => x.email);
    });
  };

  const handleClose = (isSite: boolean) => {
    runInAction(() => {
      if (isSite) {
        observable.isSiteModalOpened = false;
        form.$submitted = 0;
        form.reset();
        navigate(-1);
        return;
      }
      observable.isSurveyModalOpened = false;
      observable.emailsTo = [];
      observable.additionalEmails = [];
      surveyForm.$submitted = 0;
      surveyForm.reset();
    });
  };

  const getStudentList = () => {
    const { cycleId} = form.values();    
    UIStore.showLoader();    
    EcoSiteMappingService.getStudents(cycleId, observable.pageSize, observable.pageNumber).subscribe({
      next: (response: ChildrenPaginationModel) => {                
        cycleStore.setStudents(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const refreshStudentList = () => {
    const cycleId = cycleStore.cycle.id;    
    UIStore.showLoader();    
    EcoSiteMappingService.getStudents(cycleId, observable.pageSize, observable.pageNumber).subscribe({
      next: (response: ChildrenPaginationModel) => {                
        cycleStore.setStudents(response.results);
        runInAction(() => {
          observable.totalNumberOfRecords = response.totalNumberOfRecords;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const getActiveCycle = () => {
    const { siteId } = form.values();
    UIStore.showLoader();
    EcoSiteMappingService.activeCycle(siteId).subscribe({
      next: (response: CycleModel) => {
        UIStore.hideLoader();
        if (!response?.id) return;
        cycleStore.setCycle(response);
        getStudentList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const getCycleByCycleId = () => {
    const { cycleId } = form.values();
    UIStore.showLoader();
    EcoSiteMappingService.getCycleByCycleId(cycleId).subscribe({
      next: (response: CycleModel) => {        
        UIStore.hideLoader();
        if (!response?.id) return;        
        cycleStore.setCycle(response);
        observable.siteId=response.siteId;
        getStudentList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSubmit = () => {
    form.submit({
      onSuccess: () => {
        runInAction(() => {
          observable.isSiteModalOpened = false;
        });
        getCycleByCycleId();
      },
      onError: error => {},
    });
  };

  const onSendSurveyLink = () => {
    let siteId = observable.siteId;
    UIStore.showLoader();    
    SiteService.siteAdminsAndTeachers(siteId).subscribe({
      next: (response: OptionsModel[]) => {
        cycleStore.setSiteUsers(response);
        UIStore.hideLoader();
        runInAction(() => {
          observable.isSurveyModalOpened = true;
        });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onChangeAdditionalEmails = (emails: string[]) => {
    runInAction(() => {
      observable.additionalEmails = emails;
    });
  };

  const onChangeEmails = (emails: string[]) => {
    const { name } = surveyForm.values();
    const filteredName = name?.filter(x => emails?.includes(x.email));
    handleSurveyFormChange(filteredName, 'name');
  };

  const getRowClassName = (child: ChildrenModel): string => {
    if (child?.isSubmitted && child?.isReviewed) {
      return RowColor.LIGHT_BLUE;
    }
    if (child?.isSubmitted && !child?.isReviewed && !child?.needReview) {
      return RowColor.LIGHT_GREEN;
    }
    if (child?.isSubmitted && !child?.isReviewed && child?.needReview) {
      return RowColor.LIGHT_YELLOW;
    }
    return '';
  };

  const onStatusUpdate = (child: ChildrenModel) => {
    const req = { childSurveyGuid: child?.childSurveyGuid, isReviewed: !child?.isReviewed };
    UIStore.showLoader();
    ChildSurveyService.updateSurveyStatus(req).subscribe({
      next: (response: boolean) => {
        UIStore.hideLoader();
        getStudentList();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const onSendsurvey = () => {
    surveyForm.submit({
      onSuccess: () => {
        const { childrenIds, emailsTo, additionalEmails } = observable;
        const request = { ecoSiteMappingCycleId: cycleStore.cycle?.id, childrenIds, emailsTo, additionalEmails };
        UIStore.showLoader();
        ChildSurveyService.sendSurvey(request).subscribe({
          next: (response: any) => {
            UIStore.hideLoader();
            handleClose(false);
            notification.success('Survey link sent successfully!');
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const onPageChange = (pageNumber: number) => {
    runInAction(() => {
      observable.pageNumber = pageNumber;
    });
    getStudentList();
  };

  const handleRowsPerPageChange = (rowsPerPage: string | number) => {
    runInAction(() => {
      observable.pageSize = Number(rowsPerPage);
      observable.pageNumber = 1;
    });
    getStudentList();
  };

  const handleCloseDeleteSurvey = () => {
    runInAction(() => {      
      observable.showDeleteSurvey = false;
    //  form.$submitted = 0;
    });
    //form.reset();
  };

  const deleteSurvey = () => {
    UIStore.showLoader();
    ChildSurveyService.deleteECOSurvey(observable.child?.childSurveyGuid).subscribe({
      next: (response: boolean) => {
        handleCloseDeleteSurvey();
        refreshStudentList();      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const renderActions = params => {
    return (
      <>
        {AuthStore?.isEcoAdmin && params.row.isSubmitted && (
          <Actions btnText="Delete" onClick={() => onDeleteSurvey(params.row)} imgSrc={CrossIcon} />
        )}        
      </>
    );
  };

  const onDeleteSurvey = (child: ChildrenModel) => {
    runInAction(() => {
      observable.child = child;      
      observable.showDeleteSurvey = true;
    });
  };

  return (
    <div style={{ marginTop: 25 }}>
      {!observable.isSiteModalOpened && (
        <>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} width="100%">
            <Grid item md={3} sm={6} display="flex" flexDirection="row">
              <Typography variant="body1" sx={{ mr: '5px', fontWeight: 'bold' }}>
                Cycle Name:
              </Typography>
              <Typography variant="body1">{cycle?.name}</Typography>
            </Grid>
            <Grid item md={3} sm={6} display="flex" flexDirection="row">
              <Typography variant="body1" sx={{ mr: '5px', fontWeight: 'bold' }}>
                Start Date:
              </Typography>
              <Typography variant="body1">{Utils.formattedDate(cycle?.startDate || '', 'MM/DD/YYYY')}</Typography>
            </Grid>
            <Grid item md={3} sm={6} display="flex" flexDirection="row">
              <Typography variant="body1" sx={{ mr: '5px', fontWeight: 'bold' }}>
                End Date:
              </Typography>
              <Typography variant="body1">{Utils.formattedDate(cycle?.endDate || '', 'MM/DD/YYYY')}</Typography>
            </Grid>
            <Grid item md={3} sm={6} display="flex" flexDirection="row">
              <Typography variant="body1" sx={{ mr: '5px', fontWeight: 'bold' }}>
                Status:
              </Typography>
              <Typography variant="body1">{SurveyCycleStatus[cycle?.status]}</Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" className={classes.title}>
            Student List
          </Typography>
          <Box component="div" display="flex" flexDirection="column" sx={{ mt: 1 }}>
            <CustomDataGrid
              columns={columns}
              data={students}
              pageNumber={observable.pageNumber}              
              pageSize={observable.pageSize}
              onPageChange={onPageChange}
              totalNumberOfRecords={observable.totalNumberOfRecords}
              onRowsPerPageChange={handleRowsPerPageChange}
              getRow={row => getRowClassName(row)}                            
              styleClasses={{ gridControl: classes.grid }}
            />
          </Box>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={onSendSurveyLink}
              disabled={!observable.childrenIds.length || new Date(cycleStore.cycle.endDate)<new Date()}
            >
              Send Survey Link
            </Button>
          </div>
        </>
      )}
      {observable.showDeleteSurvey && (
        <DeleteDialog
          open={observable.showDeleteSurvey}
          content="Do you really want to delete this survey?"
          handleClose={handleCloseDeleteSurvey}
          handleDelete={deleteSurvey}
        />
      )}
      <CustomDialog
        title="Select Site and Cycle"
        buttonText="Submit"
        open={observable.isSiteModalOpened}
        onClose={() => handleClose(true)}
        onSubmit={onSubmit}
      >
        <CustomDropDown
          value={form.$('cycleId').value}
          field={form.$('cycleId')}
          options={commonStore.sitesAndCycles}
          className={classes.selectBox}
          placeholder={form.$('cycleId').label}
          onValueChange={value => handleChange(value, 'cycleId')}
          form={form}
        />
      </CustomDialog>
      <CustomDialog
        title="Send Survey Link"
        buttonText="Submit"
        open={observable.isSurveyModalOpened}
        fullWidth={true}
        onClose={() => handleClose(false)}
        onSubmit={onSendsurvey}
      >
        <MultiSelect
          label={surveyForm.$('name').label}
          options={cycleStore.siteUsers}
          onValueChange={value => handleSurveyFormChange(value, 'name')}
          placeholder={surveyForm.$('name').placeholder}
          className={classes.selectBox}
          value={surveyForm.$('name').value}
          form={surveyForm}
          error={surveyForm.$('name').error}
        />
        <MultiEmail
          emails={observable.emailsTo}
          onChangeEmails={onChangeEmails}
          placeholder="Email"
          label="Email"
          isRemoved={false}
        />
        <MultiEmail
          emails={observable.additionalEmails}
          onChangeEmails={onChangeAdditionalEmails}
          placeholder="Additional Email"
          label="Additional Email"
        />
      </CustomDialog>
    </div>
  );
};

export default inject('cycleStore', 'commonStore')(observer(EcoSurvey));
