import { Button, Tooltip, Typography } from '@mui/material';
import { FC,ReactElement } from 'react';
import { useStyles } from './Actions.styles';
import classNames from 'classnames';
import { IClasses } from '@interfaces';

type Props = {
  onClick: () => void;
  btnText?: string;
  styleClasses?: IClasses;
  showTooltip?: boolean;
  tooltipTitle?: string;
  icon?: boolean;
  imgSrc?:string;
  iconSrc?: ReactElement;
};
const Actions: FC<Props> = ({
  onClick,
  btnText,
  styleClasses,
  icon = false,
  showTooltip,
  tooltipTitle,
  iconSrc,
  imgSrc
}: Props) => {
  const classes = useStyles();
  return (
    <>
      {showTooltip ? (
        <Tooltip title={tooltipTitle} placement="bottom-end">
          <Button
            className={classNames({
              [classes.btn]: true,
              [styleClasses?.btnControl]: styleClasses?.btnControl,
            })}
            onClick={onClick}
          >
            { icon ? iconSrc  : <img src={imgSrc} alt={btnText} />}
          </Button>
        </Tooltip>
      ) : (
        <Button
          className={classNames({
            [classes.btn]: true,
            [styleClasses?.btnControl]: styleClasses?.btnControl,
          })}
          onClick={onClick}
        >
          <>
          { icon ? iconSrc  : <img src={imgSrc} alt={btnText} />}
          <Typography variant="subtitle2" sx={{ ml: 1, color: 'black' }}>
            {btnText}
          </Typography>
          </>
        </Button>
      )}
    </>
  );
};
export default Actions;
