import { FC, ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { InputLabel, TextField } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface Props {
  showError?: boolean;
  className?: string;
  form?: MobxReactForm;
  field?: Field;
  value?: any;
  showLabel?: boolean;
  isDisabled?: boolean;
  startAdornment?: any;
  inputProps?: any;
  errorMessage?: string;
  label?: string;
  startDate?: string;
  endDate?: string;
  isBirthDate?: boolean;
  onValueChange?: (date: any, fieldKey: string) => void;
}

const CustomDatePicker: FC<Props> = (props: Props) => {
  const [touched, setTouched] = useState(false);
  const onValueChange = (value: any, fieldKey: string): void => {
    props.field.showErrors(true);
    props.onValueChange(value, fieldKey);
  };

  const editableContent = (): ReactNode => {
    const { field, form, showLabel, className } = props;
    const { hasError, value, errorSync } = field;

    const handleBlur = () => {
      setTouched(true);
    };

    return (
      <div className={className}>
        <InputLabel id="select-label">{showLabel && field?.label}</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            {...field?.bind()}
            inputFormat="MM/dd/yyyy"
            label=""
            value={value}
            onChange={(date, value: string) => onValueChange(date, value)}
            minDate={props.startDate ? new Date().setDate(new Date(props.startDate).getDate() + 1) : null}
            maxDate={
              props.endDate
                ? props.isBirthDate
                  ? new Date().setDate(new Date(props.endDate).getDate())
                  : new Date().setDate(new Date(props.endDate).getDate() - 1)
                : null
            }
            renderInput={params => (
              <TextField
                {...params}
                helperText={Boolean((Boolean(form?.submitted) || touched) && hasError) && errorSync}
                error={Boolean((Boolean(form?.submitted) || touched) && hasError)}
                onBlur={() => handleBlur()}
                inputProps={{ ...params.inputProps, placeholder: 'MM/DD/YYYY' }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    );
  };

  return <div>{editableContent()}</div>;
};

export default observer(CustomDatePicker);
