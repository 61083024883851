import React, { FC, useEffect } from 'react';
import { AxiosError } from 'axios';
import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import { Typography } from '@mui/material';
import { UIStore } from '@shared';
import { CommonService } from '@services';
import { OptionsModel } from '@models';
import { useStyles } from './Family.styles';
import CaregiverGrid from './CaregiverGrid/CaregiverGrid';

const Family: FC = () => {
  const classes = useStyles();
  const observable = useLocalObservable(() => ({
    value: '1',
    providerList: [],
  }));

  useEffect(() => {
    getProviderList();
  }, []);

  const getProviderList = () => {
    UIStore.showLoader();
    CommonService.getProviderOptions().subscribe({
      next: (response: OptionsModel[]) => {
        runInAction(() => {
          observable.providerList = response;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
      },
    });
  };

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        {observable.value === '1' ? 'Families' : 'Children'}
      </Typography>
      <CaregiverGrid providerList={observable.providerList} />
    </>
  );
};

export default observer(Family);
