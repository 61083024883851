import { action, makeObservable, observable } from 'mobx';
import { ProfileModel } from '../Models';

class ProfileStore {
  public profile: ProfileModel = new ProfileModel();

  constructor() {
    makeObservable(this, {
      profile: observable,
      setProfile: action,
      clear: action,
    });
  }

  public setProfile(profile: ProfileModel): void {
    this.profile = profile;
  }

  public clear(): void {
    this.profile = new ProfileModel();
  }
}

export default ProfileStore;
