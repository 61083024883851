import { IAgencyPagination } from '../Interfaces';
import { AgencyModel } from './Agency.model';
import { PageModel } from './Page.model';

export class AgencyPaginationModel extends PageModel {
    results: AgencyModel[];

    constructor(data?: Partial<AgencyPaginationModel>) {
        super();
        Object.assign(this, data);
    }

    static deserialize(apiData: IAgencyPagination): AgencyPaginationModel {
        if (!apiData) {
            return new AgencyPaginationModel();
        }

        const data: Partial<AgencyPaginationModel> = {
            pageNumber: apiData.PageNumber,
            pageSize: apiData.PageSize,
            totalNumberOfRecords: apiData.TotalNumberOfRecords,
            totalPages: apiData.TotalPages,
            results: AgencyModel.deserializeList(apiData.Results),
        };

        return new AgencyPaginationModel(data);
    }
}
