import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginBottom: '2rem',
    fontWeight: 400,
  },
  outerContainer: {
    padding: '20px 30px',
  },
  innerContainer: {
    padding: '41px 41px 20px',
  },
}));
