export const StepOneFields = [
  {
    name: 'name',
    label: 'Site Name',
    rules: 'required|string',
    value: '',
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number',
    rules: 'required|string|min:10|max:10',
    value: '',
  },
  {
    name: 'address',
    label: 'Address',
    rules: 'required|string',
    value: '',
  },
];
