import { ITeacher } from '../Interfaces';

export class TeacherModel {
  id: number;
  name: string;
  siteId: number;
  siteName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;

  constructor(data?: Partial<TeacherModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ITeacher): TeacherModel {
    if (!apiData) {
      return new TeacherModel();
    }

    const data: Partial<TeacherModel> = {
      id: apiData.Id,
      name: apiData.Name,
      siteId: apiData.SiteId,
      siteName: apiData.SiteName,
      firstName: apiData.FirstName,
      lastName: apiData.LastName,
      email: apiData.Email,
      phoneNumber: apiData.PhoneNumber,
    };

    return new TeacherModel(data);
  }

  static deserializeList(teacherList: ITeacher[]): TeacherModel[] {
    return teacherList
      ? teacherList.map((apiData: ITeacher) => new TeacherModel(TeacherModel.deserialize(apiData)))
      : [];
  }
}
