import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  radioContainer: {
    padding: '20px',
  },
  radioButton: {
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormLabel-root': {
      display: 'flex',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
  },
  saveBtn: {
    fontSize: '15px',
    marginBottom: '1rem',
    textTransform: 'none',
    width: '140px',
  },
  backBtn: {
    backgroundColor: 'white',
    color: 'grey',
    fontSize: '15px',
    textTransform: 'none',
    border: '1px solid grey',
    height: '40px',
    width: '140px',
  },
}));
