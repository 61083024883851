import React, { FC } from 'react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { Box, Button, Card, CircularProgress, InputAdornment, Link, Typography } from '@mui/material';
import { EmailOutlined as EmailOutlinedIcon } from '@mui/icons-material';
import { Logo } from '@assets';
import { FormInputControl, getFormValidation, useNotification } from '@shared';
import { UIStore, AuthStore } from '@stores';
import { useStyles } from './ForgotPassword.styles';
import { Fields } from './fields';

const form: MobxReactForm = getFormValidation(Fields, null);

const ForgotPassword: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const notification = useNotification();

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value.trim());
  };

  const sendEmail = () => {
    const { email } = form.values();
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        AuthStore.sendEmail(email).subscribe({
          next: (response: string) => {
            UIStore.hideLoader();
            notification.success(response);
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  return (
    <div className={classes.mainWrapper}>
      <Card className={classes.container} elevation={4}>
        <div style={{ textAlign: 'center' }}>
          <img src={Logo} className={classes.image} alt="logo" />
        </div>
        <form>
          <Box component="div" display="flex" flexDirection="column">
            <FormInputControl
              showLabel={true}
              field={form.$('email')}
              onValueChange={value => handleChange(value, 'email')}
              isAdornment={true}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              startAdornment={
                <InputAdornment position="start" className={classes.inputIcon}>
                  <EmailOutlinedIcon />
                </InputAdornment>
              }
            />
            <Box component="div" display="flex" alignSelf="flex-end" sx={{ my: 1 }}>
              <Typography className={classes.linkText}>Click here to</Typography>
              <Link onClick={() => navigate('/auth/login')} underline="hover" className={classes.link}>
                Sign in
              </Link>
            </Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.emailButton}
              onClick={sendEmail}
              disabled={UIStore.pageLoading}
            >
              {UIStore.pageLoading ? (
                <CircularProgress className={classes.progress} style={{ width: 30, height: 30 }} />
              ) : (
                <Typography>Send Email</Typography>
              )}
            </Button>
          </Box>
        </form>
      </Card>
    </div>
  );
};

export default observer(ForgotPassword);
