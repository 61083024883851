import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  tipSheetsSection: {
    border: '2px solid',
    borderRadius: 1,
    padding: '10px 15px',
    width: '50%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  reportSection: {
    display: 'flex',
    height: 'calc(100% - 150px)',
    marginTop: '30px',
  },
  sideBar: {
    flexShrink: 0,
    '& .MuiPaper-root': {
      border: '1px solid #80808061',
      position: 'relative',
      marginRight: '30px',
      height: '100%',
    },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: 300,
    },
  },
  heading: {
    fontWeight: 'bold',
  },
  btn: {
    textTransform: 'none',
    color: '#333333',
    backgroundColor: 'white',
    border: '1px solid #333333',
    marginRight: '12px',
  },
  sideBox: {
    border: '1px solid #80808061',
    padding: '0px',
    /*overflowY: 'auto',*/
    height: '100%',
    '& > .MuiBox-root': {
      /*overflowY: 'auto',*/
    },
  },
  title: {
    backgroundColor: ' #80808061',
    padding: '20px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
  },
  navOptions: {
    textDecoration: 'none',
    color: '#000000d1',
    fontWeight: 600,
  },
  backBtn: {
    color: '#0000FF',
    fontWeight: 600,
    fontSize: '18px',
  },
  mainHeading: {
    backgroundColor: ' #80808061',
    padding: '6px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
    margin: '10px 0px',
  },
  icon: {
    color: '#0000FF',
  },
  text: {
    padding: 2,
    fontWeight: 'bold',
    color: '#0000FF',
    textTransform: 'none',
  },
  input: {
    width: '100%',
    margin: '16px 0 4px',
    '& .MuiFormHelperText-root': {
      marginTop: '-15px',
      marginBottom: '12px',
      marginLeft: 0,
    },
    '& .MuiInputBase-root': {
      marginTop: '9px',
      marginBottom: '20px',
    },
    '& .MuiInputLabel-root': {
      width: '100%',
      top: '-27px',
      transform: 'none',
      maxWidth: '100%',
      pointerEvents: 'all',
      color: theme.palette.grey[500],
      fontWeight: 600,
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px 10px',
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: '5px 8px 8px',
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: '7px 1px 10px',
    },
    '& MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
  selectBox: {
    marginBottom: 10,
    '& .MuiFormLabel-root': {
      fontWeight: 600,
      fontSize: '16px',
      color: theme.palette.grey[500],
      top: '-9px',
    },
    '& .MuiInputBase-root ': {
      marginTop: '3px',
      height: 'auto',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));
