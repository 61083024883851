import { map, Observable } from 'rxjs';
import { ChildrenModel, ChildrenPaginationModel, CycleModel, CyclePaginationModel, ECOModel, OptionsModel, SiteModel } from '../Models';
import { IAPIResponse, IChildren, ICycle, ICyclePagination, IECO, ISite, IOptions, IChildrenPagination } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';
import { GridSortModel } from '@mui/x-data-grid';
import { Sort } from '../Enums';

class EcoSiteMappingService {
  public getEcoSites(ecoId: number): Observable<SiteModel[]> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ ecoId });
    return http
      .get<IAPIResponse<ISite[]>>(`${ApiUrl.sitesMapping}?${params}`)
      .pipe(map((response: IAPIResponse<ISite[]>) => SiteModel.deserializeList(response.Data)));
  }

  public mapSitesToEco(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.mapSitesToEco, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public getEcos(siteId: number): Observable<ECOModel[]> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ siteId });
    return http
      .get<IAPIResponse<IECO[]>>(`${ApiUrl.ecosMapping}?${params}`)
      .pipe(map((response: IAPIResponse<IECO[]>) => ECOModel.deserializeList(response.Data)));
  }

  public mapEcosToSite(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.mapEcosToSite, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public getAllCycles(
    pageNumber: number,
    pageSize: number,
    sortModel: GridSortModel,
    ecoSiteMappingId: number,
    query: string
  ): Observable<CyclePaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      PageNumber: pageNumber,
      PageSize: pageSize,
      Sort: sortModel[0]?.field || 'name',
      Dir: Sort[sortModel[0]?.sort] || Sort.asc,
      EcoSiteMappingId: ecoSiteMappingId,
      Query: query,
    });
    return http
      .get<IAPIResponse<ICyclePagination>>(`${ApiUrl.cycle}?${params}`)
      .pipe(map((response: IAPIResponse<ICyclePagination>) => CyclePaginationModel.deserialize(response.Data)));
  }

  public addCycle(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<boolean>>(ApiUrl.cycle, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public getCycleById(id: number): Observable<CycleModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<ICycle>>(`${ApiUrl.cycle}/${id}`)
      .pipe(map((response: IAPIResponse<ICycle>) => CycleModel.deserialize(response.Data)));
  }

  public updateCycle(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.cycle, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public deleteCycle(id: number): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.cycle}/${id}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public activeCycle(id: number): Observable<CycleModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<ICycle>>(`${ApiUrl.activeCycle}/${id}`)
      .pipe(map((response: IAPIResponse<ICycle>) => CycleModel.deserialize(response.Data)));
  }
  
  public getSiteAndCycleOptions(): Observable<OptionsModel[]> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IOptions[]>>(ApiUrl.siteAndCycleOptions)
      .pipe(map((response: IAPIResponse<IOptions[]>) => OptionsModel.deserializeList(response.Data)));
  }

  public getCycleByCycleId(id: number): Observable<CycleModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<ICycle>>(`${ApiUrl.getCycleByCycleId}/${id}`)
      .pipe(map((response: IAPIResponse<ICycle>) => CycleModel.deserialize(response.Data)));
  }

  public getStudents(cycleId: number, pageSize: number, pageNumber: number): Observable<ChildrenPaginationModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({
      siteId:0,
      ecoSiteMappingCycleId: cycleId,
      pageSize: pageSize,
      pageNumber: pageNumber
    });
    return http
      .get<IAPIResponse<IChildrenPagination>>(`${ApiUrl.studentsToSendSurvey}?${params}`)
      .pipe(map((response: IAPIResponse<IChildrenPagination>) => ChildrenPaginationModel.deserialize(response.Data)));
  }
  
}

export default new EcoSiteMappingService();
