import { IFamilyPagination } from '../Interfaces';
import { FamilyModel } from './Family.model';
import { PageModel } from './Page.model';

export class FamilyPaginationModel extends PageModel {
    results: FamilyModel[];

    constructor(data?: Partial<FamilyPaginationModel>) {
        super();
        Object.assign(this, data);
    }

    static deserialize(apiData: IFamilyPagination): FamilyPaginationModel {
        if (!apiData) {
            return new FamilyPaginationModel();
        }

        const data: Partial<FamilyPaginationModel> = {
            pageNumber: apiData.PageNumber,
            pageSize: apiData.PageSize,
            totalNumberOfRecords: apiData.TotalNumberOfRecords,
            totalPages: apiData.TotalPages,
            results: FamilyModel.deserializeList(apiData.Results),
        };

        return new FamilyPaginationModel(data);
    }
}
