import { map, Observable } from 'rxjs';
import { ChildProgressModel, ChildReportModel, ChildSurveyModel, ChildTipSheetsModel, NotesModel } from '../Models';
import { IAPIResponse, IChildProgress, IChildReport, IChildSurvey, IChildTipSheets, INotes } from '../Interfaces';
import { ApiUrl } from './APIUrls';
import HttpClient from '../Tools/HttpClient';
import { Utils } from '../Utilities';

class ChildSurveyService {
  public getChildSurvey(id: string, forFamily: boolean): Observable<ChildSurveyModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ id, forFamily });
    return http.get<IAPIResponse<IChildSurvey>>(`${ApiUrl.childSurvey}?${params}`).pipe(
      map((response: IAPIResponse<IChildSurvey>) => {
        const { Data } = response;
        const sorted = response.Data.SurveySections.sort((r1, r2) =>
          r1.OrderIndex > r2.OrderIndex ? 1 : r1.OrderIndex < r2.OrderIndex ? -1 : 0
        );
        const survey = { ...Data, ...sorted };
        return ChildSurveyModel.deserialize(survey);
      })
    );
  }

  public addChildSurvey(req): Observable<number> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<number>>(ApiUrl.childSurvey, req)
      .pipe(map((response: IAPIResponse<number>) => response.Data));
  }

  public addPublicSurvey(req): Observable<number> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<number>>(ApiUrl.publicChildSurvey, req)
      .pipe(map((response: IAPIResponse<number>) => response.Data));
  }

  public sendSurvey(req): Observable<any> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<any>>(ApiUrl.ecoSendSurveyRequest, req)
      .pipe(map((response: IAPIResponse<any>) => response.Data));
  }

  public getChildReport(childSurveyGuid: string): Observable<ChildReportModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ childSurveyGuid });
    return http
      .get<IAPIResponse<IChildReport>>(`${ApiUrl.childSurveyReport}?${params}`)
      .pipe(map((response: IAPIResponse<IChildReport>) => ChildReportModel.deserialize(response.Data)));
  }

  public getChildProgress(id: number): Observable<ChildProgressModel> {
    const http: HttpClient = new HttpClient();
    const params = Utils.buildParamString({ id });
    return http
      .get<IAPIResponse<IChildProgress>>(`${ApiUrl.childProgress}?${params}`)
      .pipe(map((response: IAPIResponse<IChildProgress>) => ChildProgressModel.deserialize(response.Data)));
  }

  public addNote(req): Observable<NotesModel> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<INotes>>(ApiUrl.surveySectionNote, req)
      .pipe(map((response: IAPIResponse<INotes>) => NotesModel.deserialize(response.Data)));
  }

  public updateSurveyStatus(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .put<IAPIResponse<boolean>>(ApiUrl.reportStatus, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public retakeChildSurvey(childSurveyGuid: string): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.retakeChildSurvey}/${childSurveyGuid}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public getTipSheets(): Observable<ChildTipSheetsModel> {
    const http: HttpClient = new HttpClient();
    return http
      .get<IAPIResponse<IChildTipSheets>>(ApiUrl.childTipSheets)
      .pipe(map((response: IAPIResponse<IChildTipSheets>) => ChildTipSheetsModel.deserialize(response.Data)));
  }

  public insertTipSheets(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<boolean>>(ApiUrl.childTipSheets, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public referChild(req): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .post<IAPIResponse<boolean>>(ApiUrl.referChild, req)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }

  public deleteECOSurvey(childSurveyGuid: string): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http
      .delete<IAPIResponse<boolean>>(`${ApiUrl.deleteECOSurvey}/${childSurveyGuid}`)
      .pipe(map((response: IAPIResponse<boolean>) => response.Data));
  }
}

export default new ChildSurveyService();
