import React, { FC, Fragment, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router';
import { runInAction } from 'mobx';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { Box, Drawer, List, Typography, Divider, ListItem, ListItemButton } from '@mui/material';
import { SwycLogo } from '@assets';
import { AuthStore, ChildSurveyStore, UIStore } from '@stores';
import { ChildSurveyService, LocalStorageService } from '@services';
import { ChildSurveyModel } from '@models';
import { SurveyQuestions, useNotification } from '@shared';
import { useStyles } from './ChildSurvey.styles';

type Props = {
  childSurveyStore?: ChildSurveyStore;
};

const ChildSurvey: FC<Props> = ({ childSurveyStore }: Props) => {
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const notification = useNotification();
  const slash = location.pathname.indexOf('/', 1);
  const path = location.pathname.substring(0, slash);
  const { survey } = childSurveyStore;
  const observable = useLocalObservable(() => ({
    orderIndex: 0,
    navigatorDisplay: 'flex',
    errorMessage: '',
  }));

  useEffect(() => {
    if (!AuthStore.isFamily && !AuthStore.accessToken) {
      navigate('/auth/login', { replace: true });
      return;
    }
    getSurvey();
    setNavigator();
    return () => {
      childSurveyStore.setSurvey(null);
      childSurveyStore.setSurveySection(null);
    };
  }, []);

  const getSurvey = () => {
    UIStore.showLoader();
    ChildSurveyService.getChildSurvey(params?.id, true).subscribe({
      next: (response: ChildSurveyModel) => {
        UIStore.hideLoader();
        if (!response.hasChildConsent && AuthStore.isFamily) {
          LocalStorageService.saveRedirectUrl(location.pathname);
          navigate(`/caregiver/child-consent/${response.childrenId}`, { replace: true });
          return;
        }
        const surveySection = response?.surveySections[0];
        childSurveyStore.setSurvey(response);
        childSurveyStore.setSurveySection(surveySection);
        runInAction(() => {
          observable.orderIndex = surveySection.orderIndex;
        });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        if (error.message.includes('No survey found for a given id')) {
          runInAction(() => {
            observable.errorMessage = 'This survey link has expired or is no longer available.';
          });
          return;
        }
        notification.error(error?.message);
      },
    });
  };

  const showBack = (): boolean => {
    return observable.orderIndex !== 1;
  };

  const getLastOrderIndex = (): number => {
    return survey?.surveySections[survey?.surveySections?.length - 1]?.orderIndex;
  };

  const handleNext = (): void => {
    if (observable.orderIndex === getLastOrderIndex()) {
      submitSurvey();
      return;
    }
    const orderIndex = observable.orderIndex + 1;
    runInAction(() => {
      observable.orderIndex = orderIndex;
    });
    const findSurveySection = survey?.surveySections?.find(section => section.orderIndex === orderIndex);
    childSurveyStore.setSurveySection(findSurveySection);
  };

  const goBack = (): void => {
    const orderIndex = observable.orderIndex - 1;
    runInAction(() => {
      observable.orderIndex = orderIndex;
    });
    const findSurveySection = survey?.surveySections?.find(section => section.orderIndex === orderIndex);
    childSurveyStore.setSurveySection(findSurveySection);
  };

  const setSurveyQuestions = (orderIndex: number) => {
    runInAction(() => {
      observable.orderIndex = orderIndex;
    });
    const findSurveySection = survey?.surveySections?.find(section => section.orderIndex === orderIndex);
    childSurveyStore.setSurveySection(findSurveySection);
  };

  const submitSurvey = () => {
    UIStore.showLoader();
    ChildSurveyService.addChildSurvey(survey).subscribe({
      next: (response: number) => {
        UIStore.hideLoader();
        navigate(`${path}/thanks`, { replace: true });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const renderComponentLabel = () => {
    return survey?.surveySections?.find(x => x?.orderIndex === observable.orderIndex)?.name;
  };

  const setNavigator = () => {
    var isMobile =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      //|| navigator.userAgent.match(/iPad/i)
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i);
    observable.navigatorDisplay = isMobile ? 'none' : 'flex';
  };

  return (
    <div style={{ padding: 30, backgroundColor: 'white', height: '100%' }}>
      {observable.errorMessage ? (
        <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold' }}>
          {observable.errorMessage}
        </Typography>
      ) : (
        <Box component="div" mb={1}>
          <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold' }}>
            {`Survey - ${survey?.childName}`}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <img src={SwycLogo} className={classes.img} alt="SWYC"></img>
            <div>
              <Typography>{`SWYC ${survey?.surveyName}`}</Typography>
              <Typography>{survey?.surveyDescription}</Typography>
              <Typography>{survey?.version}</Typography>
            </div>
          </Box>
          <Box sx={{ display: 'flex', height: 'calc(100vh - 280px)', marginTop: '30px' }}>
            <Drawer
              sx={{ display: observable.navigatorDisplay }}
              className={classes.sideBar}
              variant="permanent"
              anchor="left"
            >
              <Divider />
              <ListItem className={classes.title}>Section Navigation</ListItem>
              <List>
                {survey?.surveySections?.map(section => (
                  <Fragment key={section.surveySectionId}>
                    <ListItem
                      key={section.surveySectionId}
                      disablePadding
                      onClick={() => setSurveyQuestions(section.orderIndex)}
                      sx={{
                        backgroundColor:
                          observable.orderIndex === section.orderIndex ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                      }}
                    >
                      <ListItemButton>{section.name}</ListItemButton>
                    </ListItem>
                    <Divider style={{ color: '#80808061' }} />
                  </Fragment>
                ))}
              </List>
            </Drawer>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }} className={classes.sideBox}>
              <Typography className={classes.title}>{renderComponentLabel()}</Typography>
              <SurveyQuestions handleNext={handleNext} goBack={goBack} showBack={showBack()} />
            </Box>
          </Box>
        </Box>
      )}    
    </div>
  );
};

export default inject('childSurveyStore')(observer(ChildSurvey));
