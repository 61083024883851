import React, { FC } from 'react';
import MobxReactForm, { Field } from 'mobx-react-form';
import { useNavigate } from 'react-router';
import { observer, useLocalObservable } from 'mobx-react';
import { AxiosError } from 'axios';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { Logo } from '@assets';
import { FormInputControl, LocalStorageService, getFormValidation, useNotification } from '@shared';
import { UIStore, AuthStore } from '@stores';
import { useStyles } from './AccessToken.styles';
import { Fields } from './Fields';
import { useSearchParams } from 'react-router-dom';

const form: MobxReactForm = getFormValidation(Fields, null);

const AccessToken: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const notification = useNotification();

  const observable = useLocalObservable(() => ({
    token: '',
  }));
  const [searchParams]=useSearchParams();  
  observable.token = searchParams.get('token') ?? '';
  
  
  const getField = (key: string): Field => {
    return form.$(key);
  };

  getField('accessToken').set(observable.token);

  const handleChange = (value: any, field: string): void => {
    getField(field).set(value.trim());
  };

  const verifyConsent = () => {
    AuthStore.verifyConsent().subscribe({
      next: hasConsent => {
        AuthStore.setHasConsent(hasConsent);
        UIStore.hideLoader();
        if (hasConsent) {
          const redirectUrl = LocalStorageService.getRedirectUrl();
          if (redirectUrl) {
            LocalStorageService.removeRedirectUrl();
            navigate(redirectUrl);
            return;
          }
          navigate('/caregiver/children');
          return;
        }
        navigate('/caregiver/account-consent');
      },
      error: error => {
        UIStore.hideLoader();
      },
    });
  };

  const onFamilyLogin = () => {
    const { accessToken } = form.values();
    form.submit({
      onSuccess: () => {
        UIStore.showLoader();
        AuthStore.familyLogin(accessToken).subscribe({
          next: response => {
            verifyConsent();
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  return (
    <div className={classes.mainWrapper}>
      <Card className={classes.container} elevation={4}>
        <div style={{ textAlign: 'center' }}>
          <img src={Logo} className={classes.image} alt="logo" />
        </div>
        <form>
          <Box component="div" display="flex" flexDirection="column">
            <FormInputControl
              showLabel={true}
              field={form.$('accessToken')}
              onValueChange={value => handleChange(value, 'accessToken')}
              form={form}
              styleClasses={{ inputControl: classes.input }}
              value={observable.token}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={onFamilyLogin}
              disabled={UIStore.pageLoading}
            >
              {UIStore.pageLoading ? (
                <CircularProgress className={classes.progress} style={{ width: 30, height: 30 }} />
              ) : (
                <Typography>Submit</Typography>
              )}
            </Button>
          </Box>
        </form>
      </Card>
    </div>
  );
};

export default observer(AccessToken);
